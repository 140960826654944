import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectOption } from 'src/app/common/select/interfaces/select-option.interface';
import { Stat } from './interfaces/stat.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProviderService } from '../../../common/services/provider/provider.service';
import { PreferencesService } from '../../../common/services/preferences/preferences.service';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { Router } from '@angular/router';
import { metricsConfiguration } from '../statistics.model';
import { IPreferences } from '../../../common/services/preferences/interfaces/preferences.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-statistics-settings',
  templateUrl: './statistics-settings.component.html',
  styleUrls: ['./statistics-settings.component.scss']
})
export class StatisticsSettingsComponent implements OnInit, OnDestroy {
  providerId = 'default';
  highlightedStat = 'default';
  highlightOptions: SelectOption[] = [];
  stats: Stat[] = [];
  private readonly _subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly alertService: AlertsService,
    private readonly providerService: ProviderService,
    private readonly preferencesService: PreferencesService
  ) {}

  ngOnInit(): void {
    this.readStats();
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  drop(event: CdkDragDrop<Stat[]>): void {
    moveItemInArray(this.stats, event.previousIndex, event.currentIndex);
  }

  confirm(): void {
    const preferences: IPreferences = {
      providerId: this.providerId,
      highlightedStatName: this.highlightedStat,
      statPreferences: this.stats.map(({ name, visible }, index) => ({
        name,
        visible,
        index
      }))
    };
    this.preferencesService.updatePreferences(preferences).subscribe({
      next: async () => {
        this.alertService.showSuccess('User statistics preferences updated.');
        await this.router.navigate(['stats']);
      }
    });
  }

  confirmOnKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
    const key = event.key;
    if (key !== 'Enter' && key !== ' ') {
      return;
    }
    this.confirm();
  }

  private readStats(): void {
    this.stats = metricsConfiguration.map((metric, index) => ({
      name: metric.queryType,
      description: metric.label,
      visible: true,
      index
    }));
    this.highlightOptions = this.stats.map(
      ({ name: value, description: text }) => ({
        value,
        text
      })
    );
  }

  private readPreferences(): Subscription {
    return this.preferencesService.preferencesState$.subscribe({
      next: (preferences) => {
        if (!preferences) {
          return;
        }
        this.highlightedStat =
          preferences.highlightedStatName ?? this.highlightedStat;
        this.stats = this.stats.map(({ name, description }, i) => ({
          name,
          description,
          index:
            preferences.statPreferences?.find(
              (preference) => preference.name === name
            )?.index ?? i,
          visible:
            preferences.statPreferences?.find(
              (preference) => preference.name === name
            )?.visible ?? true
        }));
        this.stats.sort((a, b) => a.index - b.index);
      }
    });
  }

  private subscribe(): void {
    this._subscriptions.push(
      this.providerService.providerState$.subscribe((state) => {
        const { provider } = state;
        this.providerId = provider.id;
      }),
      this.readPreferences()
    );
  }

  private unsubscribe(): void {
    this._subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
