<div class="row">
  <div class="col s12 center-align">
    <h4 class="text-primary center">
      {{ 'ERROR.PAGE_NOT_FOUND.TITLE' | translate }}
    </h4>
    <br />
    <p class="text-primary center">
      {{ 'ERROR.PAGE_NOT_FOUND.DESCRIPTION' | translate }}
    </p>
    <a class="btn" href="/">{{ 'ERROR.PAGE_NOT_FOUND.CLOSE' | translate }}</a>
  </div>
</div>
