<div class="subscription-error-container">
  <div class="error-content">
    <div class="image">
      <mat-icon svgIcon="request_access"></mat-icon>
    </div>
    <h1 class="error-title">
      {{ 'OH_NO.TITLE' | translate }}
    </h1>
    <p class="error-message">
      {{ 'OH_NO.SUBSCRIPTION_ERROR' | translate }}
    </p>
    <p class="error-suggestion">
      {{ 'OH_NO.SUGGESTION' | translate }}
    </p>
    <ul>
      <li>
        <a class="text-secondary" (click)="navigateTo('https://info.dentalintel.com/cbj')">
        {{ 'OH_NO.PATIENT_CHAT' | translate }}
        </a>
      </li>
      <li>
        <a class="text-secondary" (click)="navigateTo('https://info.dentalintel.com/rh0')">
        {{ 'OH_NO.TEAM_CHAT' | translate }}
        </a>
      </li>
      <li>
        <a class="text-secondary" (click)="navigateTo('https://info.dentalintel.com/r7y')">
        {{ 'OH_NO.MY_SCHEDULE' | translate }}
        </a>
      </li>
      <li>
        <a class="text-secondary" (click)="navigateTo('https://info.dentalintel.com/fp3')">
        {{ 'OH_NO.ANALYTICS' | translate }}
        </a>
      </li>
      <li>
        <a class="text-secondary" (click)="navigateTo('https://info.dentalintel.com/o9k')">
        {{ 'OH_NO.SCHEDULE' | translate }}
        </a>
      </li>
    </ul>
  </div>
</div>
