import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Dropdown, Materialize, Modal } from '../../materialize';
import { UserService } from '../../common/user';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  Router
} from '@angular/router';
import {
  BehaviorSubject,
  from,
  iif,
  merge,
  Observable,
  of,
  Subject,
  Subscription
} from 'rxjs';
import {
  delay,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { Location as AngularLocation, LocationStrategy } from '@angular/common';
import { NavigationService } from './navigation.service';
import { environment } from '../../../environments/environment';
import { PracticeService } from '../../common/practice';
import { AuthService } from '../../common/authentication/auth.service';
import { NativeAppService } from '../../common/native-app-service/native-app.service';
import { applyDropdownFixForMobile } from '../../common/utils/materialize-utils.helper';
import { Patient } from '../../common/patient/patient.model';
import { PatientChatService } from '../patient-chat/patient-chat.service';
import { UserNotificationsService } from '../../common/user-notifications';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BadgeService } from '../../common/badge/badge.service';
import { PracticeList } from 'src/app/common/practice/practice.model';

declare const M: Materialize;

@UntilDestroy()
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isTeamChatEnabled = false;
  @Input() unreadTeamChatMessagesCount = 0;
  @Output() teamChatClicked: EventEmitter<void> = new EventEmitter<void>();
  isPracticeSwitcherVisible$ = new BehaviorSubject<boolean>(false);
  userName: string;
  userImage: string;
  topLevel = true;
  contextAction = false;
  pageTitle: string;
  isLoading: boolean;
  showProviderSelection: boolean = false;
  isSpinnerWhite = false;
  patientInfoModal: Modal;
  infoPatient: Patient;
  liveUnreadNotificationsChange$ = merge(
    this.nativeService.pushNotificationsObservable(),
    this.nativeService
      .focusChangeObservable()
      .pipe(filter((focusChange) => !!focusChange))
  ).pipe(
    startWith({}),
    switchMap(() => {
      return iif(
        () => this.router.url.includes('notifications'),
        of(0),
        from(this.userNotificationsService.getUnreadNotifications()).pipe(
          take(1)
        )
      );
    })
  );
  unreadNotificationCount$: Observable<string | number> = merge(
    this.liveUnreadNotificationsChange$,
    this.userNotificationsService.getUnreadCount()
  ).pipe(
    map((count) => (count === 0 ? '' : count)),
    // for mobile IOS synchronization update
    shareReplay(1)
  );
  logout$: Subject<void> = new Subject<void>();
  tasksOnLogout$ = this.logout$
    .pipe(
      tap(() => this.badgeService.resetBadgeCounter()),
      delay(100),
      tap(() => location.reload()),
      untilDestroyed(this)
    )
    .subscribe();
  private readonly subscriptions: Subscription[] = [];
  private routerEventsSub: Subscription;
  private pageTitleSub: Subscription;
  private forceLogoutSub: Subscription;
  private patientInfoSub: Subscription;
  private dropdownPlugins: Dropdown[];

  constructor(
    public userService: UserService,
    private readonly practiceService: PracticeService,
    private readonly angularLocation: AngularLocation,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly locationStrategy: LocationStrategy,
    private readonly authService: AuthService,
    private readonly nativeService: NativeAppService,
    private readonly patientChatService: PatientChatService,
    private readonly userNotificationsService: UserNotificationsService,
    private readonly badgeService: BadgeService
  ) {}

  ngOnInit(): void {
    this.subscribe();
    this.routerEventsSub = this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe((event: any) => {
        this.topLevel = event.snapshot?.data?.topLevel;
        this.contextAction = event.snapshot?.data?.contextAction;
        setTimeout(() => this.initMaterialize(), 0);
      });
    this.pageTitleSub = this.navigationService
      .pageTitleObservable()
      .subscribe((title) => (this.pageTitle = title));
    this.forceLogoutSub = this.navigationService
      .forceLogoutEventObservable()
      .subscribe(() => this.logout());
    this.patientInfoSub = this.navigationService
      .showPatientInfoObservable()
      .subscribe((patient: Patient) => this.showPatientInfoModal(patient));

    const user = this.userService.getUser();
    const analyticsUser = this.userService.getAnalyticsUser();

    this.userName =
      user !== undefined
        ? user.name
        : `${analyticsUser.firstName} ${analyticsUser.lastName}`;
    this.userImage = user !== undefined ? user.image : analyticsUser.avatar;
    const combinedPractices = this.practiceService.getCombinedPractices();

    this.handlePracticeSwitcher(combinedPractices);
  }

  onPracticeUpdate(): void {
    this.isTeamChatEnabled =
      this.practiceService.getPracticeGuid() != null &&
      this.userService.getTeamMember().role_chat_user &&
      !!this.practiceService.getPractice().has_active_subscription &&
      !!this.practiceService.getPractice().connect_team_chat;
  }

  ngAfterViewInit(): void {
    this.initMaterialize();
  }

  raiseTeamChatClicked(event: MouseEvent): void {
    event.preventDefault();

    this.teamChatClicked.emit();
  }

  private initMaterialize(): void {
    const sidenav = document.querySelectorAll('.sidenav');
    const sidenavInstances = M.Sidenav.init(sidenav);

    !!this.route.snapshot.queryParams['leaveMenuOpen'] &&
      sidenavInstances.forEach((sidenav: any) => sidenav.open());

    const dropdowns = document.querySelectorAll('.dropdown-trigger');
    const boundary = document.getElementsByClassName('section body')[0];
    this.dropdownPlugins = M.Dropdown.init(dropdowns, {
      constrainWidth: false,
      container: boundary,
      onOpenStart: () => {
        applyDropdownFixForMobile();
      }
    });
    // close all dropdowns whenever we navigate back
    this.locationStrategy.onPopState(() => {
      if (this.dropdownPlugins) {
        this.dropdownPlugins.forEach((dropdown) => {
          dropdown.close();
        });
      }
    });
  }

  goBack(): void {
    this.angularLocation.back();
  }

  goBackOnKeydown(event: KeyboardEvent): void {
    event.preventDefault();
    const key = event.key;
    if (key !== 'Enter' && key !== ' ') {
      return;
    }
    this.goBack();
  }

  onPatientChatSelected(event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    this.patientChatService.search = null;

    if (event) {
      this.router.navigate(['/patient-chat']);
    }
  }

  // This "hack" fixes a bug, where sidenav could not be opened on iOS version 12 and less
  // @ts-ignore
  noop(): void {}

  noopOnKeydown(event: KeyboardEvent): void {
    event.preventDefault();
    const key = event.key;
    if (key !== 'Enter' && key !== ' ') {
      return;
    }
    this.noop();
  }

  async logout(): Promise<any> {
    try {
      this.isLoading = true;
      await this.userService.unregisterDevice();
      await this.authService.logout();
    } catch (e) {
      console.log(e);
    } finally {
      this.userService.clearUserInfo();
      this.practiceService.clearPracticeInfo();
      this.isLoading = false;
    }
    this.logout$.next();
  }

  async logoutOnKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    const key = event.key;
    if (key !== 'Enter' && key !== ' ') {
      return;
    }
    await this.logout();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    if (this.routerEventsSub) {
      this.routerEventsSub.unsubscribe();
    }
    if (this.pageTitleSub) {
      this.pageTitleSub.unsubscribe();
    }
    if (this.forceLogoutSub) {
      this.forceLogoutSub.unsubscribe();
    }
    if (this.patientInfoSub) {
      this.patientInfoSub.unsubscribe();
    }
    if (this.tasksOnLogout$) {
      this.tasksOnLogout$.unsubscribe();
    }
  }

  async goToDashboard(): Promise<any> {
    const url = encodeURIComponent(
      (await this.userService.getShareAuthUrl()).url
    );
    this.nativeService.openExternalUrlOnNewTab(
      `${
        environment.dashboardUrl
      }/${this.practiceService.getPracticeGuid()}?authUrl=${url}`
    );
  }

  private showPatientInfoModal(patient: Patient): void {
    if (this.patientInfoModal) {
      this.infoPatient = patient;
      this.patientInfoModal.open();
    }
  }

  private handlePracticeSwitcher(combinedPractices: PracticeList[]): void {
    this.isPracticeSwitcherVisible$.next(combinedPractices.length > 0);
  }

  private subscribe(): void {
    this.subscriptions.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event) => {
          const url = (event as NavigationEnd).urlAfterRedirects;
          this.showProviderSelection = ['my-schedule', 'stats'].some((word) =>
            url
              .split('/')
              .map((word) => word.trim())
              .includes(word)
          );
        })
    );
  }

  private unsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
