import { Injectable } from '@angular/core';
import { AnalyticsHttpService } from '../../mobiati-http/analytics-http.service';
import { AlertsService } from '../../alerts/alerts.service';
import { Preferences } from './classes/preferences';
import {
  BehaviorSubject,
  finalize,
  firstValueFrom,
  from,
  Observable,
  of
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LoadingService } from '../loading/loading.service';
import { IPreferences } from './interfaces/preferences.interface';
import { PracticeService } from '../../practice';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PracticeInfo } from '../../practice/practice.model';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  private readonly state$ = new BehaviorSubject<Preferences>(null);
  readonly preferencesState$ = this.state$.asObservable();

  constructor(
    private readonly http: AnalyticsHttpService,
    private readonly alertService: AlertsService,
    private readonly practiceService: PracticeService,
    private readonly loadingService: LoadingService
  ) {
    this.subscribe();
  }

  updatePreferences(preferences: IPreferences): Observable<null> {
    const url = `app/practice/${this.practiceService.getAnalyticsPracticeGuid()}/user/preferences`;
    const promise = this.http.put<null>(url, preferences);

    this.loadingService.start();

    return from(promise).pipe(
      tap(() => {
        this.state$.next(Preferences.init(preferences));
      }),
      catchError((error) => {
        this.state$.next(null);
        this.alertService.showApiError(error);
        return of(null);
      }),
      finalize(() => {
        this.loadingService.stop();
      })
    );
  }

  private readPreferences(practiceId: string): Observable<Preferences> {
    const url = `app/practice/${practiceId}/user/preferences`;
    const promise = this.http.get<IPreferences>(url);

    this.loadingService.start();

    return from(promise).pipe(
      map((data) => {
        const preferences = Preferences.init(data);
        this.state$.next(preferences);
        return preferences;
      }),
      catchError((error) => {
        this.alertService.showApiError(
          `Failed to fetch preferences for practice ${practiceId}`
        );
        console.log(error); // TODO: Replace with logging service when available.
        return of(null);
      }),
      finalize(() => {
        this.loadingService.stop();
      })
    );
  }

  private subscribe(): void {
    this.practiceService.practiceState$
      .pipe(untilDestroyed(this))
      .subscribe(async (practice: PracticeInfo | null) => {
        if (practice?.type === 'Engagement') {
          this.state$.next(null);
          return;
        }

        const { type, id } = practice;

        if (type === 'Analytics') {
          await firstValueFrom(this.readPreferences(id));
        }
      });
  }
}
