import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Dropdown, Materialize, Modal } from '../../materialize';
import { User, UserService } from '../../common/user';
import { ActivationEnd, Router } from '@angular/router';
import {
  BehaviorSubject,
  from,
  iif,
  merge,
  of,
  Subject,
  Subscription
} from 'rxjs';
import {
  delay,
  filter,
  map,
  mapTo,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { Location as AngularLocation, LocationStrategy } from '@angular/common';
import { NavigationService } from './navigation.service';
import { environment } from '../../../environments/environment';
import { PracticeService } from '../../common/practice';
import { AuthService } from '../../common/authentication/auth.service';
import { NativeAppService } from '../../common/native-app-service/native-app.service';
import { applyDropdownFixForMobile } from '../../common/utils/materialize-utils.helper';
import { Patient } from '../../common/patient/patient.model';
import { PatientChatService } from '../patient-chat/patient-chat.service';
import { UserNotificationsService } from '../../common/user-notifications';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BadgeService } from '../../common/badge/badge.service';

declare const M: Materialize;

@UntilDestroy()
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isTeamChatEnabled = false;
  @Input() unreadTeamChatMessagesCount = 0;

  @Output() teamChatClicked: EventEmitter<void> = new EventEmitter<void>();

  isPracticeSwitcherVisible$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  userName: string;
  userImage: string;
  topLevel = true;
  contextAction = false;
  pageTitle: string;
  isLoading: boolean;
  isSpinnerWhite = false;
  patientInfoModal: Modal;
  infoPatient: Patient;

  liveUnreadNotificationsChange$ = merge(
    this.nativeService.pushNotificationsObservable(),
    this.nativeService.focusChangeObservable().pipe(
      filter((focusChange) => !!focusChange),
      mapTo({})
    )
  ).pipe(
    startWith({}),
    switchMap(() => {
      return iif(
        () => this.router.url.includes('notifications'),
        of(0),
        from(this.userNotificationsService.getUnreadNotifications()).pipe(
          take(1)
        )
      );
    })
  );

  unreadNotificationCount$ = merge(
    this.liveUnreadNotificationsChange$,
    this.userNotificationsService.getUnreadCount()
  ).pipe(
    map((count) => (count === 0 ? '' : count)),
    // for mobile IOS synchronization update
    shareReplay(1)
  );

  logout$: Subject<void> = new Subject<void>();

  tasksOnLogout$ = this.logout$
    .pipe(
      tap(() => this.badgeService.resetBadgeCounter()),
      delay(100),
      tap(() => location.reload()),
      untilDestroyed(this)
    )
    .subscribe();

  private routerEventsSub: Subscription;
  private pageTitleSub: Subscription;
  private forceLogoutSub: Subscription;
  private patientInfoSub: Subscription;
  private dropdownPlugins: Dropdown[];

  constructor(
    public userService: UserService,
    private practiceService: PracticeService,
    private angularLocation: AngularLocation,
    private router: Router,
    private navigationService: NavigationService,
    private locationStrategy: LocationStrategy,
    private authService: AuthService,
    private nativeService: NativeAppService,
    private patientChatService: PatientChatService,
    private userNotificationsService: UserNotificationsService,
    private badgeService: BadgeService
  ) {}

  ngOnInit(): void {
    this.routerEventsSub = this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe((event: any) => {
        this.topLevel =
          event.snapshot && event.snapshot.data && event.snapshot.data.topLevel;
        this.contextAction =
          event.snapshot &&
          event.snapshot.data &&
          event.snapshot.data.contextAction;
        setTimeout(() => this.initMaterialize(), 0);
      });
    this.pageTitleSub = this.navigationService
      .pageTitleObservable()
      .subscribe((title) => (this.pageTitle = title));
    this.forceLogoutSub = this.navigationService
      .forceLogoutEventObservable()
      .subscribe(() => this.logout());
    this.patientInfoSub = this.navigationService
      .showPatientInfoObservable()
      .subscribe((patient: Patient) => this.showPatientInfoModal(patient));

    const user: User = this.userService.getUser();
    this.userName = user.name;
    this.userImage = user.image;

    this.handlePracticeSwitcher(user);
  }

  ngAfterViewInit(): void {
    this.initMaterialize();
  }

  raiseTeamChatClicked(event: MouseEvent): void {
    event.preventDefault();

    this.teamChatClicked.emit();
  }

  onPracticeUpdate(): void {
    this.isSpinnerWhite = true;
    this.isLoading = true;
  }

  private initMaterialize(): void {
    const sidenav = document.querySelectorAll('.sidenav');
    M.Sidenav.init(sidenav);
    const dropdowns = document.querySelectorAll('.dropdown-trigger');
    const boundary = document.getElementsByClassName('section body')[0];
    this.dropdownPlugins = M.Dropdown.init(dropdowns, {
      constrainWidth: false,
      container: boundary,
      onOpenStart: () => {
        applyDropdownFixForMobile();
      }
    });
    // close all dropdowns whenever we navigate back
    this.locationStrategy.onPopState(() => {
      if (this.dropdownPlugins) {
        this.dropdownPlugins.forEach((dropdown) => {
          dropdown.close();
        });
      }
    });
  }

  goBack(): void {
    this.angularLocation.back();
  }

  onPatientChatSelected(): void {
    this.patientChatService.search = null;
  }

  // This "hack" fixes a bug, where sidenav could not be opened on iOS version 12 and less
  // @ts-ignore
  noop(): void {}

  async logout(): Promise<any> {
    try {
      this.isLoading = true;
      await this.userService.unregisterDevice();
      await this.authService.logout();
    } catch (e) {
      console.log(e);
    } finally {
      this.userService.clearUserInfo();
      this.practiceService.clearPracticeInfo();
      this.isLoading = false;
    }
    this.logout$.next();
  }

  ngOnDestroy(): void {
    if (this.routerEventsSub) {
      this.routerEventsSub.unsubscribe();
    }
    if (this.pageTitleSub) {
      this.pageTitleSub.unsubscribe();
    }
    if (this.forceLogoutSub) {
      this.forceLogoutSub.unsubscribe();
    }
    if (this.patientInfoSub) {
      this.patientInfoSub.unsubscribe();
    }
  }

  async goToDashboard(): Promise<any> {
    const url = encodeURIComponent(
      (await this.userService.getShareAuthUrl()).url
    );
    this.nativeService.openExternalUrl(
      `${
        environment.dashboardUrl
      }/${this.practiceService.getPracticeGuid()}?authUrl=${url}`
    );
  }

  private showPatientInfoModal(patient: Patient): void {
    if (this.patientInfoModal) {
      this.infoPatient = patient;
      this.patientInfoModal.open();
    }
  }

  private handlePracticeSwitcher(user: User): void {
    this.isPracticeSwitcherVisible$.next(
      user.group_admin_role || user.dental_group?.length
    );
  }
}
