import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import 'rxjs';

import { ANALYTICS_API_URL } from '../../app.config';
import { PracticeService } from '../practice';
import { NavigationService } from '../../main/navigation';
import { MobiatiHttpService } from './mobiati-http.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsHttpService extends MobiatiHttpService {
  retryCall = false;

  constructor(
    @Inject(ANALYTICS_API_URL) public apiUrl: string,
    http: HttpClient,
    practiceService: PracticeService,
    navigationService: NavigationService,
    router: Router
  ) {
    super(apiUrl, http, practiceService, navigationService, router);
  }

  get<T>(url: string, params?: HttpParams): Promise<T> {
    return super.get<T>(`${this.apiUrl}/${url}`, params);
  }

  post<T>(url: string, body?: any, expectBlob: boolean = false): Promise<T> {
    return super.post<T>(`${this.apiUrl}/${url}`, body, expectBlob);
  }

  put<T>(url: string, body?: any, expectBlob: boolean = false): Promise<T> {
    return super.put<T>(`${this.apiUrl}/${url}`, body, expectBlob);
  }

  resolveUrl(url: string): string {
    if (url.startsWith('http')) {
      return url;
    } else {
      return `${this.apiUrl}/${url}`;
    }
  }
}
