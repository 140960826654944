import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScheduleComponent } from './schedule/schedule.component';
import { PermissionGuard } from '../../common/guard/permission.guard';

const routes: Routes = [
  {
    path: 'schedule',
    component: ScheduleComponent,
    data: { topLevel: true },
    canActivate: [PermissionGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScheduleRoutingModule {}
