<div class="container">
  <div class="row">
    <div class="valign-wrapper">
      <div class="col s12">
        <h5>{{ 'SUPPORT.TITLE' | translate }}</h5>
        <p class="allow-select">
          {{ 'SUPPORT.EMAIL' | translate }}
          <a (click)="mailTo('support@dentalintel.com')"
             class="text-secondary">support@dentalintel.com</a>
        </p>
        <p class="allow-select">
          {{ 'SUPPORT.PHONE' | translate }}
          <a (click)="callTo('tel:+18017172777')" class="text-secondary"
          >(801) 717-2777‬</a>
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="valign-wrapper">
      <div class="col s12">
        <h5>{{ 'SUPPORT.ABOUT_APP' | translate }}</h5>
        <p class="allow-select">
          {{ 'SUPPORT.VERSION' | translate }} {{ version }}
        </p>
        <button (click)="onCheckUpdates()"
                class="btn btn-small">
          {{ 'SUPPORT.CHECK_FOR_UPDATES' | translate }}
        </button>
        <p class="allow-select">
          {{ 'SUPPORT.RIGHTS' | translate: { year: year } }}
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="valign-wrapper">
      <div class="col s12">
        <h5>{{ 'SUPPORT.POLICIES' | translate }}</h5>
        <p>
          <a class="text-secondary" (click)="onTermsClicked()">{{
              'SUPPORT.TERMS_OF_USE' | translate
            }}</a>
        </p>
        <p>
          <a class="text-secondary" (click)="onPrivacyClicked()">{{
              'SUPPORT.PRIVACY_POLICY' | translate
            }}</a>
        </p>
      </div>
    </div>
  </div>
</div>
