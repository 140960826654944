import { Component, OnInit } from '@angular/core';
import { DeviceService } from '../../common/device-service/device.service';
import { Router } from '@angular/router';
import { NativeAppService } from '../../common/native-app-service/native-app.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../common/user';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  version: string;
  year = new Date().getFullYear();

  constructor(
    private deviceService: DeviceService,
    private nativeService: NativeAppService,
    private router: Router,
    public userService: UserService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.version = this.deviceService.getFullModentoClientInfo(false);
  }

  onTermsClicked() {
    this.router.navigate(['support', 'terms']);
  }

  onPrivacyClicked() {
    this.router.navigate(['support', 'privacy']);
  }

  mailTo(email: string) {
    this.nativeService.mailTo(email);
  }

  callTo(email: string) {
    this.nativeService.call(email);
  }

  onCheckUpdates() {
    if (!this.cookieService.get('currentUser')) {
      const now = new Date();
      const oneMinute = 60 * 1000;
      this.userService.setUserData(this.userService.getUserData(), true, {
        expires: new Date(now.getTime() + oneMinute),
        secure: environment.production,
        storeUnencoded: false
      });
    }
    location.reload();
  }
}
