import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConversationComponent } from './conversation/conversation.component';
import { PatientChatComponent } from './patient-chat.component';

const routes: Routes = [
  {
    path: 'patient-chat',
    component: PatientChatComponent,
    data: { topLevel: true }
  },
  {
    path: 'patient-chat/:id',
    component: ConversationComponent,
    data: { contextAction: true }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientChatRoutingModule {}
