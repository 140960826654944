<div>
  <div class="date-container">
    <ng-container>
      <span class="date-text">
        {{ startDate | date:'MMMM d, yyyy' }}{{ endDate ? ' - ' + (endDate | date:'MMMM d, yyyy') : '' }}
      </span>
    </ng-container>

    <mat-icon class="calendar-icon"
              (click)="toggleDatePicker()"
              onkeypress="toggleDatePicker()"
              tabindex="0">calendar_today</mat-icon>

    <input *ngIf="!isRange"
           [matDatepicker]="picker"
           [(ngModel)]="startDate"
           (ngModelChange)="onDatePickerChange($event)"
           [style.display]="'none'">

    <mat-date-range-input *ngIf="isRange"
                          [rangePicker]="rangePicker"
                          [style.display]="'none'">
      <input matStartDate
             [(ngModel)]="startDate"
             [style.display]="'none'">
      <input matEndDate
             [(ngModel)]="endDate"
             [style.display]="'none'"
             (ngModelChange)="onDatePickerChange($event)">
    </mat-date-range-input>

    <mat-datepicker #picker
                    [touchUi]="true"
                    (selectedChange)="onDateSelect($event)"></mat-datepicker>

    <mat-date-range-picker #rangePicker
                           (closed)="onMaterialPickerClosed()"
                           [touchUi]="true"></mat-date-range-picker>
  </div>

  <!-- Custom Date Picker Overlay -->
  <div class="picker-overlay"
       *ngIf="isPickerOpen"
       (click)="closeDatePicker($event)"
       onkeypress="closeDatePicker($event)">
    <div class="picker-dialog mat-elevation-z8"
         (click)="$event.stopPropagation()"
         onkeypress="$event.stopPropagation()">
      <button class="close-button"
              (click)="isPickerOpen = false">
        <mat-icon>close</mat-icon>
      </button>
      <!-- Tabs -->
      <div class="tab-container">
        <div class="tab-group">
          <button class="tab-button"
                  [class.active]="activeTab === 'presets'"
                  (click)="activeTab = 'presets'">
            Presets
          </button>
          <button class="tab-button"
                  [class.active]="activeTab === 'custom'"
                  (click)="activeTab = 'custom'">
            Custom
          </button>
        </div>
      </div>

      <!-- Presets Tab Content -->
      <div class="tab-content"
           *ngIf="activeTab === 'presets'">
        <div class="preset-list">
          <button class="preset-option"
                  *ngFor="let preset of presets"
                  (click)="applyPreset(preset.value)">
            {{ preset.label }}
            <mat-icon class="chevron-icon">chevron_right</mat-icon>
          </button>
        </div>
      </div>

      <!-- Custom Tab Content -->
      <div class="tab-content"
           *ngIf="activeTab === 'custom'">
        <div class="custom-date-inputs">
          <div class="date-input-group">
            <label for="startDate">Start Date</label>
            <div class="input-container">
              <input id="startDate"
                     [matDatepicker]="startPicker"
                     [(ngModel)]="startDate"
                     placeholder="Choose start date"
                     readonly>
              <mat-datepicker-toggle [for]="startPicker">
              </mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </div>
          </div>

          <div class="date-input-group">
            <label for="endDate">End Date</label>
            <div class="input-container">
              <input id="endDate"
                     [matDatepicker]="endPicker"
                     [(ngModel)]="endDate"
                     placeholder="Choose end date"
                     readonly>
              <mat-datepicker-toggle [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </div>
          </div>
        </div>
        <button class="generate-button"
                (click)="generateReport()">
          Generate Reports
        </button>
      </div>
    </div>
  </div>
</div>
