import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { from } from 'rxjs';
import { exhaustMap, pluck, skip, take, tap } from 'rxjs/operators';
import { Practice, PracticeService } from '../../../../common/practice';
import { UserService } from '../../../../common/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WindowRef } from '../../../../common/utils/window-utils.helper';
import { environment } from '../../../../../environments/environment';
import { CookieOptions, CookieService } from 'ngx-cookie';

@UntilDestroy()
@Component({
  selector: 'app-group-practices-select',
  templateUrl: './group-practices-select.component.html',
  styleUrls: ['./group-practices-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupPracticesSelectComponent {
  @Output()
  practiceUpdate: EventEmitter<void> = new EventEmitter<void>();

  form: FormGroup = this.fb.group({
    practice: ['']
  });

  getDefaultPractice$ = from(this.practiceService.getPracticeData())
    .pipe(
      take(1),
      tap((practice: Practice) =>
        this.form.setValue({ practice: practice.guid })
      )
    )
    .subscribe();

  updatePractice$ = this.form.valueChanges.pipe(
    skip(1), // skip getDefaultPractice
    pluck('practice'),
    tap((practiceGuid: string) => {
      this.practiceService.clearPracticeInfo();
      this.practiceService.setPracticeGuid(practiceGuid);
    }),
    exhaustMap(() => {
      return this.practiceService.loadPracticeIfNeeded();
    })
  );

  reloadApp$ = this.updatePractice$
    .pipe(
      tap(() => {
        if (!this.cookieService.get('currentUser')) {
          const now = new Date();
          const oneMinute = 60 * 1000;
          this.userService.setUserData(this.userService.getUserData(), true, {
            expires: new Date(now.getTime() + oneMinute),
            secure: environment.production,
            storeUnencoded: false
          });
        }
      }),
      tap((data) => {
        this.windowRef.nativeWindow.location.reload();
      }),
      tap((data) => this.practiceUpdate.emit()),
      untilDestroyed(this)
    )
    .subscribe();

  constructor(
    private windowRef: WindowRef,
    private fb: FormBuilder,
    private practiceService: PracticeService,
    public userService: UserService,
    private cookieService: CookieService
  ) {}

  getSelectedPractice(): string {
    return this.userService
      .getUser()
      .dental_group.find(
        ({ guid }: Practice) => guid === this.form.get('practice').value
      );
  }

  getFirstLineOfPracticeName(practiceName: string, maxLineOfLabel): string {
    if (practiceName && practiceName.length > maxLineOfLabel) {
      const breakIndex = this.getBreakNameIndex(practiceName, maxLineOfLabel);
      return breakIndex ? practiceName.substr(0, breakIndex) : practiceName;
    }
    return practiceName;
  }

  getSecondLineOfPracticeName(practiceName: string, maxLineOfLabel): string {
    if (!practiceName) {
      return;
    }

    if (practiceName.length <= maxLineOfLabel) {
      return '';
    } else {
      const breakIndex = this.getBreakNameIndex(practiceName, maxLineOfLabel);
      return breakIndex ? practiceName.slice(breakIndex) : '';
    }
  }

  private getBreakNameIndex(practiceName: string, maxLineOfLabel): number {
    const indices = [];
    for (let i = 0; i < practiceName.length; i++) {
      if (practiceName[i] === ' ') {
        indices.push(i);
      }
    }
    return indices.reverse().find((spaceIndex) => spaceIndex < maxLineOfLabel);
  }
}
