<div class="container">
  <div *ngIf="isLoading"
       class="screen-full-modal"
       (click)="(false)">
    <app-spinner class="screen-centered"></app-spinner>
  </div>

  <div class="row">
    <div class="valign-wrapper">
      <div class="col s8">
        <h5>{{ 'SCHEDULE.TITLE' | translate }}</h5>
      </div>
      <div class="col s4 schedule-datepicker-container">
        <a
          class="secondary-content date-picker-label"
          (click)="showDatePicker()"
          >{{ date | date: 'MM/dd/yyyy' }}
        <mat-icon svgIcon="date_range"></mat-icon>
        </a>
        <input #datePicker
               type="date"
               id="date"
               class="secondary-content"
               [(ngModel)]="date"
               (change)="reloadScheduleOnChange()"
               (blur)="reloadScheduleOnBlur()" />
      </div>
    </div>
  </div>
  <div class="row filters">
    <div class="input-field col s6">
      <span>{{ 'SCHEDULE.FILTER_BY_PROVIDER' | translate }}</span>
      <select id="provider"
              [(ngModel)]="currentProvider"
              (ngModelChange)="onProviderChange()">
        <option [value]="null">{{ 'SCHEDULE.NONE' | translate }}</option>
        <option *ngFor="let provider of fullSchedule?.providers"
                [value]="provider.bo_context">
          {{ provider.name }}
        </option>
      </select>
    </div>
    <div class="input-field col s6">
      <span>{{ 'SCHEDULE.FILTER_BY_OPERATORY' | translate }}</span>
      <select id="operatory"
              [(ngModel)]="currentOperatory"
              (ngModelChange)="onOperatoryChange()">
        <option [value]="null">{{ 'SCHEDULE.NONE' | translate }}</option>
        <option *ngFor="let op of fullSchedule?.ops"
                [value]="op.bo_context">
          {{ op.name }}
        </option>
      </select>
    </div>
  </div>
  <ul class="collection">
    <li *ngFor="let appointment of schedule"
        class="collection-item avatar {{ appointment.status }}"
        (click)="onAppointmentClicked(appointment)">
      <img src="{{
          appointment.patient.image
            ? appointment.patient.image
            : 'assets/images/avatar.png'
        }}"
           class="circle" />
      <span class="title">{{ appointment.scheduled_for | date: 'h:mm a' }} -
        {{ appointment.end_scheduled_for | date: 'h:mm a' }}</span>
      <p>
        {{ appointment.patient.getFullName() }}<br />
        {{ appointment.reason }}
      </p>
      <mat-icon class="secondary-content confirm-icon"
                [ngClass]="{ confirmed: appointment.confirmed }"
                [svgIcon]="appointment.confirmed ? 'check_circle_outline' : 'help_outline'">
      </mat-icon>
    </li>
  </ul>
  <p *ngIf="
      !isLoading &&
      schedule.length === 0 &&
      (currentOperatory !== null || currentProvider !== null)
    "
     class="center-align">
    {{ 'SCHEDULE.EMPTY' | translate }}
  </p>
  <p *ngIf="!isLoading && currentOperatory === null && currentProvider === null"
     class="center-align">
    {{ 'SCHEDULE.NO_FILTER' | translate }}
  </p>
</div>
