<div class="row">
  <div class="col s12 center-align">
    <h4 class="text-primary center">
      {{ 'ERROR.NO_CONNECTION.TITLE' | translate }}
    </h4>
    <br />
    <p class="text-primary center">
      {{ 'ERROR.NO_CONNECTION.DESCRIPTION' | translate }}
    </p>
    <br />
    <button (click)="tryAgain()"
            class="btn btn-small center">
      {{ 'ERROR.NO_CONNECTION.TRY_AGAIN' | translate }}
    </button>
  </div>
</div>
