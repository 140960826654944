import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NavigationService } from '../../navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.scss']
})
export class ViewFileComponent implements OnInit, AfterViewInit, OnDestroy {
  fileUrl = 'about:blank';

  constructor(
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    const params: Params = this.route.snapshot.queryParams;
    if (params.hasOwnProperty('url')) {
      this.fileUrl = params.url;
    }
    if (params.hasOwnProperty('filename')) {
      this.navigationService.setPageTitle(
        this.translateService.instant('VIEW_FILE.TITLE')
      );
    }
  }

  ngAfterViewInit(): void {
    this.setupIframeStyle();
  }

  iframeLoaded() {
    this.setupIframeStyle();
  }

  private setupIframeStyle() {
    const el: Element = document.getElementsByTagName('iframe')[0];
    const iframe: HTMLIFrameElement = el as HTMLIFrameElement;
    if (iframe) {
      const style = document.createElement('style');
      style.innerHTML = this.getIframeStyle();
      iframe.contentWindow.document.body.appendChild(style);
    }
  }

  private getIframeStyle(): string {
    return 'img { width: 100%; }';
  }

  ngOnDestroy(): void {
    if (this.fileUrl && this.fileUrl.startsWith('blob:')) {
      URL.revokeObjectURL(this.fileUrl);
    }
  }
}
