import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AlertsService } from '../../common/alerts/alerts.service';
import { AuthService } from '../../common/authentication/auth.service';
import { UserService } from '../../common/user';
import { PracticeService } from '../../common/practice';
import { UserData } from '../../common/user/user-data.model';
import { NativeAppService } from '../../common/native-app-service/native-app.service';
import { DeviceService } from '../../common/device-service/device.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from, Subject } from 'rxjs';
import { TwoFactorAuthenticationDialogService } from './two-factor-authentication-dialog/two-factor-authentication-dialog.service';
import { TwoFactorAuthenticationDialogCloseReason } from './two-factor-authentication-dialog/two-factor-authentication-dialog.types';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('password') passwordRef: ElementRef;

  @Output() logInSuccess = new EventEmitter<boolean>();

  isSubmitting: boolean;
  platform: string;

  form: FormGroup = this.fb.group({
    email: '',
    password: '',
    rememberMe: true
  });

  fixForAutofillOnIos$ = this.form.valueChanges
    .pipe(
      // wait for 500ms to be sure the autofill is finished with the password field
      debounceTime(500),
      tap((changes) => {
        // manually fetch the value from nativeElement
        const passVal = this.passwordRef.nativeElement.value;
        // if the value saved in the form is different from the input, manually update it
        if (passVal !== changes.password) {
          this.form.get('password').setValue(passVal);
        }
      }),
      untilDestroyed(this)
    )
    .subscribe();

  private readonly mfaRequired$: Subject<string> = new Subject<string>();

  constructor(
    private alertsService: AlertsService,
    private authService: AuthService,
    private userService: UserService,
    private practiceService: PracticeService,
    private nativeService: NativeAppService,
    private deviceService: DeviceService,
    private fb: FormBuilder,
    private readonly twoFactorAuthenticationDialogService: TwoFactorAuthenticationDialogService
  ) {}

  ngOnInit(): void {
    this.platform = this.deviceService.getPlatform();
    this.platform = (this.platform ? this.platform : 'ios').toUpperCase();

    this.subscribeToMFARequired();
  }

  async onLogIn(formValue): Promise<any> {
    this.isSubmitting = true;
    try {
      const response = await this.authService.logInPassword(
        formValue.email,
        formValue.password,
        'staff',
        this.userService.getDeviceUuid(formValue.email)
      );
      this.userService.setUserData(
        {
          ...response,
          username: formValue.email
        } as UserData,
        formValue.rememberMe
      );
      if (response.device_uuid) {
        this.userService.setDeviceUuid(response.device_uuid, formValue.email);
      }
      this.practiceService.setPracticeGuid(response.practice_guid);
      await this.practiceService.loadPracticeIfNeeded();
      await this.userService.loadUser();
      this.logInSuccess.emit(true);
    } catch (e) {
      if (e.error && e.error.device_uuid) {
        this.userService.setDeviceUuid(e.error.device_uuid, formValue.email);
      }
      if (e.error?.error === 'unknown_device') {
        this.alertsService.showApiWarning(e);
      } else if (e.error?.error !== 'mfa_required') {
        this.alertsService.showApiError(e);
      }
      if (e.error?.error === 'mfa_required') {
        this.mfaRequired$.next(e.error.email);
      }
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  async onPasswordKeyDown($event): Promise<any> {
    if ($event.key === 'Enter') {
      await this.onLogIn(this.form.value);
    }
  }

  onTermsClicked(): void {
    this.nativeService.openExternalUrl('https://www.modento.io/terms');
  }

  onPrivacyClicked(): void {
    this.nativeService.openExternalUrl('https://www.modento.io/privacy');
  }

  private subscribeToMFARequired(): void {
    this.mfaRequired$
      .pipe(
        switchMap((email) =>
          this.twoFactorAuthenticationDialogService
            .open(email, this.form.value.email)
            .afterClosed()
            .pipe(
              filter(
                (closeReason: TwoFactorAuthenticationDialogCloseReason) =>
                  closeReason ===
                  TwoFactorAuthenticationDialogCloseReason.VERIFIED
              ),
              switchMap(() => from(this.onLogIn(this.form.value)))
            )
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
