import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScheduleService } from './schedule.service';
import { TranslateModule } from '@ngx-translate/core';
import { AppCommonModule } from '../../common/common.module';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ScheduleComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslateModule,
    FormsModule,
    MatIconModule
  ],
  providers: [ScheduleService]
})
export class ScheduleModule {}
