import { Inject, Injectable } from '@angular/core';
import { LoginResponse } from './login-response.model';
import { API_URL, CLIENT_ID, ANALYTICS_API_URL } from '../../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserCredential } from 'firebase/auth';
import { AnalyticsUser } from '../user/user-data.model';
import { firstValueFrom, map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthService {
  constructor(
    private readonly http: HttpClient,
    @Inject(API_URL) private readonly apiUrl: string,
    @Inject(ANALYTICS_API_URL) private readonly analyticsApiUrl: string,
    @Inject(CLIENT_ID) private readonly clientId: number
  ) {}

  async hasAccessToAnalytics(
    userCredentials: UserCredential
  ): Promise<LoginResponse> {
    const firebaseUser = userCredentials.user;
    const firebaseAccessToken = await firebaseUser.getIdToken();
    const firebaseRefreshToken = firebaseUser.refreshToken;
    return firstValueFrom(
      this.http
        .get<AnalyticsUser>(`${this.analyticsApiUrl}/Users/MyInfo`, {
          headers: new HttpHeaders().set(
            'Authorization',
            `Bearer ${firebaseAccessToken}`
          )
        })
        .pipe(
          map((response) => {
            return Promise.resolve({
              token_type: 'Bearer',
              access_token: firebaseAccessToken,
              refresh_token: firebaseRefreshToken,
              practice_guid: response.practiceList?.[0]?.practiceId ?? null
            } as LoginResponse);
          })
        )
    );
  }

  async hasAccessToEngagement(idToken: string): Promise<LoginResponse> {
    const response = await firstValueFrom(
      this.authenticateToEngagement(idToken)
    );

    if (!response) {
      return {
        token_type: 'Bearer',
        access_token: null,
        refresh_token: null,
        practice_guid: null
      } as LoginResponse;
    }

    return response;
  }

  logout(): Promise<any> {
    return firstValueFrom(this.http.get<any>(`${this.apiUrl}/oauth/logout`));
  }

  private authenticateToEngagement(
    idToken: string
  ): Observable<LoginResponse | null> {
    const url = `${this.apiUrl}/firebase-auth/login`;
    const body = {
      id_token: idToken,
      client_id: this.clientId,
      grant_type: 'password',
      role: 'staff'
    };
    return this.http.post<LoginResponse>(url, body).pipe(
      catchError((error) => {
        console.log(error); // TODO: Replace with logging service when available.
        return of(null);
      })
    );
  }
}
