<div id="{{ modalId }}"
     class="modal"
     #modal>
  <div class="modal-content">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <a
      (click)="closeModal()"
      class="modal-close waves-effect waves-green btn-flat"
      >{{ 'COMMON.MODAL.CLOSE' | translate }}</a>
  </div>
</div>
