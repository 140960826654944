import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Materialize } from './materialize';
import { TranslateService } from '@ngx-translate/core';
import { NativeAppService } from './common/native-app-service/native-app.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppIconRegistryService } from './common/icons-registry/icons-registry.service';

declare const M: Materialize;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private nativeAppService: NativeAppService,
    private appIconsRegistryService: AppIconRegistryService,
    private cd: ChangeDetectorRef
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translateService.use(translateService.getBrowserLang());
  }

  ngOnInit(): void {
    this.appIconsRegistryService.registerIcons();
    this.cd.detectChanges();
    M.AutoInit();
  }
}
