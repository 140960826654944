import { Component } from '@angular/core';
import { NativeAppService } from '../native-app-service/native-app.service';

@Component({
  selector: 'app-dev-debug-actions',
  templateUrl: 'dev-debug-actions.component.html',
  styleUrls: ['./dev-debug-actions.component.scss']
})
export class DevDebugActionsComponent {
  constructor(private nativeAppService: NativeAppService) {}

  onTriggerPushArrived(): void {
    this.nativeAppService.nativeEventHandler({
      event: 'push-arrived'
    });
  }

  onTriggerAppResumed(): void {
    this.nativeAppService.nativeEventHandler({
      event: 'app-resumed'
    });
  }
}
