import { Component, Input } from '@angular/core';
import { NativeAppService } from '../native-app-service/native-app.service';

@Component({
  selector: 'app-external-url-viewer',
  templateUrl: './external-url-viewer.component.html',
  styleUrls: ['./external-url-viewer.component.scss']
})
export class ExternalUrlViewerComponent {
  @Input() url = '';

  constructor(private readonly nativeAppService: NativeAppService) {}

  close() {
    this.nativeAppService.closeExternalUrl();
  }
}
