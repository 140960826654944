import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientChatService } from '../patient-chat.service';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { Conversation } from '../conversation.model';
import { ActionResponse } from '../../../common/mobiati-http/action-response.model';
import { NavigationService } from '../../navigation/navigation.service';
import { ExtendedMessage } from './extended-message.model';
import { Subscription } from 'rxjs';
import { NativeAppService } from '../../../common/native-app-service/native-app.service';
import { Modal } from '../../../materialize';
import {
  AUTO_CHANNELS,
  CommLogsChannelsEnum
} from '../../../common/comm-log/comm-logs-channels.enum';
import { Practice, PracticeService } from '../../../common/practice';
import { CommunicationService } from '../../../common/comm-service/comm.service';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading: boolean;
  messages: ExtendedMessage[];
  conversation: Conversation;
  @ViewChild('messagesContainer')
  messagesContainer: ElementRef;
  patientInfoModal: Modal;
  autoDeliveryChannel = '';
  // Push notification subscription
  protected pushNotificationSub: Subscription;
  // App focus event subscription
  protected appFocusChangeSub: Subscription;
  protected practice: Practice;

  constructor(
    private activatedRoute: ActivatedRoute,
    public patientChatService: PatientChatService,
    private alertsService: AlertsService,
    private navigationService: NavigationService,
    private nativeService: NativeAppService,
    private practiceService: PracticeService,
    private commService: CommunicationService
  ) {}

  ngOnInit(): void {
    this.practice = this.practiceService.getPractice();

    this.conversation = this.patientChatService.getConversationById(
      Number(this.activatedRoute.snapshot.params.id)
    );

    if (!this.conversation) {
      // should never happen, but let's reload application
      location.replace('/');
      return;
    }
    this.navigationService.setPageTitle(this.conversation.getFullName());
    this.pushNotificationSub = this.nativeService
      .pushNotificationsObservable()
      .subscribe((pushData) => this.handlePushNotification(pushData));
    this.appFocusChangeSub = this.nativeService
      .focusChangeObservable()
      .subscribe((hasFocus) => this.handleFocusChange(hasFocus));

    this.loadMessages();
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    this.navigationService.setPageTitle('');
    if (this.pushNotificationSub) {
      this.pushNotificationSub.unsubscribe();
    }
    if (this.appFocusChangeSub) {
      this.appFocusChangeSub.unsubscribe();
    }
  }

  private scrollToBottom(): void {
    if (this.messagesContainer) {
      const elem = this.messagesContainer.nativeElement;
      elem.scrollTop = elem.scrollHeight;
    }
  }

  private async loadMessages(): Promise<any> {
    try {
      this.isLoading = true;
      this.messages = await this.patientChatService.getMessages(
        this.conversation.id
      );
      setTimeout(() => this.scrollToBottom(), 0);
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isLoading = false;
      setTimeout(() => this.pickAutoDeliveryChannel(), 0);
    }
  }

  onMessageSent($event: ActionResponse): void {
    this.loadMessages();
  }

  private handlePushNotification(pushData: object): void {
    // TODO: only refresh if relevant data
    this.loadMessages();
  }

  private handleFocusChange(hasFocus: boolean): void {
    if (hasFocus) {
      this.loadMessages();
    }
  }

  callPatient(): void {
    const phone = this.conversation.mobile_phone
      ? this.conversation.mobile_phone
      : this.conversation.guardian.mobile_phone;
    this.nativeService.call(phone);
  }

  emailPatient(): void {
    const email = this.conversation.email
      ? this.conversation.email
      : this.conversation.guardian.email;
    this.nativeService.mailTo(email);
  }

  showPatientInfo(): void {
    this.navigationService.showPatientInfo(this.conversation);
  }

  // Similar code (copied) from Dashboard Patient Chat Service.
  private pickAutoDeliveryChannel(): void {
    if (!this.messages) {
      return;
    }

    const lastFromPatientMessage = this.getLastMessage(this.messages, true);

    const channels = this.getPreferredChannels(lastFromPatientMessage);

    if (!channels.length) {
      this.autoDeliveryChannel = '';
      return;
    }

    let defaultChannel = channels[0];

    // prefer alt (backup) SMS over plain SMS in cases when last message sent from the office to patient was using alt number
    let lastFromOfficeMessage;
    if (
      defaultChannel === CommLogsChannelsEnum.SMS &&
      this.commService.canSendAltSMS(this.conversation, this.practice) &&
      // tslint:disable-next-line:no-conditional-assignment
      (lastFromOfficeMessage = this.getLastMessage(this.messages, false)) &&
      this.wasBackupSMSUsed(lastFromOfficeMessage)
    ) {
      defaultChannel = CommLogsChannelsEnum.SMS_ALT;
    }

    this.autoDeliveryChannel = defaultChannel;
  }

  protected getPreferredChannels(lastPatientMessage: any): string[] {
    const copyArrChannels = AUTO_CHANNELS.slice().filter((channel: string) => {
      return (
        (channel === CommLogsChannelsEnum.PUSH &&
          this.conversation.can_receive_push) ||
        (channel === CommLogsChannelsEnum.SMS &&
          this.conversation.can_receive_sms) ||
        // tslint:disable-next-line:prettier
        (channel === CommLogsChannelsEnum.EMAIL &&
          this.conversation.can_receive_email)
      );
    });

    if (lastPatientMessage && lastPatientMessage.channel) {
      // when patient responds from app, the channel is 'app' so we need to convert to outgoing channel (push)
      const lastPatientChannel =
        lastPatientMessage.channel === 'app'
          ? CommLogsChannelsEnum.PUSH
          : lastPatientMessage.channel;

      // prefer channel which was least recently used by patient to respond
      const index = copyArrChannels.findIndex(
        (channel: string) => channel === lastPatientChannel
      );
      if (index >= 0) {
        copyArrChannels.unshift(...copyArrChannels.splice(index, 1));
      }
    }
    return copyArrChannels;
  }

  protected wasBackupSMSUsed(lastPatientMessage: any): boolean {
    if (!lastPatientMessage) {
      return false;
    }

    if (
      lastPatientMessage.transformed &&
      lastPatientMessage.transformed.isPatient
    ) {
      return (
        lastPatientMessage.to &&
        lastPatientMessage.to === this.practice.extra_sms_full_number
      );
    } else {
      return (
        lastPatientMessage.from &&
        lastPatientMessage.from === this.practice.extra_sms_full_number &&
        lastPatientMessage.delivery_status !== false
      );
    }
  }

  protected getLastMessage(messages: any[], fromPatient?: boolean): any {
    const reversedMessages = JSON.parse(JSON.stringify(messages)).reverse();
    let lastMessage;

    for (const message of reversedMessages) {
      if (
        fromPatient === undefined ||
        (fromPatient && message.is_patient) ||
        (!fromPatient && !message.is_patient)
      ) {
        lastMessage = message;
        break;
      }

      if (lastMessage) {
        break;
      }
    }

    return lastMessage ? lastMessage : undefined;
  }
}
