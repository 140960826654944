import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NativeAppService } from '../native-app-service/native-app.service';
import { TeamChatService } from '../team-chat/team-chat.service';
import { UserNotificationsService } from '../user-notifications/user-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {
  readonly count$: Observable<number> = combineLatest([
    this.teamChatService.getUnreadCount(),
    this.userNotificationService.getUnreadCount()
  ]).pipe(
    map(
      (nums: number[]) =>
        nums.reduce((prevCount, currCount) => prevCount + currCount),
      0
    )
  );

  constructor(
    private readonly nativeService: NativeAppService,
    private readonly teamChatService: TeamChatService,
    private readonly userNotificationService: UserNotificationsService
  ) {}

  resetBadgeCounter(): void {
    this.nativeService.updateBadgeCounter(0);
  }
}
