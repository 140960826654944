import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ANALYTICS_FEATURES } from './permissions.model';
import { PracticeService } from '../practice';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard {
  public constructor(
    private readonly router: Router,
    private readonly practiceService: PracticeService
  ) {}

  public canActivate({ data }: ActivatedRouteSnapshot): Observable<boolean> {
    const { permission } = data;
    const hasEngagementAccess = this.practiceService.getPracticeGuid() != null;
    const hasAnalyticsAccess =
      this.practiceService.getAnalyticsPracticeGuid() != null;

    if (hasAnalyticsAccess && ANALYTICS_FEATURES.includes(permission)) {
      return of(true);
    }

    if (hasEngagementAccess && !ANALYTICS_FEATURES.includes(permission)) {
      return of(true);
    }

    this.router.navigate(['request-access'], {
      queryParams: {
        pageName: permission
      }
    });

    return of(false);
  }
}
