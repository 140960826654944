export function applyDropdownFixForMobile() {
  // slice.call to convert NodeList ot Array so we are sure we have forEach available
  const elements = document.querySelectorAll(
    'li[id^="select-options"],#context-action-dropdown.dropdown-content > li'
  );
  if (elements) {
    Array.prototype.slice.call(elements).forEach((item) => {
      item.removeEventListener('touchend', stopEventPropagation);
      item.addEventListener('touchend', stopEventPropagation);
    });
  }
}

function stopEventPropagation(e) {
  e.stopPropagation();
}
