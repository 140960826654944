import { HttpErrorResponse } from '@angular/common/http';

export enum LoadingState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED'
}

export interface ErrorState {
  message: string;
  code: number;
  error?: string;
  errors?: { [key in string]: string[] };
  device_uuid?: string;
}

export type CallState = LoadingState | ErrorState | number;

export const getErrorState = (error: HttpErrorResponse): ErrorState => {
  let message = '';
  if (error.error) {
    if (error.error.errors) {
      const serverErrors: string[][] = Object.values(error.error.errors);
      message = serverErrors.map((errors) => errors.join('<br>')).join('<br>');
    } else {
      message = error.error.message;
    }
  } else {
    message = error.message;
  }
  return {
    message,
    code: error.status,
    error: error.error?.error,
    device_uuid: error.error ? error.error.device_uuid : null,
    errors: error.error ? error.error.errors : null
  };
};
