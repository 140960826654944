import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { ScheduleService } from '../schedule.service';
import { Schedule } from '../schedule.model';
import { Materialize } from '../../../materialize';
import { applyDropdownFixForMobile } from '../../../common/utils/materialize-utils.helper';
import { UserService } from '../../../common/user';
import { Appointment } from '../appointment.model';
import { NavigationService } from '../../navigation/navigation.service';
import { NativeAppService } from '../../../common/native-app-service/native-app.service';
import { Subscription } from 'rxjs';
import { DeviceService } from '../../../common/device-service/device.service';
import { isEmpty } from '../../../common/utils/string-utils.helper';

declare const M: Materialize;

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading: boolean;
  date: string;
  currentProvider: string = null;
  currentOperatory: string = null;
  schedule: any[] = [];
  fullSchedule: Schedule;
  @ViewChild('datePicker')
  private datePicker: ElementRef;
  // Has focus event subscription
  protected appFocusChangeSub: Subscription;

  constructor(
    private scheduleService: ScheduleService,
    private userService: UserService,
    private alertsService: AlertsService,
    private navigationService: NavigationService,
    private nativeService: NativeAppService,
    private deviceService: DeviceService
  ) {}

  async ngOnInit() {
    this.date = this.toDateStr(new Date());
    // refresh user info to know which provider to preselect
    await this.reloadUser();
    const teamMember = this.userService.getTeamMember();
    this.currentProvider = teamMember ? teamMember.bo_context : null;

    this.reloadSchedule().then(() => {
      if (this.currentProvider === null && this.currentOperatory === null) {
        // if we couldn't choose default for user, let's use first operatory
        if (
          this.fullSchedule &&
          this.fullSchedule.ops &&
          this.fullSchedule.ops.length > 0
        ) {
          this.currentOperatory = this.fullSchedule.ops[0].bo_context;
          this.applyFilters();
        }
      }
    });
    this.appFocusChangeSub = this.nativeService
      .focusChangeObservable()
      .subscribe((hasFocus) => {
        if (hasFocus) {
          this.reloadSchedule().then();
        }
      });
  }

  ngAfterViewInit(): void {
    this.updateMaterializeUI();
  }

  // TODO: make it util?
  private toDateStr(date: Date) {
    return date.toISOString().substr(0, 'YYYY-MM-DD'.length);
  }

  async reloadUser() {
    try {
      this.isLoading = true;
      await this.userService.loadUser();
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async reloadSchedule() {
    if (isEmpty(this.date)) {
      this.date = this.toDateStr(new Date());
    }

    try {
      this.isLoading = true;
      this.fullSchedule = await this.scheduleService.getSchedule(this.date);
      this.applyFilters();
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isLoading = false;
    }
  }

  private updateMaterializeUI() {
    const elems = document.querySelectorAll('select');
    const instances = M.FormSelect.init(elems, {
      dropdownOptions: {
        coverTrigger: false,
        onOpenStart: () => {
          applyDropdownFixForMobile();
        }
      }
    });
  }

  applyFilters() {
    if (this.currentProvider === null && this.currentOperatory === null) {
      this.schedule = []; // need at least one filter
    } else {
      this.schedule = this.fullSchedule.live
        .filter((appt: Appointment) => {
          return (
            this.currentProvider === null ||
            this.currentProvider === appt.bo_provider_context
          );
        })
        .filter((appt: Appointment) => {
          return (
            this.currentOperatory === null ||
            this.currentOperatory === appt.bo_operatory_context
          );
        })
        .filter((appt: Appointment) => {
          return appt.status !== 'cancelled';
        });
    }
    setTimeout(() => this.updateMaterializeUI(), 0);
  }

  onProviderChange() {
    if (this.currentProvider === 'null') {
      this.currentProvider = null;
    }
    this.applyFilters();
  }

  onOperatoryChange() {
    if (this.currentOperatory === 'null') {
      this.currentOperatory = null;
    }
    this.applyFilters();
  }

  showDatePicker() {
    if (this.datePicker) {
      this.datePicker.nativeElement.focus();
      this.datePicker.nativeElement.click();
    }
  }

  onAppointmentClicked(appointment: Appointment) {
    this.navigationService.showPatientInfo(appointment.patient);
  }

  ngOnDestroy(): void {
    if (this.appFocusChangeSub) {
      this.appFocusChangeSub.unsubscribe();
    }
  }

  reloadScheduleOnBlur() {
    // iOS reloads on blur because (change) is signaled during interaction with date picker
    if (this.deviceService.isiOS()) {
      this.reloadSchedule();
    }
  }

  reloadScheduleOnChange() {
    // Android reloads on (change) as this is how date picker should work
    if (this.deviceService.isAndroid()) {
      this.reloadSchedule();
    }
  }
}
