export enum CommLogsChannelsEnum {
  SMS = 'sms',
  EMAIL = 'email',
  CALL = 'call',
  FEED = 'feed',
  PUSH = 'push',
  SMS_ALT = 'sms_alt',
  AUTO = 'auto'
}

export const CHANNELS_REQUIRING_DELIVERY = [
  CommLogsChannelsEnum.SMS,
  CommLogsChannelsEnum.EMAIL,
  CommLogsChannelsEnum.CALL
] as string[];

export const AUTO_CHANNELS = [
  CommLogsChannelsEnum.PUSH,
  CommLogsChannelsEnum.SMS,
  CommLogsChannelsEnum.EMAIL,
  CommLogsChannelsEnum.FEED
] as string[];
