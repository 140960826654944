export interface IGaugeOptions {
  arc: {
    startAngle: number;
    endAngle: number;
  };
  bar: {
    width: number;
  };
  gutter: {
    width: number;
    color: string;
  };
  tick: {
    length: number;
    width: number;
  };
  isRounded: boolean;
  backgroundColor: string;
}

export enum ChartColor {
  Primary = 'rgb(0, 96, 198)',
  Restorative = 'rgb(255, 116, 1)',
  Hygiene = 'rgb(103, 64, 139)',
  Combined = 'rgb(84, 160, 160)',
  Background = 'rgb(223, 235, 241)',
  Goal = 'rgb(56, 191, 136)',
  Positive = 'rgb(9, 168, 105)',
  Negative = 'rgb(240, 81, 81)',
  Neutral = 'rgb(102, 123, 152)',
  NegativeCompliment = 'rgb(255, 183, 183)',
  PrimaryCompliment = 'rgb(102, 198, 255)',
  PrimaryUnrealized = 'rgba(0, 96, 198, 0.25)',
  RestorativeUnrealized = 'rgba(255, 116, 1, 0.25)',
  HygieneUnrealized = 'rgba(103, 64, 139, 0.25)',
  OtherUnrealized = 'rgb(27, 33, 40, 0.25)',
  Line = 'rgb(27, 33, 40)',
  Benchmark = 'rgb(193, 111, 58)',
  Crosshair = 'rgba(220, 220, 220, 0.5)',
  Tick = 'rgb(204, 214, 235)',
  HealthExcellent = 'rgb(80, 165, 110)',
  HealthGood = 'rgb(211, 180, 60)',
  HealthPoor = 'rgb(148, 48, 50)'
}

type Align = 'left' | 'center' | 'right';
type FontWeight = 'bold' | 'normal';

export interface IDashboardChartConfig {
  title?: {
    text: string;
    align?: Align;
    style?: {
      fontSize?: string;
      fontWeight?: FontWeight;
    };
  };
  subTitle?: {
    text: string;
    align?: Align;
    offsetTop?: number;
    style?: {
      fontSize?: string;
      fontWeight?: FontWeight;
    };
  };
  intervalType?: SeriesInterval;
  backgroundColor?: string;
  series: IChartSeriesConfig[];
  colors?: {
    palette?: string[];
    colorByPoint?: boolean;
  };
  stackLabels?: {
    enabled: boolean;
  };
  scroll?: {
    minWidth?: number;
  };
  xAxis?: IXAxisOptions[];
  yAxis: IYAxisOptions[];
  plot?: {
    groupPadding?: number;
    backgroundColor?: string;
    events?: {
      click?: (event: IChartEvent) => void;
    };
  };
  legend?: {
    enabled: boolean;
    layout?: 'vertical' | 'horizontal';
    align?: 'left' | 'right' | 'center';
    verticalAlign?: 'top' | 'middle' | 'bottom';
    floating?: boolean;
    itemWidth?: number;
    width?: number;
    padding?: number;
    margin?: number;
    itemDistance?: number;
    symbolRadius?: number;
    disableClicks?: boolean;
    labelFormatter?: (context: IChartLegendLabelContext) => string;
  };
  tooltip?: {
    enabled?: boolean;
    formatter?: (context: IChartTooltipContext) => string;
  };
  benchmark?: IChartBenchmarkConfig;
  alignYAxisThresholds?: boolean;
}

export interface IAxisOptions {
  title?: string;
  opposite?: boolean;
}
export interface IYAxisOptions extends IAxisOptions {
  minValue?: number;
  maxValue?: number;
  ceiling?: number;
  measurementType: MeasurementType;
  linkedTo?: number;
  dataLabels?: {
    precision?: number;
  };
}
export enum HorizontalAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}
export type CursorValue =
  | 'alias'
  | 'all-scroll'
  | 'auto'
  | 'cell'
  | 'col-resize'
  | 'context-menu'
  | 'copy'
  | 'crosshair'
  | 'default'
  | 'e-resize'
  | 'ew-resize'
  | 'grab'
  | 'grabbing'
  | 'help'
  | 'move'
  | 'n-resize'
  | 'ne-resize'
  | 'nesw-resize'
  | 'no-drop'
  | 'none'
  | 'not-allowed'
  | 'ns-resize'
  | 'nw-resize'
  | 'nwse-resize'
  | 'pointer'
  | 'progress'
  | 'row-resize'
  | 's-resize'
  | 'se-resize'
  | 'sw-resize'
  | 'text'
  | 'vertical-text'
  | 'w-resize'
  | 'wait'
  | 'zoom-in'
  | 'zoom-out';
export interface IXAxisOptions extends IAxisOptions {
  categories?: string[];
  labels?: {
    rotation?: number;
    autoRotation?: number[];
    step?: number;
    align?: HorizontalAlignment;
    style?: {
      textOverflow?: string;
      fontSize?: string;
      lineHeight?: string;
      color?: string;
      cursor?: CursorValue;
    };
    className?: string;
    hipaaProtected?: boolean;
    useHTML?: boolean;
    formatter?: (context: IChartLabelContext) => string;
  };
  reversed?: boolean;
  tick?: {
    width?: number;
    interval?: number;
    placement?: TickmarkPlacement;
  };
  plotLines?: {
    value: number;
    style?: ILineStyleOptions;
    label?: {
      text: string;
      x?: number;
      style?: {
        color?: string;
      };
    };
  }[];
}
export interface IChartLabelContext {
  label: SVGElement | HTMLElement | undefined;
  category: string;
}
export enum TickmarkPlacement {
  On = 'on',
  Between = 'between'
}

export interface IChartLegendLabelContext {
  name: string;
  group?: string;
}

export interface IChartTooltipContext {
  category: string;
  interval?: {
    startDate: Date;
    endDate: Date;
    type: SeriesInterval;
  };
  objective?: {
    name: string;
    value: number;
  };
  series: {
    value: number;
    percentage: number;
    custom?: { [x: string]: any };
    name: string;
    color: string;
    total: number;
    measurementType: MeasurementType;
  }[];
}

export enum SeriesInterval {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
  Enumerated = 'enumerated'
}

export interface IChartBenchmarkConfig {
  enabled?: true;
  showInLegend?: boolean;
  legendIndex?: number;
  name?: string;
  measurementType?: MeasurementType;
  value?: number;
  options?: {
    style?: ILineStyleOptions;
  };
}

export enum MeasurementType {
  Numeric = '#',
  Currency = '$',
  Percentage = '%',
  Ratio = ':'
}

export interface ILineStyleOptions {
  color?: string;
  lineStyle?: LineStyle;
  width?: number;
  showMarker?: boolean;
}

export enum LineStyle {
  Solid = 'solid',
  Dot = 'dotted',
  ShortDot = 'short-dot',
  Dash = 'dash',
  LongDash = 'long-dash'
}

export enum SeriesType {
  Column = 'column',
  Line = 'line',
  Slice = 'slice',
  Gauge = 'gauge',
  Spline = 'spline'
}
export interface ISeriesValue {
  [x: string]: any;
  value: number | null;
}
export interface IChartSeriesConfig {
  name: string;
  group?: string;
  color?: string;
  type: SeriesType;
  values: (number | ISeriesValue)[];
  opacity?: number;
  isVisible?: boolean;
  isMaster?: boolean;
  custom?: any;
  lineOptions?: ILineStyleOptions;
  dataLabels?: {
    enabled: boolean;
  };
  events?: {
    click?: (event: IChartEvent) => void;
  };
  trendLine?: IChartSeriesTrendlineConfig;
  yAxis?: number;
  showInLegend?: boolean;
  legendIndex?: number;
  maxItemWidth?: number;
}
export interface IChartEvent {
  point: {
    name: string;
  };
  series?: {
    name: string;
  };
  group?: {
    name: string | number;
  };
}

export interface IChartSeriesTrendlineConfig {
  enabled?: boolean;
  includeOpenPeriod?: boolean;
  showInLegend?: boolean;
  legendIndex?: number;
  name?: string;
  options?: {
    up: ILineStyleOptions;
    down: ILineStyleOptions;
    neutral: ILineStyleOptions;
  };
}
