<h5 class="allow-select">{{ patient.getFullName() }}</h5>
<p *ngIf="patient.guardian">
  {{ 'PATIENT_INFO.GUARDIAN' | translate }}
  <strong>{{ patient.guardian.first_name }}
    {{ patient.guardian.last_name }}</strong>
</p>

<div class="center-align">
  <app-spinner *ngIf="isLoading"></app-spinner>
</div>

<ul class="collection"
    *ngIf="!isLoading">
  <li class="collection-item">
    <strong>{{ 'PATIENT_INFO.PHONE' | translate }}</strong>
    <mat-icon *ngIf="patient.smsReceiver().mobile_phone"
              class="right action-icon"
              (click)="callPatient()"
              svgIcon="call"></mat-icon>
    <br />
    <span class="allow-select">{{
      patient.smsReceiver().mobile_phone
        ? (patient.smsReceiver().mobile_phone | phone)
        : 'n/a'
    }}</span>
    <small
           *ngIf="patient.phone_optout > 0"><br />{{ 'PATIENT_INFO.SMS_OPTOUT' | translate }}</small>
    <small
           *ngIf="patient.phone_undeliverable"><br />{{ 'PATIENT_INFO.SMS_UNDELIVERABLE' | translate }}</small>
  </li>
  <li class="collection-item">
    <strong>{{ 'PATIENT_INFO.EMAIL' | translate }}</strong>
    <mat-icon *ngIf="patient.emailReceiver().email"
              class="right action-icon"
              (click)="emailPatient()"
              svgIcon="email">
    </mat-icon>
    <br />
    <span class="allow-select">{{
      patient.emailReceiver().email ? patient.emailReceiver().email : 'n/a'
    }}</span>
    <small
           *ngIf="patient.email_optout > 0"><br />{{ 'PATIENT_INFO.EMAIL_OPTOUT' | translate }}</small>
    <small
           *ngIf="patient.email_undeliverable"><br />{{ 'PATIENT_INFO.EMAIL_UNDELIVERABLE' | translate }}</small>
  </li>
  <li class="collection-item">
    <strong>{{ 'PATIENT_INFO.DATE_OF_BIRTH' | translate }}</strong><br />
    <span class="allow-select">{{
      patient.date_of_birth | date: 'MM/dd/yyyy':'UTC'
    }}</span>
  </li>
  <li class="collection-item">
    <strong>{{ 'PATIENT_INFO.ADDRESS' | translate }}</strong><br />
    <span class="allow-select">{{ patient.getFullAddress() }}</span>
  </li>
  <li class="collection-item">
    <strong>{{ 'PATIENT_INFO.NEXT_VISIT' | translate }}</strong><br />
    <span class="allow-select">{{
      patient.next_visit_date
        ? (patient.next_visit_date | date: 'MM/dd/yyyy') +
          ' ' +
          (patient.next_visit_date | date: 'h:mm a' | lowercase)
        : 'not scheduled'
    }}</span>
    <small *ngIf="patient.next_visit_date"><br />{{
        patient.next_visit_confirmed
          ? ('PATIENT_INFO.CONFIRMED' | translate)
          : ('PATIENT_INFO.NOT_CONFIRMED' | translate)
      }}</small>
  </li>
</ul>

<div *ngIf="!isLoading"
     class="patient-info__bottom-btns">
  <button mat-raised-button
          type="button"
          (click)="onStartConversationClick(patient)">
    {{ 'PATIENT_INFO.START_CONVERSATION' | translate }}
  </button>
</div>
