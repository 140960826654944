import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Materialize, Modal } from '../../materialize';

declare const M: Materialize;

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit, AfterViewInit {
  @Input() modalId: string;
  @Input() startOpened = false;
  @Input() confirmMessage;
  @Input() cancelMessage;
  @Output() modalPluginSet = new EventEmitter<Modal>();
  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @ViewChild('modal')
  private modal: ElementRef;
  private modalPlugin: Modal;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.modalPlugin = M.Modal.init(this.modal.nativeElement);
    if (this.startOpened) {
      this.modalPlugin.open();
    }
    this.modalPluginSet.emit(this.modalPlugin);
  }

  onConfirm() {
    this.confirm.emit();
    this.modalPlugin.close();
  }

  onCancel() {
    this.cancel.emit();
    this.modalPlugin.close();
  }
}
