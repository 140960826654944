// date-picker.component.ts
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
  @Input() isRange: boolean = false;
  @Output() dateChange = new EventEmitter<{
    type?: string;
    startDate: Date;
    endDate?: Date;
    interval?: number;
  }>();

  startDate: Date = new Date();
  endDate: Date | null = null;
  isPickerOpen = false;
  activeTab: 'presets' | 'custom' = 'presets';
  @ViewChild('rangePicker') rangePicker: MatDatepicker<any>;
  @ViewChild('picker') picker: MatDatepicker<any>;

  presets = [
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'This Week', value: 'thisWeek' },
    { label: 'This Month', value: 'thisMonth' },
    { label: 'This QTR', value: 'thisQuarter' },
    { label: 'This Year', value: 'thisYear' },
    { label: 'Last Week', value: 'lastWeek' },
    { label: 'Last Month', value: 'lastMonth' },
    { label: 'Last QTR', value: 'lastQuarter' },
    { label: 'Last Year', value: 'lastYear' }
  ];

  constructor() {}

  async ngOnInit(): Promise<void> {
    const today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    this.startDate = this.isRange ? yesterday : today;
  }

  generateReport() {
    if (this.startDate && this.endDate) {
      this.dateChange.emit({
        startDate: this.startDate,
        endDate: this.endDate,
        interval: 0,
        type: 'custom'
      });
      this.isPickerOpen = false;
    }
  }

  toggleDatePicker() {
    if (this.isRange) {
      this.isPickerOpen = !this.isPickerOpen;
    } else {
      this.picker.open();
    }
  }

  closeDatePicker(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      this.isPickerOpen = false;
    }
  }

  onMaterialPickerClosed() {
    if (this.startDate && (!this.isRange || this.endDate)) {
      this.isPickerOpen = false;
    }
  }

  onDatePickerChange(date: Date) {
    if (this.isRange) {
      this.endDate = date;
    } else {
      this.startDate = date;
      this.endDate = date;
    }
    this.dateChange.emit({ startDate: this.startDate, endDate: this.endDate });
    this.isPickerOpen = false;
  }

  onDateSelect(date: Date) {
    this.onDatePickerChange(date);
  }

  applyPreset(preset: string) {
    const today = new Date();
    let start: Date;
    let end: Date;
    let interval: number;

    switch (preset) {
      case 'yesterday': {
        start = new Date(today);
        start.setDate(today.getDate() - 1);
        end = new Date(start);
        interval = 1;
        break;
      }

      case 'thisWeek': {
        start = new Date(today);
        start.setDate(today.getDate() - today.getDay());
        end = new Date(today);
        end.setDate(start.getDate() + 6);
        interval = 2;
        break;
      }

      case 'lastWeek': {
        start = new Date(today);
        start.setDate(today.getDate() - today.getDay() - 7);
        end = new Date(today);
        end.setDate(today.getDate() - today.getDay() - 1);
        interval = 2;
        break;
      }

      case 'thisMonth': {
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today);
        interval = 3;
        break;
      }

      case 'lastMonth': {
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        interval = 3;
        break;
      }

      case 'thisQuarter': {
        const currentQuarter = Math.floor(today.getMonth() / 3);
        start = new Date(today.getFullYear(), currentQuarter * 3, 1);
        end = new Date(today);
        interval = 4;
        break;
      }

      case 'lastQuarter': {
        const lastQuarter = Math.floor(today.getMonth() / 3) - 1;
        start = new Date(today.getFullYear(), lastQuarter * 3, 1);
        end = new Date(today.getFullYear(), (lastQuarter + 1) * 3, 0);
        interval = 4;
        break;
      }

      case 'thisYear': {
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date(today);
        interval = 5;
        break;
      }

      case 'lastYear': {
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        interval = 5;
        break;
      }
      default:
        return;
    }

    this.startDate = start;
    this.endDate = end;
    this.dateChange.emit({
      startDate: start,
      endDate: end,
      interval: interval,
      type: 'preset'
    });
    this.isPickerOpen = false;
  }
}
