import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'channel' })
export class DeliveryChannelPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'auto':
        return 'Auto';
      case 'sms':
        return 'Text';
      case 'sms_alt':
        return 'Text (alt)';
      case 'email':
        return 'Email';
      case 'push':
        return 'Mobile app + Push';
      default:
        return '';
    }
  }
}
