<div class="container">
  <div *ngIf="isLoading"
       class="screen-full-modal">
    <app-spinner class="screen-centered"></app-spinner>
  </div>
  <div class="row">
    <div class="valign-wrapper">
      <div class="col s4">
        <div class='title-wrapper'>
          <h5 class='title'>{{ 'STATISTICS.TITLE' | translate }}</h5>
          <mat-icon class='icon'
                    svgIcon='settings'
                    [routerLink]='"/stats/settings"'></mat-icon>
        </div>
      </div>
      <div class="col s8">
        <div class="schedule-datepicker-container">
          <div class="row">
            <app-date-picker [isRange]=true
                             (dateChange)="onDateChange($event)">
            </app-date-picker>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="gauge-container">
    <p class="row align-center"><b>{{ readMetricLabel() }}</b></p>
  </div>
  <!-- Primary and Secondary Metric Gauges -->
  <div class="row gauge-container primary-metric">
    <div [class.col]="!secondaryMetric"
         [class.s3]="!secondaryMetric"></div>
    <div class="col s6">
      <app-circular-gauge [chartConfig]="primaryChartConfig"
                          #primaryChart></app-circular-gauge>
      <p *ngIf="primaryMetric"
         class="align-center">
        {{ primaryMetric.metricLabel }}<br />{{ primaryMetric.value }}
      </p>
    </div>
    <div [class.col]="!secondaryMetric"
         [class.s3]="!secondaryMetric"></div>
    <div class="col s6"
         *ngIf="secondaryMetric">
      <app-circular-gauge [chartConfig]="secondaryChartConfig">
      </app-circular-gauge>
      <div class='secondary-metric'>
        <p>{{ secondaryMetric.metricLabel }}</p>
        <p>
          {{ secondaryMetric.value }}
          <span *ngIf='secondaryMetric?.goal'> / </span>
          <span *ngIf='secondaryMetric?.goal'
                class='goal'>{{ secondaryMetric.goal }}</span>
        </p>
      </div>
    </div>
  </div>
  <div>
    <div class="metrics-container">
      <ng-container *ngFor="let metric of metrics">
        <div *ngIf='metric.visible ?? true'
             class="metric-card">
          <div class="metric-icon">
            <mat-icon class="metric-icon"
                      svgIcon="{{metric.icon}}"></mat-icon>
          </div>
          <div class="metric-content">
            <h3>{{ metric.label }}</h3>
            <p>{{ metric.value }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
