import { Inject, Injectable } from '@angular/core';
import { LoginRequest } from './login-request.model';
import { LoginResponse } from './login-response.model';
import { RefreshTokenRequest } from './refresh-token-request.model';
import { API_URL, CLIENT_ID, CLIENT_SECRET } from '../../app.config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string,
    @Inject(CLIENT_ID) private clientId: number,
    @Inject(CLIENT_SECRET) private clientSecret: string
  ) {}

  logInPassword(
    username: string,
    password: string,
    role: string,
    deviceUuid?: string,
    scope: string = '',
    practiceGuid?: string
  ): Promise<LoginResponse> {
    const request = {
      grant_type: 'password',
      client_id: this.clientId,
      client_secret: this.clientSecret,
      scope,
      username,
      password,
      role,
      practice_guid: practiceGuid,
      device_uuid: deviceUuid
    } as LoginRequest;

    const url =
      request.practice_guid !== undefined && request.practice_guid !== null
        ? `${this.apiUrl}/oauth/token`
        : `${this.apiUrl}/oauth/token-without-practice`;

    return this.http.post<LoginResponse>(url, request).toPromise();
  }

  logInClient(
    scope: string = '',
    practiceGuid?: string
  ): Promise<LoginResponse> {
    const request = {
      grant_type: 'client_credentials',
      client_id: this.clientId,
      client_secret: this.clientSecret,
      scope,
      practice_guid: practiceGuid
    } as LoginRequest;

    const url =
      request.practice_guid !== undefined && request.practice_guid !== null
        ? `${this.apiUrl}/oauth/token`
        : `${this.apiUrl}/oauth/token-without-practice`;

    return this.http.post<LoginResponse>(url, request).toPromise();
  }

  refreshToken(refreshToken: string, scope: string): Promise<LoginResponse> {
    const request = {
      grant_type: 'refresh_token',
      client_id: this.clientId,
      client_secret: this.clientSecret,
      refresh_token: refreshToken,
      scope
    } as RefreshTokenRequest;

    return this.http
      .post<LoginResponse>(`${this.apiUrl}/oauth/token`, request)
      .toPromise();
  }

  logout(): Promise<any> {
    return this.http
        .get<any>(`${this.apiUrl}/oauth/logout`)
        .toPromise();
  }
}
