import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../app.config';
import {
  AnalyticsPractice,
  EngagementPractice,
  Practice,
  PracticeInfo,
  PracticeList
} from './practice.model';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PracticeService {
  private practice: Practice;
  private practiceGuid: string;
  private analyticsPracticeGuid: string;
  private combinedPractices: any[];
  private readonly state$ = new BehaviorSubject<PracticeInfo | null>(null);
  readonly practiceState$ = this.state$.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly cookieService: CookieService,
    @Inject(API_URL) private readonly apiUrl: string
  ) {
    this.practiceGuid = this.cookieService.get('practice_guid');
    this.analyticsPracticeGuid = this.cookieService.get(
      'analytics_practice_guid'
    );
    this.combinedPractices = JSON.parse(localStorage.getItem('practices'));
    const storedPractice = JSON.parse(localStorage.getItem('practice'));
    if (storedPractice) {
      console.log('Restored practice from local storage');
      this.practice = storedPractice;
      this.practiceGuid = this.practice.guid;
    }
  }

  async getPracticeData(): Promise<Practice> {
    if (this.practiceGuid == null) {
      return undefined;
    }
    return await firstValueFrom(
      this.http.get<Practice>(`${this.apiUrl}/${this.practiceGuid}/practice`)
    );
  }

  async lazyRefreshPracticeData(): Promise<Practice> {
    if (!this.practice || this.practice.guid !== this.practiceGuid) {
      this.setPractice(await this.getPracticeData());
    } else {
      this.getPracticeData().then((practice) => this.setPractice(practice));
    }

    return Promise.resolve(this.practice);
  }

  /**
   * Loads practice data based on guid if not loaded already.
   */
  async loadPracticeIfNeeded(): Promise<Practice> {
    if (
      this.practiceGuid !== null &&
      (!this.practice || this.practice.guid !== this.practiceGuid)
    ) {
      this.setPractice(
        await firstValueFrom(
          this.http.get<Practice>(
            `${this.apiUrl}/${this.practiceGuid}/practice`
          )
        )
      );
    }

    return Promise.resolve(this.practice);
  }

  setPractice(practice: Practice) {
    this.practice = practice;
    this.practiceGuid = practice.guid;

    const { id, guid, name } = practice;
    const engagementPractice: EngagementPractice = {
      id,
      guid,
      name,
      type: 'Engagement'
    };
    this.state$.next(engagementPractice);

    localStorage.setItem('practice', JSON.stringify(this.practice));
  }

  getPractice(): Practice {
    return this.practice;
  }

  setPracticeGuid(guid: string) {
    this.practiceGuid = guid;
  }

  setCombinedPractices(combinedPractices: PracticeList[]) {
    this.combinedPractices = combinedPractices;
    localStorage.setItem('practices', JSON.stringify(combinedPractices));
  }

  getCombinedPractices(): PracticeList[] {
    return this.combinedPractices;
  }

  getPracticeGuid(): string {
    return this.practiceGuid;
  }

  setAnalyticsPracticeGuid(guid: string) {
    this.analyticsPracticeGuid = guid;
    this.cookieService.put('analytics_practice_guid', guid);
  }

  getAnalyticsPracticeGuid(): string {
    return this.analyticsPracticeGuid;
  }

  clearPracticeInfo() {
    delete this.practice;
    delete this.practiceGuid;
    this.state$.next(null);
    localStorage.removeItem('practice');
  }

  setPracticeInfo(practice: PracticeList): void {
    if (practice?.eng_id && practice?.eng_guid) {
      const { eng_id: id, eng_guid: guid, name } = practice;
      const engagementPractice: EngagementPractice = {
        id,
        guid,
        name,
        type: 'Engagement'
      };
      this.state$.next(engagementPractice);
      return;
    }

    if (practice?.analytics_id) {
      const { analytics_id: id, name } = practice;
      const analyticsPractice: AnalyticsPractice = {
        id,
        name,
        type: 'Analytics'
      };
      this.state$.next(analyticsPractice);
      return;
    }

    this.state$.next(null);
  }
}
