import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  finalize,
  firstValueFrom,
  from,
  Observable,
  of
} from 'rxjs';
import { AnalyticsHttpService } from '../../mobiati-http/analytics-http.service';
import { Provider } from './provider';
import { AlertsService } from '../../alerts/alerts.service';
import { catchError, map } from 'rxjs/operators';
import { LoadingService } from '../loading/loading.service';
import { PracticeService } from '../../practice';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PracticeInfo } from '../../practice/practice.model';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  private readonly _default = {
    provider: {
      id: 'default',
      displayName: 'All'
    },
    providers: []
  };
  private _provider: Provider = this._default.provider;
  private _providers: Provider[] = this._default.providers;
  private readonly state$ = new BehaviorSubject<{
    provider: Provider;
    providers: Provider[];
  }>(this._default);
  readonly providerState$ = this.state$.asObservable();

  constructor(
    private readonly http: AnalyticsHttpService,
    private readonly alertService: AlertsService,
    private readonly loadingService: LoadingService,
    private readonly practiceService: PracticeService
  ) {
    this.subscribe();
  }

  selectProvider(providerId: string): void {
    const provider = this._providers.find(
      (provider) => provider.id === providerId
    );
    this._provider = provider ?? this._default.provider;
    this.next();
  }

  private readProviders(practiceId: string): Observable<Provider[]> {
    const url = `app/practice/${practiceId}/provider`;
    const promise = this.http.get<Provider[]>(url);

    this.loadingService.start();

    return from(promise).pipe(
      catchError((error) => {
        this.alertService.showApiError(
          `Failed to fetch providers for practice ${practiceId}`
        );
        console.log(error); // TODO: Replace with logging service when available.
        return of([] as Provider[]);
      }),
      map((value) => {
        this._providers = value ?? this._default.providers;
        this.next();
        return value;
      }),
      finalize(() => {
        this.loadingService.stop();
      })
    );
  }

  private subscribe(): void {
    this.practiceService.practiceState$
      .pipe(untilDestroyed(this))
      .subscribe(async (practice: PracticeInfo | null) => {
        if (practice?.type === 'Engagement') {
          this._provider = this._default.provider;
          this._providers = this._default.providers;
          this.next();
          return;
        }
        const { type, id } = practice;
        if (type === 'Analytics') {
          await firstValueFrom(this.readProviders(id));
        }
      });
  }

  private next(): void {
    this.state$.next({
      provider: this._provider,
      providers: this._providers
    });
  }
}
