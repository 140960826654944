import { StatPreference } from './stat-preferences';
import { IPreferences } from '../interfaces/preferences.interface';

export class Preferences implements IPreferences {
  providerId: string;
  highlightedStatName: string;
  statPreferences: StatPreference[] = [];

  static init(data: Partial<IPreferences>): Preferences {
    const preferences = new Preferences();

    preferences.providerId = data?.providerId;
    preferences.highlightedStatName = data?.highlightedStatName;
    preferences.statPreferences = data?.statPreferences ?? [];

    return preferences;
  }

  validate(): boolean {
    return !!(
      this.providerId &&
      this.highlightedStatName &&
      this.statPreferences
    );
  }
}
