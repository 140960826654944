import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Materialize, Modal } from '../../materialize';
import { LocationStrategy } from '@angular/common';

declare const M: Materialize;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, AfterViewInit {
  @Input() modalId: string;
  @Input() startOpened = false;
  @Output() modalPluginSet = new EventEmitter<Modal>();
  @Output() modalClosed = new EventEmitter<boolean>();
  @ViewChild('modal')
  private modal: ElementRef;
  modalPlugin: Modal;

  constructor(private locationStrategy: LocationStrategy) {}

  ngOnInit() {
    this.locationStrategy.onPopState(() => {
      if (this.modalPlugin && this.modalPlugin.isOpen) {
        this.modalPlugin.close();
      }
    });
  }

  ngAfterViewInit(): void {
    this.modalPlugin = M.Modal.init(this.modal.nativeElement, {
      onCloseEnd: () => {
        this.modalClosed.emit(true);
      },
      onOpenStart: () => {
        history.pushState(null, null, location.href);
      }
    });
    if (this.startOpened) {
      this.modalPlugin.open();
    }
    this.modalPluginSet.emit(this.modalPlugin);
  }

  closeModal() {
    history.back();
  }
}
