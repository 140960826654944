<ng-container *ngrxLet="data$ as data">
  <app-spinner *ngIf="data.loading"></app-spinner>
  <div class="dialog-title">
    <div class="two-factor-authentication-dialog-header">
      <mat-icon svgIcon="logo-di"></mat-icon>
      <div class="two-factor-authentication-dialog-title">
        {{ 'LOGIN.TWO_FACTOR_AUTHENTICATION.TITLE' | translate }}
      </div>
    </div>
    <mat-icon svgIcon="close"
              mat-dialog-close></mat-icon>
  </div>
  <mat-dialog-content>
    <p class="email-info">
      {{
        'LOGIN.TWO_FACTOR_AUTHENTICATION.EMAIL_INFO'
          | translate: { email: this.email }
      }}
    </p>
    <p class="expiration-info">
      {{ 'LOGIN.TWO_FACTOR_AUTHENTICATION.EXPIRATION_INFO' | translate }}
    </p>

    <div class="code-container">
      <input #codeInput
             class="code-input"
             maxlength="1"
             type="number"
             *ngFor="let inputControl of codeFormArray.controls; let i = index"
             [formControl]="inputControl"
             (input)="onInput($event, i)"
             (keyup)="onKeyUp($event, i)"
             (paste)="onPaste($event, i)" />
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="btn btn-primary verify-button"
            [disabled]="!twoFactorAuthenticationFormGroup.valid"
            (click)="verifyClicked()">
      {{ 'LOGIN.TWO_FACTOR_AUTHENTICATION.VERIFY' | translate }}
    </button>
    <div class="resend-code">
      {{ 'LOGIN.TWO_FACTOR_AUTHENTICATION.DIDNT_GET_THE_CODE' | translate }}
      <button class="btn-tertiary"
              (click)="resendClicked()"
              [disabled]="!data.isResendButtonEnabled">
        {{ 'LOGIN.TWO_FACTOR_AUTHENTICATION.RESEND' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</ng-container>
