import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportRoutingModule } from './support-routing.module';
import { AppCommonModule } from '../../common/common.module';
import { SupportComponent } from './support.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SupportComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    SupportRoutingModule,
    TranslateModule
  ]
})
export class SupportModule {}
