import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AppCommonModule } from '../../common/common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { StatisticsComponent } from './statistics.component';
import { CircularGaugeComponent } from 'src/app/common/circular-gauge/circular-gauge.component';
import { StatisticsService } from './statistics-service';
import { DatePickerModule } from 'src/app/common/date-picker/date-picker.module';
import { StatisticsSettingsComponent } from './settings/statistics-settings.component';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    StatisticsComponent,
    CircularGaugeComponent,
    StatisticsSettingsComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslateModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    DatePickerModule,
    MatInputModule,
    RouterModule,
    DragDropModule
  ],
  providers: [StatisticsService]
})
export class StatisticsModule {}
