<div class="container">
  <div *ngIf="isLoading"
       class="screen-full-modal">
    <app-spinner class="screen-centered"></app-spinner>
  </div>
  <div class="row">
    <div class="valign-wrapper">
      <div class="col s6">
        <h5>{{ 'MY_SCHEDULE.TITLE' | translate }}</h5>
      </div>
      <div class="col s6 schedule-datepicker-container">
        <div class="date-container">
          <span class="date-text">{{ date | date:'MMMM d, yyyy' }}</span>
          <mat-icon class="calendar-icon"
                    (click)="picker.open()"
                    (keydown)='calendarOnKeydown($event, picker.open)'
                    tabindex="0">calendar_today
          </mat-icon>
          <input [matDatepicker]="picker"
                 [(ngModel)]="date"
                 (ngModelChange)="onDatePickerChange($event)"
                 style="display: none;">
          <mat-datepicker #picker
                          [touchUi]="true"></mat-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="date-picker-container">
      <div class="weekday-list">
        <div *ngIf="!isLoading && providerMetrics !== undefined"
             class="indicators-row">
          <div *ngFor="let day of providerMetrics.daysScheduled"
               class="indicator-wrapper">
            <div [class.today-indicator]="day.isScheduled"></div>
          </div>
        </div>
        <div *ngFor="let day of currentWeekDays"
             class="day-item"
             [class.selected]="day.isSelected"
             (click)="onWeekViewDateChange(day)"
             (keydown)='weekViewDateChangeOnKeydown($event, day)'>
          <div class="weekday">{{ day.shortLabel }}</div>
          <div class="date">{{ day.date }}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isLoading && providerMetrics === undefined">
    <h5>{{ 'MY_SCHEDULE.EMPTY' | translate }}</h5>
  </div>
  <div *ngIf="!isLoading && providerMetrics !== undefined"
       class="row metrics-container">
    <div class="metric-card col s3">
      <div class="metric-content">
        <div class="metric-label">PTS/HRS</div>
        <div class="metric-value">
          {{ providerMetrics.appointmentsCount + '/' + providerMetrics.scheduledMinutes / 60 }}
        </div>
      </div>
    </div>
    <div class="metric-card col s3">
      <div class="metric-content">
        <div class="metric-label">Scheduled</div>
        <div class="metric-value">
          {{ providerMetrics.productionScheduled | currency }}
        </div>
      </div>
    </div>
    <div class="metric-card col s3">
      <div class="metric-content">
        <div class="metric-label">Goal</div>
        <div class="metric-value">
          {{ providerMetrics.productionGoal | currency }}
        </div>
      </div>
    </div>
    <div class="metric-card col s3">
      <div class="metric-content">
        <div class="metric-label">Unscheduled</div>
        <div class="metric-value">
          {{ providerMetrics.unscheduledTxForProvider | currency }}
        </div>
      </div>
    </div>
  </div>
  <div class="row appointments-container"
       *ngIf="!isLoading && selectedProviderId === defaultProviderId">
    <div class="staff-card"
         *ngFor="let provider of allProviderMetrics"
         (click)="onStaffCardClick(provider)"
         (keydown)='staffCardClickOnKeydown($event, provider)'>
      <div class="staff-info col s7">
        <div class="avatar-container">
          <div class="avatar">
            <div class="avatar-placeholder"></div>
          </div>
        </div>
        <div class="details">
          <h3>{{ provider.name }}</h3>
          <p class="role">{{ provider.role }}</p>
        </div>
      </div>
      <div class="metrics col s5">
        <div class="pts-hrs">
          <span class="label">PTS/HRS</span>
          <span
                class="value">{{ provider.appointmentsCount }}/{{ provider.scheduledMinutes / 60 }}</span>
        </div>
        <div class="scheduled">
          <span class="label">Scheduled</span>
          <span
                class="value">{{ provider.productionScheduled | currency }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row appointments-container"
       *ngIf="!isLoading && selectedProviderId !== defaultProviderId">
    <div class="appointment-item"
         *ngFor="let appointment of appointments"
         [ngClass]="{'open-slot': appointment.status === 'open', 'close-slot': appointment.status === 'closed'}">
      <div class="time-slot">
        <div class="time-content">
          <span>{{ formatDate(appointment.time) }}</span>
          <span *ngIf="formatDate(appointment.endTime)"> -
            {{ formatDate(appointment.endTime) }}</span>
        </div>
      </div>
      <div class="appointment-details"
           *ngIf="appointment.status !== 'open' && appointment.status !== 'closed'">
        <div class="avatar-container">
          <div class="avatar">
            <div class="avatar-placeholder"></div>
          </div>
        </div>
        <div class="info">
          <div class="name-row">
            <span class="name">{{ appointment.name }}</span>
            <span class="birthdate"
                  *ngIf="appointment.birthdate">
              <i class="birthday-icon">🎂</i> {{ appointment.birthdate }}
            </span>
          </div>
          <div class="codes">{{ appointment.codes }}</div>
          <div class="status"
               [ngClass]="{'confirmed': appointment.status === 'confirmed', 'not-confirmed': appointment.status === 'not_confirmed'}">
            {{ appointment.status === 'confirmed' ? 'Confirmed' : 'Not confirmed' }}
          </div>
        </div>
      </div>
      <div *ngIf="appointment.status === 'open' || appointment.status === 'closed'"
           [ngClass]="{'open-slot-text': appointment.status === 'open', 'close-slot-text': appointment.status === 'closed'}">
        {{ appointment.name }}
      </div>
    </div>
  </div>
</div>
