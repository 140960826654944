<app-navigation class="app-navigation"
                [unreadTeamChatMessagesCount]="unreadTeamChatMessagesCount$ | async"
                [isTeamChatEnabled]="isTeamChatEnabled"
                (teamChatClicked)="toggleTeamChatWindowVisibility()">
</app-navigation>
<div class="app-content section body no-pad-bot no-pad-top"
     [ngClass]="{ 'team-chat-visible': isTeamChatWindowVisible }">
  <router-outlet></router-outlet>
</div>

<div class="team-chat-window-container"
     #teamChatWindowContainer
     *ngIf="isTeamChatEnabled"
     [ngClass]="{ open: isTeamChatWindowVisible }">
  <iframe #teamChatIframe
          class="team-chat-window"
          [src]="teamChatUrl"></iframe>
</div>
