// tslint:disable:variable-name
export class Patient {
  id: number;
  first_name: string;
  last_name: string;
  image: string;
  mobile_phone: string;
  email: string;
  phone_optout: number;
  email_optout: number;
  email_undeliverable: boolean;
  phone_undeliverable: boolean;
  can_receive_push: boolean;
  can_receive_sms: boolean;
  can_receive_email: boolean;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  timestamp: string;
  unread_messages: number;
  last_message: string;
  guardian_user_id?: number;
  guardian_user_active?: boolean;
  guardian_user_push_active?: boolean;
  user_id: number;
  user_push_active?: boolean;
  date_of_birth?: Date;
  next_visit_date?: Date;
  next_visit_confirmed: boolean;
  guardian?: Patient;

  isReachable(): boolean {
    return (
      this.can_receive_email || this.can_receive_sms || this.can_receive_email
    );
  }

  smsReceiver(): Patient {
    return this.guardian && this.guardian.mobile_phone ? this.guardian : this;
  }

  canReceiveBackupSMS(): boolean {
    const receiver: Patient = this.smsReceiver();
    return receiver.mobile_phone && receiver.phone_optout === 0;
  }

  emailReceiver(): Patient {
    return this.guardian && this.guardian.email ? this.guardian : this;
  }

  /**
   * Get patient full name
   */
  getFullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  getFullAddress(): string {
    return (
      (this.address ? `${this.address}, ` : '') +
      (this.city ? `${this.city}, ` : '') +
      (this.state ? `${this.state}, ` : '') +
      (this.zipcode ? `${this.zipcode}` : '')
    );
  }
}
