import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Patient } from '../../common/patient/patient.model';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private pageTitleSubject: Subject<string> = new Subject();
  private forceLogoutSubject: Subject<boolean> = new Subject();
  private patientInfoSubject: Subject<Patient> = new Subject();

  constructor() {}

  public setPageTitle(title: string) {
    this.pageTitleSubject.next(title);
  }

  public pageTitleObservable(): Observable<string> {
    return this.pageTitleSubject.asObservable();
  }

  public forceLogoutEventObservable(): Observable<boolean> {
    return this.forceLogoutSubject.asObservable();
  }

  public forceLogout() {
    this.forceLogoutSubject.next(true);
  }

  public showPatientInfoObservable(): Observable<Patient> {
    return this.patientInfoSubject.asObservable();
  }

  public showPatientInfo(patient: Patient) {
    this.patientInfoSubject.next(patient);
  }
}
