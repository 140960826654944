import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamChatService {
  private unreadCount$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  getUnreadCount(): Observable<number> {
    return this.unreadCount$.asObservable();
  }

  updateUnreadCount(count: number): void {
    this.unreadCount$.next(count);
  }
}
