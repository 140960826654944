import { Component, Input, OnInit } from '@angular/core';
import { ExtendedMessage } from '../extended-message.model';
import { AlertsService } from '../../../../common/alerts/alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { PatientChatService } from '../../patient-chat.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss']
})
export class MessageItemComponent implements OnInit {
  @Input() message: ExtendedMessage;
  loadingAttachment: boolean;

  constructor(
    private alertsService: AlertsService,
    private translateService: TranslateService,
    private patientChatService: PatientChatService,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.message.unread) {
      this.patientChatService
        .makeMessageSeen(this.message)
        .then(() => {})
        .catch(() => {});
    }
  }

  onDeliveryFailedClicked() {
    this.alertsService.showError(
      this.translateService.instant(
        'PATIENT_CHAT.CONVERSATION.DELIVERY_FAILED'
      ) + this.message.delivery_note
    );
  }

  attachmentExtension() {
    const filename = this.message.incoming_message.attachment_filename;
    if (filename) {
      return filename.substr(filename.lastIndexOf('.'));
    } else {
      return '';
    }
  }

  async viewAttachment(attachmentGuid: string, filename: string) {
    try {
      this.loadingAttachment = true;
      const data = (await this.patientChatService.getAttachment(attachmentGuid))
        .body;
      const url: string = URL.createObjectURL(data);
      this.router.navigate(['view-file'], {
        queryParams: { url, filename }
      } as NavigationExtras);
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.loadingAttachment = false;
    }
  }
}
