import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { SelectOption } from './interfaces/select-option.interface';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  @Input() label: string;
  @Input() options: SelectOption[];
  @Input() defaultText: string;
  @Input() value: string;
  @Input() style: 'form' | 'header' = 'form';
  @Output() valueChange = new EventEmitter<string>();
  isOpen = false;

  constructor(private readonly elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (
      targetElement &&
      !this.elementRef.nativeElement.contains(targetElement)
    ) {
      this.isOpen = false;
    }
  }

  get selectedText(): string {
    return (
      this.options.find((opt) => opt.value === this.value)?.text ||
      this.defaultText
    );
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  toggleOnKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
    const key = event.key;
    if (key !== 'Enter' && key !== ' ') {
      return;
    }
    this.toggle();
  }

  selectValue(option: SelectOption): void {
    this.value = option.value;
    this.valueChange.emit(option.value);
    this.isOpen = false;
  }

  selectValueOnKeyDown(event: KeyboardEvent, option: SelectOption): void {
    event.preventDefault();
    const key = event.key;
    if (key !== 'Enter' && key !== ' ') {
      return;
    }
    this.selectValue(option);
  }
}
