<ng-container *ngIf='online; else offline'>
  <ng-container *ngIf='canUseApp; else cannotUseApp'>
    <ng-container *ngIf='!enableExternalUrl; else showExternalUrl'>
      <!--Class to add a scrollbar-->
      <div class='fullscreen-y-scroll'
           [ngClass]="{ 'main-app': !needLogIn }">
        <ng-container *ngIf='!needLogIn; else showLogin'>
          <!--Main application-->
          <app-main></app-main>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<!--External URL-->
<ng-template #showExternalUrl>
  <app-external-url-viewer [url]='externalUrl'>
  </app-external-url-viewer>
</ng-template>
<!--Login screen-->
<ng-template #showLogin>
  <app-login (logInSuccess)="onLogInSuccess()"></app-login>
</ng-template>
<!--Offline page-->
<ng-template #offline>
  <app-no-connection></app-no-connection>
</ng-template>
<!--Page not found-->
<ng-template #cannotUseApp>
  <app-page-not-found></app-page-not-found>
</ng-template>
<!--Development debug actions-->
<app-dev-debug-actions *ngIf="env.envName === 'local'"></app-dev-debug-actions>
