import { Injectable } from '@angular/core';
import { Materialize } from '../../materialize';
import { ErrorMessageService } from '../error-message/error-message.service';

declare const M: Materialize;

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  constructor(private errorMessageService: ErrorMessageService) {}

  showSuccess(message: string): void {
    M.toast({ html: message, classes: 'success' });
  }

  showError(message: string): void {
    M.toast({ html: message, classes: 'error' });
  }

  showWarning(message: string): void {
    M.toast({ html: message, classes: 'warn' });
  }

  showInfo(message: string): void {
    M.toast({ html: message, classes: 'info' });
  }

  showApiWarning(error): void {
    this.showWarning(this.errorMessageService.getErrorMessage(error));
  }

  showApiError(error): void {
    this.showError(this.errorMessageService.getErrorMessage(error));
  }
}
