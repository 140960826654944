import { Injectable } from '@angular/core';
import { MobiatiHttpService } from '../../../common/mobiati-http/mobiati-http.service';

@Injectable({ providedIn: 'root' })
export class PatientService {
  constructor(private http: MobiatiHttpService) {}

  getFullPatient(patientId: number): Promise<any> {
    return this.http.get(`/staff/patients/${patientId}`);
  }
}
