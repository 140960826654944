<div class='app-switch'
     [class.first]='!showAllBorders'>
  <ng-container *ngIf='!description; else showTitle'></ng-container>
  <div class='flex-container'>
    <p *ngIf='description; else showTitle'
       class='description'>{{ description }}</p>
    <div class='right-container'>
      <div class="switch-div"
           (click)='toggle()'
           (keydown)='toggleOnKeyDown($event)'
           [class.active]='value'>
        <div class="slider-div"></div>
      </div>
      <div class='icon'>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
<ng-template #showTitle>
  <h2 class='title'>{{ title }}</h2>
</ng-template>
