import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../app.config';
import { Practice } from './practice.model';
import { CookieService } from 'ngx-cookie';
import { PhonePipe } from '../pipes/phone/phone.pipe';

@Injectable({ providedIn: 'root' })
export class PracticeService {
  private practice: Practice;
  private practiceGuid: string;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private phonePipe: PhonePipe,
    @Inject(API_URL) private apiUrl: string
  ) {
    this.practiceGuid = this.cookieService.get('practice_guid');
    const storedPractice = JSON.parse(localStorage.getItem('practice'));
    if (storedPractice) {
      console.log('Restored practice from local storage');
      this.practice = storedPractice;
      this.practiceGuid = this.practice.guid;
    }
  }

  async getPracticeData(): Promise<Practice> {
    return this.http
      .get<Practice>(`${this.apiUrl}/${this.practiceGuid}/practice`)
      .toPromise();
  }

  async lazyRefreshPracticeData(): Promise<Practice> {
    if (!this.practice || this.practice.guid !== this.practiceGuid) {
      this.setPractice(await this.getPracticeData());
    } else {
      this.getPracticeData().then((practice) => this.setPractice(practice));
    }

    return Promise.resolve(this.practice);
  }

  /**
   * Loads practice data based on guid if not loaded already.
   */
  async loadPracticeIfNeeded(): Promise<Practice> {
    if (!this.practice || this.practice.guid !== this.practiceGuid) {
      this.setPractice(
        await this.http
          .get<Practice>(`${this.apiUrl}/${this.practiceGuid}/practice`)
          .toPromise()
      );
    }

    return Promise.resolve(this.practice);
  }

  setPractice(practice: Practice) {
    this.practice = practice;
    this.practiceGuid = practice.guid;
    localStorage.setItem('practice', JSON.stringify(this.practice));
  }

  getPractice(): Practice {
    return this.practice;
  }

  setPracticeGuid(guid: string) {
    this.practiceGuid = guid;
  }

  getPracticeGuid(): string {
    return this.practiceGuid;
  }

  clearPracticeInfo() {
    delete this.practice;
    delete this.practiceGuid;
    localStorage.removeItem('practice');
  }
}
