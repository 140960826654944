import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Materialize } from '../../materialize';
import { NativeAppService } from '../../common/native-app-service/native-app.service';
import { environment } from '../../../environments/environment';
import { PracticeService } from '../../common/practice';
import { UserService } from '../../common/user';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { tap } from 'rxjs/operators';
import { TeamChatService } from '../../common/team-chat/team-chat.service';
import { BadgeService } from '../../common/badge/badge.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, Subject } from 'rxjs';
import {
  CommunicationEvent,
  CommunicationEventType,
  LocationChangedData,
  NewMessageData,
  UnreadMessagesCounterUpdateData
} from '@localmed/modento-team-chat-types';
import { DOCUMENT } from '@angular/common';

declare const M: Materialize;

@UntilDestroy()
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  @HostBinding('class.team-chat-open') isTeamChatWindowVisible = false;

  @ViewChild('teamChatIframe')
  set _teamChatIframe(iframe: ElementRef<HTMLIFrameElement> | undefined) {
    this.teamChatIframe = iframe;
    this.teamChatWindowLoaded.next(!!iframe);
  }

  @ViewChild('teamChatWindowContainer') teamChatWindowContainer: ElementRef;

  isTeamChatEnabled = false;
  teamChatUrl: SafeUrl = '';

  unreadTeamChatMessagesCount$: Observable<number>;

  private readonly teamChatWindowLoaded: Subject<boolean> = new Subject<
    boolean
  >();

  private readonly baseTeamChatUrl = `${
    environment.teamChat.appUrl
  }/${this.practiceService.getPracticeGuid()}`;
  private readonly authToken = this.userService.getUserData().access_token;
  private teamChatIframe: ElementRef<HTMLIFrameElement> | undefined;
  private currentIframeUrl = '';

  private newMessageSound = '../../assets/sounds/team-chat-new-message.wav';

  @HostListener('window:message', ['$event'])
  onPostMessage(event): void {
    if (!this.isTeamChatEnabled) {
      return;
    }

    if (event.origin === environment.teamChat.appUrl) {
      switch (event.data.type) {
        case CommunicationEventType.UNREAD_MESSAGES_COUNTER_UPDATE:
          this.teamChatService.updateUnreadCount(
            (event.data as CommunicationEvent<UnreadMessagesCounterUpdateData>)
              .data.counterValue
          );
          break;
        case CommunicationEventType.NEW_MESSAGE:
          this.notifyAboutNewMessage(
            (event.data as CommunicationEvent<NewMessageData>).data
          );
          break;
        case CommunicationEventType.LOCATION_CHANGED:
          this.currentIframeUrl = (event.data as CommunicationEvent<
            LocationChangedData
          >).data.url;
          break;
        case CommunicationEventType.CLOSE_MOBILE_APP:
          this.hideTeamChat();
          break;
      }
    }
  }
  constructor(
    private teamChatService: TeamChatService,
    private readonly nativeService: NativeAppService,
    private readonly practiceService: PracticeService,
    private readonly userService: UserService,
    private readonly domSanitizer: DomSanitizer,
    private readonly badgeService: BadgeService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.badgeService.count$
      .pipe(
        tap((unreadCount: number) =>
          this.nativeService.updateBadgeCounter(unreadCount)
        ),
        untilDestroyed(this)
      )
      .subscribe();

    this.unreadTeamChatMessagesCount$ = this.teamChatService.getUnreadCount();
    this.nativeService.registerForPushNotification();

    this.checkIfTeamChatIsEnabled();
    if (this.isTeamChatEnabled) {
      this.setDefaultTeamChatUrl();
      this.subscribeToAppStateChange();

      if (/iPhone|iPad|iPod/.test(window.navigator.userAgent)) {
        window.visualViewport.addEventListener(
          'resize',
          this.resizeListener.bind(this)
        );
      }
    }
  }

  ngOnDestroy(): void {
    window.visualViewport.removeEventListener(
      'resize',
      this.resizeListener.bind(this)
    );
  }

  hideTeamChat(): void {
    this.isTeamChatWindowVisible = false;
  }

  toggleTeamChatWindowVisibility(): void {
    this.isTeamChatWindowVisible = !this.isTeamChatWindowVisible;
  }

  private setDefaultTeamChatUrl(): void {
    this.teamChatUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      `${this.baseTeamChatUrl}?token=${this.authToken}`
    );
  }

  private checkIfTeamChatIsEnabled(): void {
    this.isTeamChatEnabled =
      this.userService.getTeamMember().role_chat_user &&
      !!this.practiceService.getPractice().has_active_subscription &&
      !!this.practiceService.getPractice().connect_team_chat;
  }

  private notifyAboutNewMessage(newMessage: NewMessageData): void {
    if (
      !this.isTeamChatWindowVisible ||
      !this.isChannelOpen(newMessage.channelId)
    ) {
      this.newMessageSoundAlert();
    }
  }

  private isChannelOpen(channelId: string): boolean {
    return this.currentIframeUrl.endsWith(`channel/${channelId}`);
  }

  private newMessageSoundAlert(): void {
    const audio = new Audio();
    audio.src = this.newMessageSound;

    try {
      audio.load();
      audio.play();
    } catch (error) {}
  }

  private subscribeToAppStateChange(): void {
    combineLatest([
      this.nativeService.focusChangeObservable(),
      this.teamChatWindowLoaded
    ]).subscribe(([isInApp, isIframeLoaded]) => {
      this.isTeamChatEnabled = isInApp;

      if (isInApp && isIframeLoaded) {
        this.teamChatIframe.nativeElement.contentWindow.postMessage(
          { type: 'reloadIfError' },
          environment.teamChat.appUrl
        );

        this.teamChatIframe.nativeElement.contentWindow.postMessage(
          {
            type: 'reloadChannels'
          },
          environment.teamChat.appUrl
        );

        this.teamChatIframe.nativeElement.contentWindow.postMessage(
          {
            type: 'reloadChannel'
          },
          environment.teamChat.appUrl
        );
      }
    });
  }

  private resizeListener(event: Event): void {
    if (!this.isTeamChatWindowVisible) {
      return;
    }

    const viewport = event.target as VisualViewport;

    window.scrollTo(0, 0);
    this.document.body.style.height = `${viewport.height}px`;
    this.teamChatWindowContainer.nativeElement.style.height = `${viewport.height}px`;
  }
}
