import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Patient } from '../patient/patient.model';
import { Practice } from '../practice';

@Injectable()
export class CommunicationService {
  constructor(private translateService: TranslateService) {}

  getDeliverabilitySummary(
    patient: Patient,
    considerMobile: boolean = true
  ): string {
    const warnings: string[] = [];
    const smsReceiver: Patient = patient.smsReceiver();
    const emailReceiver: Patient = patient.emailReceiver();
    if (!smsReceiver.mobile_phone) {
      warnings.push(
        this.translateService.instant('COMMON.CHANNELS.PHONE_MISSING')
      );
    }
    if (smsReceiver.phone_optout) {
      warnings.push(
        this.translateService.instant('COMMON.CHANNELS.SMS_OPTOUT')
      );
    }
    if (smsReceiver.phone_undeliverable) {
      warnings.push(
        this.translateService.instant('COMMON.CHANNELS.PHONE_UNDELIVERABLE')
      );
    }
    if (!emailReceiver.email) {
      warnings.push(
        this.translateService.instant('COMMON.CHANNELS.EMAIL_MISSING')
      );
    }
    if (emailReceiver.email_optout) {
      warnings.push(
        this.translateService.instant('COMMON.CHANNELS.EMAIL_OPTOUT')
      );
    }
    if (emailReceiver.email_undeliverable) {
      warnings.push(
        this.translateService.instant('COMMON.CHANNELS.EMAIL_UNDELIVERABLE')
      );
    }
    if (
      considerMobile &&
      !patient.can_receive_push &&
      (patient.user_id || patient.guardian_user_id)
    ) {
      warnings.push(
        this.translateService.instant('COMMON.CHANNELS.PUSH_NOT_ACTIVE')
      );
    }

    return warnings.join(' ');
  }

  hasDeliverabilityIssues(
    patient: Patient,
    considerMobile: boolean = true
  ): boolean {
    if (!patient) {
      return false;
    }

    return (
      !patient.can_receive_sms ||
      !patient.can_receive_email ||
      (considerMobile &&
        !patient.can_receive_push &&
        (patient.user_id != null || patient.guardian_user_id != null))
    );
  }

  canSendAltSMS(patient: Patient, practice: Practice): boolean {
    return (
      practice.extra_sms_full_operator &&
      practice.extra_sms_full_number &&
      patient &&
      patient.canReceiveBackupSMS()
    );
  }
}
