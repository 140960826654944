import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-existence',
  templateUrl: 'existence.component.html'
})
export class ExistenceComponent implements OnInit {
  @Input() data: any;
  @Output() created = new EventEmitter<any>();

  ngOnInit() {
    this.created.emit(this.data);
  }
}
