import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PracticeService } from '../common/practice';
import { nonEmpty } from '../common/utils/string-utils.helper';
import { VERSION } from '../../environments/version';

let globalPracticeService: PracticeService;

Sentry.init({
  dsn: environment.sentry.DSN,
  environment: environment.envName,
  release: `Forms/${VERSION.version} (${VERSION.hash})`,
  beforeSend(event, hint) {
    // Workaround about cases when rejected promise throws odd, non-descriptive exception
    try {
      /* tslint:disable:no-string-literal only-arrow-functions */
      const isNonErrorException =
        event.exception.values[0].value.startsWith(
          'Non-Error exception captured'
        ) ||
        hint.originalException['message'].startsWith(
          'Non-Error exception captured'
        );
      /* tslint:enable:no-string-literal only-arrow-functions */

      if (isNonErrorException) {
        // We want to ignore those kind of errors
        return null;
      }
    } catch (e) {}

    // add practice guid tag if possible
    const practiceGuid =
      globalPracticeService !== undefined
        ? globalPracticeService.getPracticeGuid()
        : undefined;
    if (nonEmpty(practiceGuid)) {
      if (event.tags === undefined) {
        event.tags = {};
      }
      event.tags.practice_guid = practiceGuid;
    }

    return event;
  }
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private readonly practiceService: PracticeService) {
    globalPracticeService = this.practiceService;
  }

  handleError(error: any) {
    if (environment.sentry.DSN.length > 0) {
      Sentry.captureException(error.originalError || error);
    }
    throw error;
  }
}
