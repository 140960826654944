<form [formGroup]="form">
  <div *ngIf="isSubmitting"
       class="screen-full-modal"
       (click)="(false)">
    <app-spinner class="screen-centered"
                 [white]="true"></app-spinner>
  </div>

  <div class="row mt-3">
    <div class="col s10 offset-s1 m6 offset-m3 center-align">
      <img class="logo"
           src="assets/logo.png" />
      <h3 class="text-primary mt-0 mb-1"><strong>GO</strong></h3>
    </div>
  </div>

  <div class="row">
    <div class="col s10 offset-s1 m6 offset-m3 center-align">
      <p class="text-primary">{{ 'LOGIN.PROMPT' | translate }}</p>
      <div class="input-field">
        <input id="email"
               type="email"
               class=""
               formControlName="email"
               name="email"
               required />
        <label for="email">{{ 'LOGIN.EMAIL' | translate }}</label>
      </div>
      <div class="input-field">
        <input #password
               id="password"
               type="password"
               formControlName="password"
               name="password"
               (keydown)="onPasswordKeyDown($event)"
               required />
        <label for="password">{{ 'LOGIN.PASSWORD' | translate }}</label>
      </div>
      <p class="left-align">
        <label>
          <input type="checkbox"
                 class="filled-in"
                 formControlName="rememberMe"
                 [value]="true" />
          <span
                class="text-primary">{{ 'LOGIN.REMEMBER_ME' | translate }}</span>
        </label>
        <span class="helper-text text-light">{{
        'LOGIN.REMEMBER_ME_NOTE_' + platform | translate
      }}</span>
      </p>
      <button type="button"
              class="btn btn-primary waves-effect waves-primary mt-2"
              (click)="onLogIn(form?.value)">
        {{ 'LOGIN.BUTTON' | translate }}
      </button>
    </div>
  </div>

  <div class="center-align text-primary">
    <p>
      <a class="text-light" (click)="onTermsClicked()">{{
      'LOGIN.TERMS_OF_USE' | translate
    }}</a>
      |
      <a class="text-light" (click)="onPrivacyClicked()">{{
      'LOGIN.PRIVACY_POLICY' | translate
    }}</a>
    </p>
  </div>
</form>
