import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import 'rxjs';
import { API_URL, UNAUTHORIZED, FORBIDDEN } from '../../app.config';
import { PracticeService } from '../practice';
/**
 * WARNING: Do not shorten the import below.
 * @remarks
 * The application will not load properly when this import is shortened.
 */
import { NavigationService } from '../../main/navigation/navigation.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MobiatiHttpService {
  retryCall = false;
  private authToken: string;

  constructor(
    @Inject(API_URL) public apiUrl: string,
    protected readonly http: HttpClient,
    protected readonly practiceService: PracticeService,
    protected readonly navigationService: NavigationService,
    protected readonly router: Router
  ) {}

  async get<T>(url: string, params?: HttpParams): Promise<T> {
    this.retryCall = false;
    const apiUrl = this.resolveUrl(url);

    return this.http
      .get<T>(apiUrl, { headers: this.getGetHeaders(), params })
      .toPromise()
      .catch((error) => {
        return this.handleError(error).then(() => {
          if (this.retryCall) {
            return this.get<T>(url, params);
          } else {
            return this;
          }
        });
      });
  }

  private getGetHeaders(): HttpHeaders {
    // X-Sent-At header is a workaround for on WKWebWebview/iOS/Safari issue where some GET requests get cached and not actually being sent
    // adding something custom to request, like new header value, helps with this issue apparently
    return this.getAuthorizationHeader().set(
      'X-Sent-At',
      new Date().getTime().toString()
    );
  }

  async post<T>(
    url: string,
    body?: any,
    expectBlob: boolean = false
  ): Promise<T> {
    this.retryCall = false;
    const apiUrl = this.resolveUrl(url);
    const opts: any = {
      headers: this.getAuthorizationHeader()
    };
    if (expectBlob) {
      opts.responseType = 'blob';
    }
    return this.http
      .post<T>(apiUrl, body, opts)
      .toPromise()
      .catch((error) => {
        return this.handleError(error).then(() => {
          if (this.retryCall) {
            return this.post<T>(url, body);
          } else {
            return this;
          }
        });
      });
  }

  async put<T>(
    url: string,
    body?: any,
    expectBlob: boolean = false
  ): Promise<T> {
    this.retryCall = false;
    const apiUrl = this.resolveUrl(url);
    const opts: any = {
      headers: this.getAuthorizationHeader()
    };
    if (expectBlob) {
      opts.responseType = 'blob';
    }
    return this.http
      .put<T>(apiUrl, body, opts)
      .toPromise()
      .catch((error) => {
        return this.handleError(error).then(() => {
          if (this.retryCall) {
            return this.post<T>(url, body);
          } else {
            return this;
          }
        });
      });
  }

  async patch<T>(url: string, body?: any): Promise<T> {
    this.retryCall = false;
    const apiUrl = this.resolveUrl(url);
    return this.http
      .patch<T>(apiUrl, body, { headers: this.getAuthorizationHeader() })
      .toPromise()
      .catch((error) => {
        return this.handleError(error).then(() => {
          if (this.retryCall) {
            return this.patch<T>(url, body);
          } else {
            return this;
          }
        });
      });
  }

  async delete<T>(url: string): Promise<T> {
    this.retryCall = false;
    const apiUrl = this.resolveUrl(url);
    return this.http
      .delete<T>(apiUrl, { headers: this.getAuthorizationHeader() })
      .toPromise()
      .catch((error) => {
        return this.handleError(error).then(() => {
          if (this.retryCall) {
            return this.delete<T>(url);
          } else {
            return this;
          }
        });
      });
  }

  getFile(url: string, params?: HttpParams): Promise<HttpResponse<Blob>> {
    this.retryCall = false;
    const apiUrl = this.resolveUrl(url);
    const headers = this.getAuthorizationHeader();
    return this.http
      .get(apiUrl, {
        headers,
        params,
        observe: 'response',
        responseType: 'blob'
      })
      .toPromise();
  }

  setAuthToken(authToken: string): void {
    this.authToken = authToken;
  }

  getAuthToken(): string {
    return this.authToken;
  }

  getHttpParams(params: object): HttpParams {
    if (!params) {
      return new HttpParams();
    }
    const fromString = Object.entries(params)
      .filter(([_, value]) => this.valuesFiltering(value))
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    return new HttpParams({ fromString });
  }

  private valuesFiltering(value: any): boolean {
    return (
      (!Array.isArray(value) && value != null) ||
      (Array.isArray(value) && !!value?.length)
    );
  }

  private getAuthorizationHeader(): HttpHeaders {
    if (this.authToken) {
      return new HttpHeaders().set('Authorization', `Bearer ${this.authToken}`);
    } else {
      return new HttpHeaders();
    }
  }

  private handleError(error: any): any {
    // convert blob error into object by parsing expected JSON
    if (error.error instanceof Blob) {
      return new Promise((_, reject) => {
        const fr = new FileReader();
        const err = error;
        fr.onload = () => {
          err.error = JSON.parse(fr.result.toString());
          reject(err as Error);
        };
        fr.readAsText(error.error);
      });
    }
    if (error && error.status === UNAUTHORIZED) {
      return this.authenticationErrorHandler(error);
    }
    if (error && error.status === FORBIDDEN) {
      return this.authenticationErrorHandler(error);
    }
    return Promise.reject(error as Error) as any;
  }

  private authenticationErrorHandler(error: any): any {
    this.navigationService.forceLogout();
    return Promise.reject(error as Error) as any;
  }

  resolveUrl(url: string): string {
    if (url.startsWith('http')) {
      return url;
    } else {
      return `${this.apiUrl}/${this.getPracticeGuid()}${url}`;
    }
  }

  private getPracticeGuid(): string {
    const practice = this.practiceService.getPractice();
    return practice !== undefined ? practice.guid : undefined;
  }
}
