import { ActionResponse } from '../../../common/mobiati-http/action-response.model';

export interface TwoFactorAuthenticationDialogData {
  email: string;
  username: string;
}

export enum TwoFactorAuthenticationDialogCloseReason {
  VERIFIED = 'verified'
}

export interface TwoFactorAuthenticationViewData {
  loading: boolean;
  isResendButtonEnabled: boolean;
}

export interface VerifyResponse extends ActionResponse {
  access_token: string;
}
