import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  TwoFactorAuthenticationDialogCloseReason,
  TwoFactorAuthenticationDialogData,
  TwoFactorAuthenticationViewData
} from './two-factor-authentication-dialog.types';
import { TwoFactorAuthenticationFacade } from './+state/two-factor-authentication.facade';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '../../../common/user';

@UntilDestroy()
@Component({
  selector: 'app-two-factor-authentication-dialog',
  templateUrl: './two-factor-authentication-dialog.component.html',
  styleUrls: ['./two-factor-authentication-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TwoFactorAuthenticationFacade]
})
export class TwoFactorAuthenticationDialogComponent
  implements OnInit, OnDestroy {
  @ViewChildren('codeInput') codeInputs: QueryList<
    ElementRef<HTMLInputElement>
  >;

  isResendButtonEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  data$: Observable<TwoFactorAuthenticationViewData> = combineLatest({
    loading: this.twoFactorAuthenticationFacade.loading$,
    isResendButtonEnabled: this.isResendButtonEnabled$.asObservable()
  });

  twoFactorAuthenticationFormGroup: FormGroup = new FormGroup({
    code: new FormArray([
      new FormControl(null, Validators.required),
      new FormControl(null, Validators.required),
      new FormControl(null, Validators.required),
      new FormControl(null, Validators.required),
      new FormControl(null, Validators.required),
      new FormControl(null, Validators.required)
    ])
  });

  email: string;

  private codeCharDeleted = false;

  private readonly RESEND_TIMEOUT_IN_SECONDS = 30;
  private timeout: number;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: TwoFactorAuthenticationDialogData,
    private readonly twoFactorAuthenticationFacade: TwoFactorAuthenticationFacade,
    private readonly userService: UserService,
    private readonly matDialogRef: MatDialogRef<
      TwoFactorAuthenticationDialogComponent
    >
  ) {}

  get codeFormArray(): FormArray {
    return this.twoFactorAuthenticationFormGroup.get('code') as FormArray;
  }

  ngOnInit(): void {
    this.setAnonymizedEmail();
    this.subscribeToMFAVerified();
    this.setTimerToEnableResendButton();
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onInput(event: InputEvent, index: number): void {
    if (event.inputType === 'deleteContentBackward') {
      this.codeCharDeleted = true;
      return;
    }

    const value = this.codeInputs.get(index).nativeElement.value;

    if (!value) {
      return;
    }

    if (index + 1 === this.codeInputs.length) {
      return;
    }

    this.codeInputs.get(index + 1).nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent, index: number): void {
    if (this.codeCharDeleted) {
      this.codeCharDeleted = false;
      return;
    }

    const value = this.codeInputs.get(index).nativeElement.value;

    if (event.code === 'Backspace' && !value && index > 0) {
      this.codeInputs.get(index - 1).nativeElement.focus();
    }
  }

  onPaste(event: ClipboardEvent, index: number): void {
    if (index) {
      return;
    }

    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');

    if (pastedText?.length === 6) {
      this.codeFormArray.setValue([...pastedText]);
      this.codeInputs.get(this.codeInputs.length - 1).nativeElement.focus();
    }
  }

  resendClicked(): void {
    this.twoFactorAuthenticationFacade.resend({
      deviceUUID: this.userService.getDeviceUuid(this.data.username)
    });

    this.isResendButtonEnabled$.next(false);
    this.setTimerToEnableResendButton();
  }

  verifyClicked(): void {
    if (!this.twoFactorAuthenticationFormGroup.valid) {
      return;
    }

    this.twoFactorAuthenticationFacade.verify({
      deviceUUID: this.userService.getDeviceUuid(this.data.username),
      code: this.codeFormArray.value.join('')
    });
  }

  private setAnonymizedEmail(): void {
    const atSignIndex = this.data.email.indexOf('@');

    if (atSignIndex < 3) {
      this.email = this.data.email;
      return;
    }

    this.email =
      this.data.email.substring(0, 3) +
      '*'.repeat(atSignIndex - 3) +
      this.data.email.substring(atSignIndex);
  }

  private subscribeToMFAVerified(): void {
    this.twoFactorAuthenticationFacade.verified$
      .pipe(
        tap(() => {
          this.matDialogRef.close(
            TwoFactorAuthenticationDialogCloseReason.VERIFIED
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private setTimerToEnableResendButton(): void {
    this.timeout = setTimeout(() => {
      this.isResendButtonEnabled$.next(true);
    }, this.RESEND_TIMEOUT_IN_SECONDS * 1000);
  }
}
