import { Injectable } from '@angular/core';
import { MobiatiHttpService } from '../../common/mobiati-http/mobiati-http.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { nonEmpty } from '../../common/utils/string-utils.helper';
import { PagedResults } from '../../common/paging/paged-results.model';
import { Conversation } from './conversation.model';
import { Message } from './conversation/message.model';
import { SendMessageModel } from './conversation/send-message/send-message.model';
import { ActionResponse } from '../../common/mobiati-http/action-response.model';
import { ExtendedMessage } from './conversation/extended-message.model';
import { NativeAppService } from '../../common/native-app-service/native-app.service';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PatientChatService {
  conversations: Conversation[] = [];
  totalConversations = 0;
  perPage = undefined;
  unreadOnly: boolean;
  search: string;

  protected loadedData = false;
  // Push notification subscription
  protected pushNotificationSub: Subscription;
  // Has focus event subscription
  protected appFocusChangeSub: Subscription;

  constructor(
    private http: MobiatiHttpService,
    private nativeService: NativeAppService
  ) {
    this.pushNotificationSub = this.nativeService
      .pushNotificationsObservable()
      .subscribe((pushData) => this.handlePushNotification(pushData));
    this.appFocusChangeSub = this.nativeService
      .focusChangeObservable()
      .subscribe((hasFocus) => this.handleFocusChange(hasFocus));
  }

  async loadConversations(): Promise<Conversation[]> {
    const result = await this.getConversations(
      this.search,
      this.unreadOnly,
      this.perPage
    );
    this.totalConversations = result.total;
    this.conversations = result.data.map((conv) =>
      Object.assign(new Conversation(), conv)
    );
    this.loadedData = true;
    return Promise.resolve(this.conversations);
  }

  loadMore(moreCount: number): Promise<Conversation[]> {
    this.perPage = this.conversations.length + moreCount;
    return this.loadConversations();
  }

  private async getConversations(
    search: string,
    unreadOnly: boolean,
    perPage?: number
  ): Promise<PagedResults<Conversation>> {
    let params: HttpParams = new HttpParams().append(
      'unreadConversation',
      unreadOnly ? '1' : '0'
    );
    if (nonEmpty(search)) {
      params = params.append('search', search);
    }
    if (perPage) {
      params = params.append('perPage', String(perPage));
    }
    return this.http.get<PagedResults<Conversation>>(
      '/staff/chat/conversations-v2',
      params
    );
  }

  async getMessages(conversationId: number): Promise<ExtendedMessage[]> {
    const response = await this.http.get<PagedResults<Message>>(
      `/staff/chat/conversation/${conversationId}/messages`,
      this.http.getHttpParams({
        allCommunication: '1'
      })
    );
    return Promise.resolve(this.messagesTransformer(response.data.reverse()));
  }

  getConversationById(id: number): Conversation {
    return this.conversations.find((conv) => conv.id === id);
  }

  sendMessage(message: SendMessageModel): Promise<ActionResponse> {
    return this.http.post<ActionResponse>(
      `/staff/actions/patient-message`,
      message
    );
  }

  /**
   * Transform messages to add some useful fields
   *
   */
  protected messagesTransformer(messages: Message[]): ExtendedMessage[] {
    return messages.reduce(
      (acc, message: ExtendedMessage, index, array: Message[]) => {
        const localMessage = message as ExtendedMessage;
        localMessage.next_in_group = false;
        localMessage.next_on_same_date = false;
        const previous: Message = index === 0 ? undefined : array[index - 1];

        if (previous && Object.keys(previous).length > 0) {
          const previousUser = previous.is_reply
            ? previous.patient
            : previous.staff_user;
          const currentUser = message.is_reply
            ? message.patient
            : message.staff_user;

          const previousUserId = previousUser != null ? previousUser.id : null;
          const currentUserId = currentUser != null ? currentUser.id : null;

          if (previousUserId === currentUserId) {
            localMessage.next_in_group = true;
          }

          const previousDate = new Date(previous.created_at);
          const currentDate = new Date(message.created_at);
          if (
            previousDate.getFullYear() === currentDate.getFullYear() &&
            previousDate.getMonth() === currentDate.getMonth() &&
            previousDate.getDate() === currentDate.getDate()
          ) {
            localMessage.next_on_same_date = true;
          }
        }

        // Check unread message
        const unread =
          message.incoming_message !== null && !message.incoming_message.seen;

        // Determine avatar image
        let image = message.is_reply
          ? message.patient.image
          : message.staff_user != null
          ? message.staff_user.image
          : null;
        image = nonEmpty(image) ? image : '/assets/images/avatar.png';

        message = {
          ...localMessage,
          is_patient: message.is_reply,
          image,
          full_name: message.is_reply
            ? `${message.patient.first_name} ${message.patient.last_name}`
            : message.staff_user != null
            ? message.staff_user.name
            : 'MODENTO',
          unread
        } as ExtendedMessage;

        acc.push(message);

        return acc;
      },
      []
    );
  }

  /**
   * Update incoming message if it was seen
   *
   */
  makeMessageSeen(message: Message): Promise<any> {
    return this.http.post<any>(
      `/staff/chat/conversation/${message.patient_id}/messages/${message.incoming_message.id}/seen`
    );
  }

  private handlePushNotification(pushData: object): void {
    if (this.loadedData) {
      // only refresh; if app didn't finish loading yet, ignore
      this.loadConversations()
        .then(() => {})
        .catch(() => {});
    }
  }

  private handleFocusChange(hasFocus: boolean): void {
    if (hasFocus && this.loadedData) {
      // only refresh; if app didn't finish loading yet, ignore
      this.loadConversations()
        .then(() => {})
        .catch(() => {});
    }
  }

  getAttachment(attachmentGuid: string): Promise<HttpResponse<Blob>> {
    return this.http.getFile(`/form-attachments/${attachmentGuid}`);
  }

  getFullPatient(patientId: number): Promise<any> {
    return this.http.get(`/staff/${patientId}`);
  }
}
