import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AppCommonModule } from '../common/common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../common/authentication/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { TwoFactorAuthenticationDialogModule } from './login/two-factor-authentication-dialog/two-factor-authentication-dialog.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TwoFactorAuthenticationDialogModule
  ],
  exports: [LoginComponent],
  providers: [AuthService]
})
export class LoginModule {}
