import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge.src';
import {
  ChartColor,
  IChartSeriesConfig,
  IDashboardChartConfig,
  IGaugeOptions,
  ISeriesValue,
  SeriesType
} from './circular-guage.model';

// Initialize the solid gauge module
HighchartsMore(Highcharts);
solidGauge(Highcharts);

@Component({
  selector: 'app-circular-gauge',
  template: `<div
    id="gauge-container"
    #chart
    style="width: 100%; height: 100%"
  ></div>`,
  styleUrls: ['./circular-gauge.component.scss']
})
export class CircularGaugeComponent implements AfterViewInit, OnDestroy {
  private _options: IGaugeOptions = {
    arc: {
      startAngle: -150,
      endAngle: 150
    },
    bar: {
      width: 18
    },
    gutter: {
      width: 18,
      color: ChartColor.Background
    },
    tick: {
      length: 22,
      width: 5
    },
    isRounded: true,
    backgroundColor: 'rgb(255, 255, 255)'
  };

  @Input() public set options(options: IGaugeOptions) {
    this._options = { ...this._options, ...options };
  }

  public renderChart(
    chartConfig: IDashboardChartConfig,
    container: ElementRef
  ): Highcharts.Chart {
    const seriesItem = this._getChartSeriesDataValue(
      chartConfig.series[0]?.values[0]
    );

    const tickLength = this._options.tick.length;
    const tickWidth = this._options.tick.width;
    const barWidth = this._options.bar.width;
    const gutterWidth = this._options.gutter.width;
    const gutterColor = this._options.gutter.color;
    const rounded = this._options.isRounded;
    const startAngle = this._options.arc.startAngle;
    const endAngle = this._options.arc.endAngle;
    const backgroundColor = this._options.backgroundColor;

    const chart = Highcharts.chart(container.nativeElement, {
      exporting: { enabled: false },
      credits: { enabled: false },
      chart: {
        type: 'solidgauge',
        margin: [0, 0, 0, 0],
        ignoreHiddenSeries: true,
        backgroundColor,
        height: '75%'
      },
      title: {
        text: chartConfig.title?.text ?? ''
      },
      tooltip: {
        enabled: false
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      pane: {
        size: '90%',
        center: ['50%', '50%'],
        startAngle,
        endAngle,
        background: [
          {
            borderWidth: gutterWidth,
            shape: 'arc',
            outerRadius: '90%',
            innerRadius: '90%',
            borderColor: gutterColor
          }
        ]
      },
      yAxis: {
        min: 0,
        max: this._calculateMax(
          seriesItem.goal,
          Math.max(seriesItem.value, seriesItem.max)
        ),
        lineWidth: 0,
        tickPositions: [seriesItem.goal > 0 ? seriesItem.goal : 0],
        tickPosition: 'inside',
        tickWidth: seriesItem.goal > 0 ? tickWidth : 0,
        tickLength,
        tickColor: ChartColor.Goal,
        minorTickColor: ChartColor.Goal,
        minorTickLength: 0,
        minTickInterval: 500,
        ...{ zIndex: 99 },
        labels: {
          enabled: false
        }
      },
      plotOptions: {
        solidgauge: {
          borderColor: chartConfig.series[0].color,
          borderWidth: barWidth,
          dataLabels: {
            enabled: false
          },
          linecap: rounded ? 'round' : 'square',
          stickyTracking: false,
          rounded,
          ...(chartConfig.plot?.events?.click && { cursor: 'pointer' }),
          events: {
            click(event): void {
              chartConfig.plot.events.click({
                point: { name: event.point.name },
                series: { name: event.point.series.name }
              });
            }
          }
        }
      },
      series: this._getChartSeries(chartConfig)
    });

    return chart;
  }

  public isEmptyChartConfig(chartConfig: IDashboardChartConfig): boolean {
    return !chartConfig;
  }

  private _getChartSeries(
    chartConfig: IDashboardChartConfig
  ): Highcharts.SeriesOptionsType[] {
    return chartConfig.series.map((chartSeries) => {
      return {
        type: CircularGaugeComponent.mapSeriesType(chartSeries.type),
        name: chartSeries.name,
        visible: chartSeries.isVisible === true,
        data: this._getChartSeriesConfig(chartSeries)
      };
    });
  }

  private _getChartSeriesConfig(chartSeries: IChartSeriesConfig): object[] {
    return chartSeries.values.map((seriesItem) => {
      const dataPoint =
        typeof seriesItem === 'object' ? seriesItem : { value: seriesItem };
      return {
        y: dataPoint.value,
        color: chartSeries.color,
        radius: '90%',
        innerRadius: '90%',
        // name: chartData.categories[index],
        ...dataPoint
      };
    });
  }

  private _getChartSeriesDataValue(
    seriesItem: number | ISeriesValue
  ): ISeriesValue {
    return typeof seriesItem === 'object' ? seriesItem : { value: seriesItem };
  }

  private _calculateMax(goal: number, value: number): number {
    return Math.max(goal ?? 0, value);
  }

  @Input() public set chartConfig(config: IDashboardChartConfig) {
    this._chartConfig = config;
    if (this._initialized) {
      this._chart = this._destroyChart();
      if (
        this._chartConfig &&
        !this.isEmptyChartConfig(this._chartConfig) &&
        this._chartContainer
      ) {
        this._chart = this.renderChart(this._chartConfig, this._chartContainer);
      }
    }
  }

  reflow() {
    if (this._chart) {
      this._chart.reflow();
    }
  }

  public get noChart(): boolean {
    return !this._chartConfig && !this._chart;
  }
  public get noData(): boolean {
    return !!this._chartConfig && this.isEmptyChartConfig(this._chartConfig);
  }

  public get noDataText(): string | null {
    return this._noDataText;
  }

  protected _chart: Highcharts.Chart | null = null;
  protected _isolatedGroup: string | null = null;

  @ViewChild('chart', { read: ElementRef })
  private readonly _chartContainer: ElementRef;
  private _chartConfig: IDashboardChartConfig;
  private _initialized: boolean = false;
  private readonly _noDataText: string | null = null;
  private resizeObserver: ResizeObserver;
  private resizeTimeout: any;

  public static mapSeriesType(
    seriesType: SeriesType
  ): (
    | Highcharts.SeriesColumnOptions
    | Highcharts.SeriesLineOptions
    | Highcharts.SeriesPieOptions
    | Highcharts.SeriesSolidgaugeOptions
    | Highcharts.SeriesSplineOptions
  )['type'] {
    switch (seriesType) {
      case SeriesType.Column:
        return 'column';
      case SeriesType.Line:
        return 'line';
      case SeriesType.Slice:
        return 'pie';
      case SeriesType.Gauge:
        return 'solidgauge';
      case SeriesType.Spline:
        return 'spline';
    }
  }

  public ngAfterViewInit(): void {
    this._initialized = true;
    if (this._chartConfig && this._chartContainer) {
      this._chart = this.renderChart(this._chartConfig, this._chartContainer);
    }
    this.setupResizeObserver();
  }

  private setupResizeObserver() {
    this.resizeObserver = new ResizeObserver((entries) => {
      // Debounce resize events
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        if (this._chart) {
          this._chart.reflow();
        }
      }, 100); // Delay of 100ms
    });

    if (this._chartContainer?.nativeElement) {
      this.resizeObserver.observe(this._chartContainer.nativeElement);
    }
  }

  public ngOnDestroy(): void {
    this._destroyChart();
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
  }

  public highlightSeries(seriesNames: string[]): void {
    if (!this._chart) {
      return;
    }
    this._chart.series.forEach((series) => {
      if (
        !seriesNames ||
        seriesNames.length === 0 ||
        seriesNames.includes(series.name)
      ) {
        if (series.options.opacity !== 1.0) {
          series.update({ opacity: 1.0 } as Highcharts.SeriesOptionsType);
        }
      } else if (series.options.opacity !== 0.5) {
        series.update({ opacity: 0.5 } as Highcharts.SeriesOptionsType);
      }
    });
  }

  private _destroyChart(): Highcharts.Chart | null {
    if (this._chart) {
      this._chart.destroy();
      this._chart = null;
    }
    return this._chart;
  }
}
