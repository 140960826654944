// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  envName: 'dev',
  production: false,
  serverUrl: 'https://dev.modento.io',
  apiUrl: 'https://dev.modento.io/api/v1',
  dashboardUrl: 'https://dev-office.modento.io',
  clientId: 83,
  clientSecret: 'pFZNnyRNmN2RF2Jnhlu97cSK5EwkePQ7mEL7xia8',
  sentry: {
    DSN: 'https://99e49759c0904f5d957ead04b8696d04@sentry.io/5173251'
  },
  teamChat: {
    appUrl: 'https://dev-chat.modento.io'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
