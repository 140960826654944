<div class='container app-statistics-settings'>
  <header class='header'>
    <h2 class='title'>{{ 'STATISTICS.SETTINGS.TITLE' | translate }}</h2>
    <p class='confirm'
       type='button'
       (click)='confirm()'
       (keydown)='confirmOnKeyDown($event)'>
      {{ 'STATISTICS.SETTINGS.CONFIRM' | translate }}
    </p>
  </header>
  <div class='options'>
    <div>
      <app-provider-selection></app-provider-selection>
    </div>
    <div>
      <app-select [label]='"STATISTICS.SETTINGS.HIGHLIGHT.LABEL" | translate'
                  [defaultText]='"STATISTICS.SETTINGS.HIGHLIGHT.DEFAULT" | translate'
                  [options]='highlightOptions'
                  [(value)]='highlightedStat'></app-select>
    </div>
  </div>
  <div class='stats'
       cdkDropList
       (cdkDropListDropped)='drop($event)'>
    <app-switch *ngFor='let stat of stats; let i = index'
                [title]='stat.description'
                [description]=''
                [showAllBorders]='i !== 0'
                [(value)]='stat.visible'
                cdkDrag>
      <mat-icon svgIcon="menu"
                cdkDragHandle>
      </mat-icon>
    </app-switch>
  </div>
</div>
