<div id="{{ modalId }}"
     class="modal"
     #modal>
  <div class="modal-content">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <a
      (click)="onCancel()"
      class="modal-close waves-effect waves-green btn-flat"
      >{{
        cancelMessage ? cancelMessage : ('COMMON.MODAL.CANCEL' | translate)
      }}</a>
    <a
      (click)="onConfirm()"
      class="modal-close waves-effect waves-green btn-flat"
      >{{
        confirmMessage ? confirmMessage : ('COMMON.MODAL.YES' | translate)
      }}</a>
  </div>
</div>
