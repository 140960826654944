export function nonEmpty(value: any): boolean {
  return value !== undefined && value !== null && value !== '';
}

export function isEmpty(value: any): boolean {
  return value === undefined || value === null || value === '';
}

export function withGuessedType(value: any): any {
  if (value === '') {
    return value;
  }
  const asNumber = Number(value);
  if (!isNaN(asNumber)) {
    return asNumber;
  }
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return value;
}

export function hasMultiLines(element) {
  const style = window.getComputedStyle(element, null);
  const lineHeight = parseInt(style.getPropertyValue('line-height'), 10);
  return element.offsetHeight > lineHeight;
}

export function getLinesCount(element) {
  const style = window.getComputedStyle(element, null);
  const fontSize = parseInt(style.getPropertyValue('font-size'), 10);
  const boxSizing = style.getPropertyValue('box-sizing');
  let height = parseInt(style.getPropertyValue('height'), 10);
  let lineHeight = parseInt(style.getPropertyValue('line-height'), 10);

  if (isNaN(lineHeight)) {
    lineHeight = fontSize * 1.2;
  }

  if (boxSizing === 'border-box') {
    const paddingTop = parseInt(style.getPropertyValue('padding-top'), 10);
    const paddingBottom = parseInt(
      style.getPropertyValue('padding-bottom'),
      10
    );
    const borderTop = parseInt(style.getPropertyValue('border-top-width'), 10);
    const borderBottom = parseInt(
      style.getPropertyValue('border-bottom-width'),
      10
    );
    height = height - paddingTop - paddingBottom - borderTop - borderBottom;
  }

  return Math.ceil(height / lineHeight);
}

export function nl2br(text: string) {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

export function br2nl(text: string) {
  return text.replace(/<br>/g, '\n');
}
