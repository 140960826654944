<app-navigation class="app-navigation"
                [unreadTeamChatMessagesCount]="unreadTeamChatMessagesCount$ | async"
                [isTeamChatEnabled]="isTeamChatEnabled"
                (teamChatClicked)="toggleTeamChatWindowVisibility()">
</app-navigation>
<div class="app-content section body no-pad-bot no-pad-top"
     [ngClass]="{ 'team-chat-visible': isTeamChatWindowVisible }">
  <div *ngIf="isLoading"
       class="screen-full-modal">
    <app-spinner class="screen-centered"></app-spinner>
  </div>
  <div [class.invisible]='isLoading'>
    <router-outlet></router-outlet>
  </div>
</div>
<div class="team-chat-window-container"
     #teamChatWindowContainer
     *ngIf="isTeamChatEnabled"
     [ngClass]="{ open: isTeamChatWindowVisible }">
  <iframe #teamChatIframe
          title='team-chat-window'
          class="team-chat-window"
          [src]="teamChatUrl"></iframe>
</div>
