import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VERSION } from '../../../environments/version';
import { nonEmpty } from '../utils/string-utils.helper';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private modentoUserAgents = [
    'Modento Connect Android',
    'Modento Connect iOS'
  ];

  constructor(private deviceDetectorService: DeviceDetectorService) {}

  public isInApp(): boolean {
    const userAgent = this.deviceDetectorService.userAgent;
    let isApp = false;
    this.modentoUserAgents.forEach((modentoUA) => {
      if (userAgent.includes(modentoUA)) {
        isApp = true;
      }
    });
    return isApp;
  }

  public getModentoHostAppInfo(): string {
    const userAgent = this.deviceDetectorService.userAgent;
    let hostAppInfo = '';
    this.modentoUserAgents.forEach((modentoUA) => {
      if (userAgent.includes(modentoUA)) {
        const match = userAgent.match(
          new RegExp(modentoUA + '/.* ?\\(?.*\\)? ?')
        );
        if (match) {
          hostAppInfo = match.toString().trim();
        } else {
          hostAppInfo = modentoUA;
        }
      }
    });
    return hostAppInfo;
  }

  public getFullModentoClientInfo(withDeviceInfo: boolean = true): string {
    const version = VERSION.version + ' (' + VERSION.hash + ')';
    const hostAppInfo: string = this.getModentoHostAppInfo();
    return (
      'Connect/' +
      version +
      (nonEmpty(hostAppInfo) ? ` ${hostAppInfo.trim()}` : '') +
      (withDeviceInfo ? '@' + this.getDeviceInfo() : '')
    );
  }

  public isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  public isiOS(): boolean {
    return this.getPlatform() === 'ios';
  }

  public isAndroid(): boolean {
    return this.getPlatform() === 'android';
  }

  public getDeviceName(): string {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    return deviceInfo.device + ' ' + deviceInfo.os;
  }

  public getDeviceVersion(): string {
    return this.deviceDetectorService.getDeviceInfo().os_version;
  }

  public getDeviceInfo() {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    return `${deviceInfo.os} ${deviceInfo.os_version} ${deviceInfo.browser} ${deviceInfo.browser_version}`.replace(
      /Unknown/g,
      ''
    );
  }

  public getModentoHostAppVersionInt(): number {
    const versionMatch = this.getModentoHostAppInfo().match(/\([0-9]*\)/);
    const versionStr = versionMatch != null ? versionMatch.toString() : null;
    if (versionStr != null) {
      const version: number = Number(versionStr.replace(/[\(\)]/g, ''));
      if (!isNaN(version)) {
        return version;
      }
    }
    // fallback to 1
    return 1;
  }

  public getPlatform() {
    const userAgent = this.deviceDetectorService.userAgent;
    if (userAgent.includes('Modento Connect Android')) {
      return 'android';
    } else if (userAgent.includes('Modento Connect iOS')) {
      return 'ios';
    } else {
      return 'web';
    }
  }
}
