import {
  CallState,
  ErrorState,
  LoadingState
} from '../mobiati-http/call-state.types';

export const isLoading = (callState: CallState): boolean =>
  callState === LoadingState.LOADING ||
  (typeof callState !== 'object' && callState > 0);

export const isLoaded = (callState: CallState): boolean =>
  callState === LoadingState.LOADED || callState === 0;

export const isInit = (callState: CallState): boolean =>
  callState === LoadingState.INIT;

export const errors = (callState: CallState): ErrorState | null =>
  typeof callState !== 'object' ? null : callState;

export const increaseLoadingState = (callState: CallState): CallState => {
  if (typeof callState === 'number') {
    return callState++;
  }

  return 1;
};

export const decreaseLoadingState = (callState: CallState): CallState => {
  if (typeof callState === 'number') {
    return callState--;
  }

  return 0;
};
