<label>{{ 'PATIENT_CHAT.CONVERSATION.CHANNEL' | translate }}</label>
<div class="form-group">
  <select [(ngModel)]="channel"
          (ngModelChange)="onChannelChange()">
    <option value="push"
            *ngIf="allowMobile && patient?.can_receive_push">
      {{ 'PATIENT_CHAT.CONVERSATION.CHANNEL_PUSH' | translate }}
    </option>
    <option value="sms"
            *ngIf="patient?.can_receive_sms">
      {{ 'PATIENT_CHAT.CONVERSATION.CHANNEL_SMS' | translate }}
    </option>
    <option value="sms_alt"
            *ngIf="canSendAltSMS">
      {{ 'PATIENT_CHAT.CONVERSATION.CHANNEL_SMS_ALT' | translate }}
    </option>
    <option value="email"
            *ngIf="patient?.can_receive_email">
      {{ 'PATIENT_CHAT.CONVERSATION.CHANNEL_EMAIL' | translate }}
    </option>
  </select>
</div>
