<div class="container">
  <div *ngIf="isLoading"
       class="screen-full-modal"
       (click)="(false)">
    <app-spinner class="screen-centered"></app-spinner>
  </div>

  <div class="row">
    <div class="valign-wrapper">
      <div class="col s10">
        <h5>{{ 'PATIENT_CHAT.TITLE' | translate }}</h5>
      </div>
      <div class="col s2">
        <a class="secondary-content search" (click)="toggleSearch()"
          ><h5>
          <mat-icon class="mat-icon-36" svgIcon="search"></mat-icon>
        </h5></a>
      </div>
    </div>
  </div>
  <div class="row"
       [hidden]="!searchActive">
    <div class="input-field col s12 search">
      <input id="search"
             type="search"
             autofocus
             autocapitalize="words"
             #searchConversation />
      <label for="search">{{ 'PATIENT_CHAT.SEARCH' | translate }}</label>
      <mat-icon *ngIf="
          patientChatService.search && patientChatService.search.length > 0
        "
                class="clear-input"
                (click)="onClearSearch()"
                svgIcon="close"></mat-icon>
    </div>
  </div>
  <ul class="collection">
    <li class="collection-item avatar"
        *ngFor="let conversation of patientChatService.conversations"
        (click)="onConversationSelected(conversation)">
      <img src="{{
          conversation.image ? conversation.image : 'assets/images/avatar.png'
        }}"
           alt="{{ conversation.first_name }} {{ conversation.last_name }}"
           class="circle" />
      <span class="title">{{ conversation.first_name }}
        {{ conversation.last_name }}</span>
      <p>
        {{ conversation.last_message }}<br />
        {{ conversation.timestamp }}
      </p>
      <a
        *ngIf="conversation.unread_messages > 0"
        class="secondary-content unread-count"
      >
        <strong>{{ conversation.unread_messages }}</strong>
      </a>
    </li>
  </ul>
  <p *ngIf="!isLoading && patientChatService.conversations.length === 0"
     class="center-align">
    {{ 'PATIENT_CHAT.EMPTY' | translate }}
  </p>
  <button *ngIf="
      !isLoading &&
      patientChatService.conversations.length <
        patientChatService.totalConversations
    "
          class="btn btn-small center-align"
          (click)="loadMore()">
    {{ 'PATIENT_CHAT.LOAD_MORE' | translate }}
  </button>
</div>
