import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProviderService } from '../services/provider/provider.service';
import { SelectOption } from '../select/interfaces/select-option.interface';
import { Subscription } from 'rxjs';
import { PreferencesService } from '../services/preferences/preferences.service';

@Component({
  selector: 'app-provider-selection',
  templateUrl: './provider-selection.component.html'
})
export class ProviderSelectionComponent implements OnInit, OnDestroy {
  @Input() style: 'form' | 'header' = 'form';
  providerOptions: SelectOption[] = [];
  private _provider = 'default';
  private readonly _subscriptions: Subscription[] = [];

  constructor(
    private readonly providerService: ProviderService,
    private readonly preferencesService: PreferencesService
  ) {}

  ngOnInit(): void {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  get provider(): string {
    return this._provider;
  }

  set provider(value: string) {
    this._provider = value;
    this.providerService.selectProvider(value);
  }

  private readProviders(): Subscription {
    return this.providerService.providerState$.subscribe((state) => {
      const { provider, providers } = state;
      this._provider = provider.id;
      this.providerOptions = providers.map((provider) => ({
        value: provider.id,
        text: provider.displayName
      }));
    });
  }

  private readPreferences(): Subscription {
    return this.preferencesService.preferencesState$.subscribe(
      (preferences) => {
        if (!preferences?.validate()) {
          return;
        }
        const { providerId } = preferences;
        if (providerId !== 'default' && this._provider === 'default') {
          this.provider = providerId;
        }
      }
    );
  }

  private subscribe(): void {
    this._subscriptions.push(this.readProviders(), this.readPreferences());
  }

  private unsubscribe(): void {
    this._subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
