import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { AppCommonModule } from '../../common/common.module';
import { PatientService } from './patient-info/patient.service';
import { TranslateModule } from '@ngx-translate/core';
import { ViewFileComponent } from './view-file/view-file.component';
import { SafePipeModule } from 'safe-pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [PatientInfoComponent, ViewFileComponent],
  exports: [PatientInfoComponent, ViewFileComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslateModule,
    SafePipeModule,
    MatButtonModule,
    MatIconModule
  ],
  providers: [PatientService]
})
export class SharedModule {}
