import { Component, OnInit } from '@angular/core';
import { DeviceService } from '../../common/device-service/device.service';
import { NativeAppService } from '../../common/native-app-service/native-app.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../common/user';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  version: string;
  year = new Date().getFullYear();

  constructor(
    private readonly deviceService: DeviceService,
    private readonly nativeService: NativeAppService,
    private readonly cookieService: CookieService,
    private readonly userService: UserService
  ) {}

  ngOnInit() {
    this.version = this.deviceService.getFullModentoClientInfo(false);
  }

  onTermsClicked() {
    this.nativeService.openExternalUrlOnNewTab(
      'https://www.dentalintel.com/terms'
    );
  }

  onPrivacyClicked() {
    this.nativeService.openExternalUrlOnNewTab(
      'https://www.dentalintel.com/terms/privacy'
    );
  }

  mailTo(email: string) {
    this.nativeService.mailTo(email);
  }

  callTo(email: string) {
    this.nativeService.call(email);
  }

  onCheckUpdates() {
    if (!this.cookieService.get('currentUser')) {
      const now = new Date();
      const oneMinute = 60 * 1000;
      this.userService.setUserData(this.userService.getUserData(), true, {
        expires: new Date(now.getTime() + oneMinute),
        secure: environment.production,
        storeUnencoded: false
      });
    }
    location.reload();
  }
}
