<div *ngIf="isLoading"
     class="screen-full-modal"
     (click)="(false)">
  <app-spinner [white]="isSpinnerWhite"
               class="screen-centered"></app-spinner>
</div>

<nav>
  <div class="nav-wrapper">
    <img *ngIf="topLevel"
         src="assets/logo.png"
         class="brand-logo" />
    <a *ngIf="topLevel" data-target="mobile-demo" class="sidenav-trigger" (click)="noop()">
      <mat-icon svgIcon="menu"></mat-icon>
    </a>
    <span *ngIf="!topLevel"
          class="brand-logo">{{ pageTitle }}</span>
    <a *ngIf="!topLevel" class="sidenav-back" (click)="goBack()"
      ><mat-icon svgIcon="keyboard_arrow_left"></mat-icon>
    </a>
    <a
      *ngIf="contextAction"
      class="dropdown-trigger sidenav-context right"
      href="#"
      data-target="context-action-dropdown"
      ><mat-icon svgIcon="more_vert"></mat-icon>
    </a>
    <ul class="right hide-on-med-and-down">
      <li>
        <a routerLink="notifications" class="sidenav-close"
          ><mat-icon
            class="nav__badge"
            [matBadge]="unreadNotificationCount$ | async"
            matBadgePosition="below after"
            svgIcon="notifications"
          >
          </mat-icon>
          <span>{{ 'NAV.NOTIFICATIONS' | translate }}</span>
        </a>
      </li>
      <li *ngIf="isTeamChatEnabled">
        <a href="#" class="sidenav-close" (click)="raiseTeamChatClicked($event)"
        ><mat-icon
          class="nav__badge"
          [matBadge]="unreadTeamChatMessagesCount"
          [matBadgeHidden]="!unreadTeamChatMessagesCount"
          matBadgePosition="below after"
          svgIcon="teamChat"
        >
        </mat-icon>
          <span>{{ 'NAV.TEAM_CHAT' | translate }}</span>
        </a>
      </li>
      <li>
        <a
          routerLink="patient-chat"
          (click)="onPatientChatSelected()"
          class="sidenav-close"
        >
          <mat-icon svgIcon="chat"></mat-icon>
          {{ 'NAV.PATIENT_CHAT' | translate }}</a>
      </li>
      <li>
        <a routerLink="schedule" class="sidenav-close"
          ><mat-icon svgIcon="event"></mat-icon>
          {{ 'NAV.SCHEDULE' | translate }}</a>
      </li>
      <li>
        <a (click)="goToDashboard()" class="sidenav-close"
          ><mat-icon svgIcon="laptop"></mat-icon>
          {{ 'NAV.DASHBOARD' | translate }}</a>
      </li>
      <li>
        <a routerLink="support" class="sidenav-close"
          ><mat-icon svgIcon="contact_support"></mat-icon>
          {{ 'NAV.SUPPORT' | translate }}</a>
      </li>
      <!--<li><a routerLink="settings" class="sidenav-close"><i class="material-icons left">settings</i>Settings</a></li>-->
      <li>
        <a (click)="logout()" class="sidenav-close">{{
          'NAV.LOGOUT' | translate
        }}</a>
      </li>
    </ul>
    <a
      *ngIf="!contextAction"
      class="notification__btn"
      routerLink="notifications"
      ><button mat-icon-button>
        <mat-icon
          class="btn__badge"
          [matBadge]="unreadNotificationCount$ | async"
          matBadgePosition="above after"
          svgIcon="notifications"
          ></mat-icon
        >
      </button>
    </a>
  </div>
</nav>

<div class="sidenav"
     id="mobile-demo">
  <div class="sidenav__header halign-wrapper">
    <div class="sidenav__profile flex-column"
         [class.sidenav__profile--practices-displayed]="isPracticeSwitcherVisible$.value"
         [class.flex-center]="!isPracticeSwitcherVisible$.value">
      <div *ngIf="isPracticeSwitcherVisible$.value"
           class="profile__group-practices">
        <app-group-practices-select (practiceUpdate)="onPracticeUpdate()">
        </app-group-practices-select>
      </div>
      <div class="sidenav__profile-details flex-center flex-column">
        <div class="flex-center">
          <div class="sidenav__avatar round-image">
            <img class="round-image"
                 src="{{ userImage ? userImage : 'assets/images/avatar.png' }}" />
          </div>
        </div>
        <h6 class="profile__name">{{ userName }}</h6>
      </div>
    </div>
  </div>

  <ul class="sidenav__links">
    <li>
      <a routerLink="notifications" class="sidenav-close"
        ><mat-icon class="sidenav__nav-icon" svgIcon="notifications"></mat-icon>

        <span
          class="sidenav__badge"
          [matBadge]="unreadNotificationCount$ | async"
          matBadgePosition="below after"
          >{{ 'NAV.NOTIFICATIONS' | translate }}</span
        ></a>
    </li>
    <li *ngIf="isTeamChatEnabled">
      <a href="#" class="sidenav-close" (click)="raiseTeamChatClicked($event)"
      ><mat-icon class="sidenav__nav-icon" svgIcon="teamChat"></mat-icon>

        <span
          class="sidenav__badge"
          [matBadge]="unreadTeamChatMessagesCount"
          [matBadgeHidden]="!unreadTeamChatMessagesCount"
          matBadgePosition="below after"
        >{{ 'NAV.TEAM_CHAT' | translate }}</span
        ></a>
    </li>
    <li>
      <a
        routerLink="patient-chat"
        (click)="onPatientChatSelected()"
        class="sidenav-close"
      >
        <mat-icon class="sidenav__nav-icon" svgIcon="chat"></mat-icon>

        {{ 'NAV.PATIENT_CHAT' | translate }}</a>
    </li>
    <li></li>
    <li>
      <a routerLink="schedule" class="sidenav-close">
        <mat-icon class="sidenav__nav-icon" svgIcon="event"></mat-icon>
        {{ 'NAV.SCHEDULE' | translate }}</a>
    </li>
    <li>
      <a (click)="goToDashboard()" class="sidenav-close">
        <mat-icon class="sidenav__nav-icon" svgIcon="laptop"></mat-icon>
        {{ 'NAV.DASHBOARD' | translate }}</a>
    </li>
    <li>
      <a
        routerLink="notification-settings"
        class="sidenav-close"
      >
        <mat-icon class="sidenav__nav-icon" svgIcon="notification_settings"></mat-icon>
        {{ 'NAV.NOTIFICATION_SETTINGS' | translate }}</a>
    </li>
    <li>
      <a routerLink="support" class="sidenav-close">
        <mat-icon
          class="sidenav__nav-icon"
          svgIcon="contact_support"
        ></mat-icon>
        {{ 'NAV.SUPPORT' | translate }}</a>
    </li>
    <!--<li><a routerLink="settings" class="sidenav-close"><i class="material-icons left">settings</i>Settings</a></li>-->
    <li>
      <a (click)="logout()" class="sidenav-close mt-4">{{
        'NAV.LOGOUT' | translate
      }}</a>
    </li>
  </ul>
</div>

<!-- Patient info modal -->
<app-modal (modalPluginSet)="patientInfoModal = $event">
  <app-patient-info *ngIf="patientInfoModal ? patientInfoModal.isOpen : false"
                    [patient]="infoPatient"></app-patient-info>
</app-modal>
