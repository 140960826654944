<form [formGroup]="form">
  <mat-form-field class="group-practices__form-field"
                  appearance="fill">
    <mat-select formControlName="practice"
                disableOptionCentering="true"
                panelClass="mat-select__panel--blue mat-select__panel--256"
                class="group-practices__select">
      <mat-select-trigger class="flex-center">
        <span class="group-practices__icon-trigger">
          <mat-icon class="mat-icon-20"
                    svgIcon="practice"></mat-icon>
        </span>
        <div class="group-practices__trigger-label flex-center flex-column">
          <div class="trigger-label__top-line helpers--text-dots">
            {{ getFirstLineOfPracticeName(getSelectedPractice()?.name, 15) }}
          </div>
          <div class="trigger-label__bottom-line helpers--text-dots">
            {{ getSecondLineOfPracticeName(getSelectedPractice()?.name, 15) }}
          </div>
        </div>

        <span class="empty-placeholder"></span>
        <span class="group-practices__select-arrow">
          <mat-icon svgIcon="keyboard_arrow_down"></mat-icon>
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let groupPractice of userService.getUser()?.dental_group"
                  [value]="groupPractice?.guid">
        <div class="group-practices__option"
             [class.group-practices__option--selected]="
            getSelectedPractice()?.guid === groupPractice?.guid
          ">
          <span *ngIf="getSelectedPractice()?.guid === groupPractice?.guid"
                class="group-practices__selected-icon-indicator">
            <mat-icon class="mat-icon-20"
                      svgIcon="practice"></mat-icon>
          </span>
          <div class="group-practices__option-label flex-column flex-center">
            <div class="trigger-label__top-line helpers--text-dots">
              {{ getFirstLineOfPracticeName(groupPractice.name, 20) }}
            </div>
            <div class="trigger-label__bottom-line helpers--text-dots">
              {{ getSecondLineOfPracticeName(groupPractice.name, 20) }}
            </div>
          </div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
