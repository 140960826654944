import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PatientChatRoutingModule } from './patient-chat-routing.module';
import { PatientChatComponent } from './patient-chat.component';
import { AppCommonModule } from '../../common/common.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConversationComponent } from './conversation/conversation.component';
import { SendMessageComponent } from './conversation/send-message/send-message.component';
import { ChannelSelectComponent } from './conversation/send-message/channel-select/channel-select.component';
import { MessageItemComponent } from './conversation/message-item/message-item.component';
import { DeliveryChannelPipe } from './conversation/send-message/delivery-channel.pipe';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    PatientChatComponent,
    ConversationComponent,
    SendMessageComponent,
    ChannelSelectComponent,
    MessageItemComponent,
    DeliveryChannelPipe
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    TranslateModule,
    PatientChatRoutingModule,
    MatIconModule,
    SharedModule
  ],
  providers: [DeliveryChannelPipe]
})
export class PatientChatModule {}
