import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AppIconRegistryService {
  private path = 'assets/icons/';
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  registerIcons(): void {
    this.matIconRegistry
      .addSvgIcon(
        'chat',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}chat/baseline.svg`
        )
      )
      .addSvgIcon(
        'event',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}event/baseline.svg`
        )
      )
      .addSvgIcon(
        'event',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}event/baseline.svg`
        )
      )
      .addSvgIcon(
        'laptop',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}laptop/baseline.svg`
        )
      )
      .addSvgIcon(
        'contact_support',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}contact_support/baseline.svg`
        )
      )
      .addSvgIcon(
        'notifications',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}notifications/baseline.svg`
        )
      )
      .addSvgIcon(
        'menu',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}menu/baseline.svg`
        )
      )
      .addSvgIcon(
        'more_vert',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}more_vert/baseline.svg`
        )
      )
      .addSvgIcon(
        'keyboard_arrow_left',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}keyboard_arrow_left/baseline.svg`
        )
      )
      .addSvgIcon(
        'keyboard_arrow_down',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}keyboard_arrow_down/baseline.svg`
        )
      )
      .addSvgIcon(
        'search',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}search/baseline.svg`
        )
      )
      .addSvgIcon(
        'date_range',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}date_range/baseline.svg`
        )
      )
      .addSvgIcon(
        'send',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}send/baseline.svg`
        )
      )
      .addSvgIcon(
        'help',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}help/baseline.svg`
        )
      )
      .addSvgIcon(
        'email',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}email/baseline.svg`
        )
      )
      .addSvgIcon(
        'call',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}call/baseline.svg`
        )
      )
      .addSvgIcon(
        'attachment',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}attachment/baseline.svg`
        )
      )
      .addSvgIcon(
        'perm_contact_calendar',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}perm_contact_calendar/baseline.svg`
        )
      )
      .addSvgIcon(
        'check_circle_outline',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}check_circle_outline/baseline.svg`
        )
      )
      .addSvgIcon(
        'help_outline',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}help_outline/baseline.svg`
        )
      )
      .addSvgIcon(
        'fiber_manual_record',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}fiber_manual_record/baseline.svg`
        )
      )
      .addSvgIcon(
        'warning',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}warning/baseline.svg`
        )
      )
      .addSvgIcon(
        'close',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}close/baseline.svg`
        )
      )
      .addSvgIcon(
        'practice',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}hospital.svg`
        )
      )
      .addSvgIcon(
        'logo-di',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}logo-di.svg`
        )
      )
      .addSvgIcon(
        'teamChat',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}team_chat/icon.svg`
        )
      )
      .addSvgIcon(
        'notification_settings',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${this.path}notification_settings/icon.svg`
        )
      );
  }
}
