<div class="container">
  <div *ngIf="isLoading"
       class="screen-full-modal"
       (click)="(false)">
    <app-spinner class="screen-centered"></app-spinner>
  </div>

  <div class="messages-container"
       #messagesContainer>
    <app-message-item *ngFor="let message of messages"
                      [message]="message"></app-message-item>
    <p *ngIf="!isLoading && (!messages || messages.length === 0)"
       class="center-align">
      {{ 'PATIENT_CHAT.CONVERSATION.EMPTY' | translate }}
    </p>
  </div>
  <div class="send-message-container">
    <app-send-message [conversation]="conversation"
                      [autoDeliveryChannel]="autoDeliveryChannel"
                      (messageSent)="onMessageSent($event)"></app-send-message>
  </div>
</div>

<!-- Context action dropdown Structure -->
<ul id="context-action-dropdown"
    class="dropdown-content">
  <li *ngIf="
      conversation?.mobile_phone ||
      (conversation?.guardian && conversation?.guardian?.mobile_phone)
    ">
    <a (click)="callPatient()"
      >
      <mat-icon svgIcon="call"></mat-icon>
      {{ 'PATIENT_CHAT.CONVERSATION.CONTEXT.CALL' | translate }}</a>
  </li>
  <li *ngIf="
      conversation?.email ||
      (conversation?.guardian && conversation?.guardian?.email)
    ">
    <a (click)="emailPatient()"
      >
      <mat-icon svgIcon="email"></mat-icon>
      {{ 'PATIENT_CHAT.CONVERSATION.CONTEXT.EMAIL' | translate }}</a>
  </li>
  <li>
    <a (click)="showPatientInfo()"
      ><mat-icon svgIcon="perm_contact_calendar"></mat-icon>
      {{ 'PATIENT_CHAT.CONVERSATION.CONTEXT.INFO' | translate }}</a>
  </li>
</ul>
