import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Patient } from '../../../../../common/patient/patient.model';
import { Materialize } from '../../../../../materialize';
import { applyDropdownFixForMobile } from '../../../../../common/utils/materialize-utils.helper';
import { PracticeService } from '../../../../../common/practice';
import { CommunicationService } from '../../../../../common/comm-service/comm.service';

declare const M: Materialize;

@Component({
  selector: 'app-channel-select',
  templateUrl: './channel-select.component.html',
  styleUrls: ['./channel-select.component.scss']
})
export class ChannelSelectComponent implements OnInit, OnChanges {
  @Input() patient: Patient;
  @Input() autoDeliveryChannel: string;
  @Input() allowMobile = true;
  @Output() channelChanged = new EventEmitter<string>();
  @Output() preferTextOverEmailChanged = new EventEmitter<boolean>();
  channel = '';
  canSendAltSMS = false;

  constructor(
    private translateService: TranslateService,
    private practiceService: PracticeService,
    private commService: CommunicationService
  ) {}

  onChannelChange(): void {
    this.channelChanged.emit(this.channel);
  }

  ngOnInit(): void {
    this.canSendAltSMS = this.commService.canSendAltSMS(
      this.patient,
      this.practiceService.getPractice()
    );
    if (!!this.autoDeliveryChannel) {
      this.channel = this.autoDeliveryChannel;
      this.initSelect();
      this.onChannelChange();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.autoDeliveryChannel && !!this.autoDeliveryChannel) {
      this.channel = this.autoDeliveryChannel;
      this.initSelect();
      this.onChannelChange();
    }
  }

  initSelect(): void {
    const elems = document.querySelectorAll('select');
    const instances = M.FormSelect.init(elems, {
      dropdownOptions: {
        coverTrigger: false,
        onOpenStart: () => {
          applyDropdownFixForMobile();
        }
      }
    });
  }
}
