import { Injectable } from '@angular/core';
import { AnalyticsHttpService } from '../../common/mobiati-http/analytics-http.service';
import { PracticeService } from '../../common/practice';
import { IStatDetailed } from './statistics.model';

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  constructor(
    private readonly analyticsHttp: AnalyticsHttpService,
    private readonly practiceService: PracticeService
  ) {}

  public async loadStat(
    statType: string,
    dateStart: string,
    interval: number,
    userId: string
  ): Promise<IStatDetailed> {
    return await this.analyticsHttp.get<IStatDetailed>(
      `MobileApp/MyStats?type=${statType}&practiceId=${this.practiceService.getAnalyticsPracticeGuid()}&dateStart=${dateStart}&interval=${interval}&userid=${userId}`
    );
  }
}
