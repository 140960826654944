<div *ngIf="isLoading"
     class="screen-full-modal">
  <app-spinner [white]="isSpinnerWhite"
               class="screen-centered"></app-spinner>
</div>
<header class='header'>
  <ng-container *ngIf='topLevel; else displayBackArrow'>
    <mat-icon data-target='mobile-demo'
              class='icon menu sidenav-trigger'
              svgIcon='menu'
              (click)='noop()'
              (keydown)='noopOnKeydown($event)'>
    </mat-icon>
  </ng-container>
  <ng-template #displayBackArrow>
    <mat-icon class='icon arrow-left'
              svgIcon="keyboard_arrow_left"
              (click)='goBack()'
              (keydown)='goBackOnKeydown($event)'>
    </mat-icon>
  </ng-template>
  <app-provider-selection *ngIf='showProviderSelection'
                          [style]='"header"'></app-provider-selection>
  <a *ngIf='contextAction; else showNotifications'
     title='Context Action'
     class="dropdown-trigger sidenav-context right context-action"
     href="#"
     data-target="context-action-dropdown">
    <mat-icon class='context-action-icon'
              svgIcon="more_vert">
    </mat-icon>
  </a>
</header>
<div class="sidenav"
     id="mobile-demo">
  <div class="sidenav__header halign-wrapper">
    <div class="sidenav__profile flex-column"
         [class.sidenav__profile--practices-displayed]="isPracticeSwitcherVisible$.value"
         [class.flex-center]="!isPracticeSwitcherVisible$.value">
      <div *ngIf="isPracticeSwitcherVisible$.value"
           class="profile__group-practices">
        <app-group-practices-select (practiceUpdate)="onPracticeUpdate()">
        </app-group-practices-select>
      </div>
      <div class="sidenav__profile-details flex-center flex-column">
        <div class="flex-center">
          <div class="sidenav__avatar round-image">
            <img class="round-image"
                 src="{{ userImage ? userImage : 'assets/images/avatar.png' }}"
                 alt="avatar" />
          </div>
        </div>
        <div class="profile-container">
          <h6 class='first-name'>{{ userName.split(" ")[0] }}</h6>
          <h6 class='last-name'>{{ userName.split(" ").slice(1).join(" ") }}
          </h6>
        </div>
      </div>
    </div>
  </div>
  <ul class="sidenav__links">
    <ng-container
                  *ngTemplateOutlet="navItem; context: { link: 'notifications', label: 'NAV.NOTIFICATIONS', badge: unreadNotificationCount$ | async }">
    </ng-container>
    <li *ngIf="isTeamChatEnabled">
      <a href="#" class="sidenav-close" (click)="raiseTeamChatClicked($event)">
        <span class="sidenav__badge"
              [matBadge]="unreadTeamChatMessagesCount"
              [matBadgeHidden]="!unreadTeamChatMessagesCount"
              matBadgePosition="below after">
          {{ 'NAV.TEAM_CHAT' | translate }}
        </span>
        <mat-icon class="arrow-icon" svgIcon="right_arrow"></mat-icon>
      </a>
    </li>
    <ng-container
                  *ngTemplateOutlet="navItem; context: { link: 'patient-chat', label: 'NAV.PATIENT_CHAT', click: onPatientChatSelected }">
    </ng-container>
    <ng-container
                  *ngTemplateOutlet="navItem; context: { link: 'my-schedule', label: 'NAV.MY_SCHEDULE' }">
    </ng-container>
    <ng-container
                  *ngTemplateOutlet="navItem; context: { link: 'stats', label: 'NAV.STATISTICS' }">
    </ng-container>
    <li>
      <a (click)="goToDashboard()" class="sidenav-close">
      {{ 'NAV.DASHBOARD' | translate }}
      <mat-icon class="arrow-icon"
                svgIcon="right_arrow"></mat-icon>
      </a>
    </li>
    <ng-container
                  *ngTemplateOutlet="navItem; context: { link: 'schedule', label: 'NAV.SCHEDULE' }">
    </ng-container>
    <ng-container
                  *ngTemplateOutlet="navItem; context: { link: 'notification-settings', label: 'NAV.NOTIFICATION_SETTINGS' }">
    </ng-container>
    <ng-container
                  *ngTemplateOutlet="navItem; context: { link: 'support', label: 'NAV.SUPPORT' }">
    </ng-container>
  </ul>
  <div class="sidenav__bottom-tabs">
    <div class="bottom-tab"
         (click)="logout()"
         (keydown)='logoutOnKeyDown($event)'>
      <mat-icon svgIcon="logout"></mat-icon>
      <span class="bottom-tab-label">Logout</span>
    </div>
  </div>
</div>
<!-- Patient info modal -->
<app-modal (modalPluginSet)="patientInfoModal = $event">
  <app-patient-info *ngIf="patientInfoModal ? patientInfoModal.isOpen : false"
                    [patient]="infoPatient">
  </app-patient-info>
</app-modal>
<!-- Navigation item template -->
<ng-template #navItem
             let-link="link"
             let-label="label"
             let-badge="badge"
             let-click="click">
  <li>
    <a [routerLink]="link" class="sidenav-close" (click)="click ? click($event) : null">
      <span *ngIf="badge"
            [matBadge]="badge"
            [matBadgeHidden]='!badge'
            matBadgePosition="below after"
            class="sidenav__badge">
        {{ label | translate }}
      </span>
      <span *ngIf="!badge">{{ label | translate }}</span>
      <mat-icon class="arrow-icon" svgIcon="right_arrow"></mat-icon>
    </a>
  </li>
</ng-template>
<!--Notifications bell icon-->
<ng-template #showNotifications>
  <mat-icon [matBadge]="unreadNotificationCount$ | async"
            matBadgePosition="above after"
            [routerLink]='"/notifications"'
            class='icon notifications'
            aria-label='notifications'
            svgIcon="notifications">
  </mat-icon>
</ng-template>
