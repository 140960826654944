import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCommonModule } from './common/common.module';
import { PracticeService } from './common/practice';
import { MobiatiHttpService } from './common/mobiati-http/mobiati-http.service';
import { environment } from '../environments/environment';
import { VERSION } from '../environments/version';
import { API_URL, CLIENT_ID, CLIENT_SECRET, SERVER_URL } from './app.config';
import { DeviceService } from './common/device-service/device.service';
import { ErrorMessageService } from './common/error-message/error-message.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { ModentoClientHeaderInterceptor } from './common/interceptors/modento-client-header-interceptor.service';
import { AcceptLanguageHeaderInterceptor } from './common/interceptors/accept-language-header-interceptor.service';
import { SentryModule } from './sentry/sentry.module';
import { CookieModule } from 'ngx-cookie';
import { EntryComponent } from './entry/entry.component';
import { LoginModule } from './login/login.module';
import { MainModule } from './main/main.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { DevDebugActionsComponent } from './common/dev-debug/dev-debug-actions.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?v=' + VERSION.hash
  );
}

@NgModule({
  declarations: [AppComponent, EntryComponent, DevDebugActionsComponent],
  imports: [
    TranslateModule,
    AppCommonModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatMenuModule,
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SentryModule,
    LoginModule,
    MainModule,
    BrowserAnimationsModule,
    HammerModule
  ],
  providers: [
    { provide: SERVER_URL, useValue: environment.serverUrl },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: CLIENT_ID, useValue: environment.clientId },
    { provide: CLIENT_SECRET, useValue: environment.clientSecret },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ModentoClientHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageHeaderInterceptor,
      multi: true
    },
    PracticeService,
    MobiatiHttpService,
    DeviceService,
    ErrorMessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
