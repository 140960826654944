import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: []
})
export class TermsComponent implements OnInit {
  constructor(
    private navigationService: NavigationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.navigationService.setPageTitle(
      this.translateService.instant('SUPPORT.TERMS_OF_USE')
    );
  }
}
