import { Component, OnInit } from '@angular/core';
import { PracticeService } from '../common/practice';
import { UserService } from '../common/user';
import { NativeAppService } from '../common/native-app-service/native-app.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {
  needLogIn: boolean;
  canUseApp: boolean;
  externalUrl: string;
  enableExternalUrl: boolean;
  online: boolean;
  env = environment;

  constructor(
    private readonly practiceService: PracticeService,
    private readonly userService: UserService,
    private readonly nativeAppService: NativeAppService
  ) {}

  ngOnInit(): void {
    this.nativeAppService.state$.subscribe(({ url, enabled }) => {
      this.externalUrl = url;
      this.enableExternalUrl = enabled;
    });

    this.canUseApp = this.nativeAppService.isInApp() || !environment.production;
    this.checkLogInStatus();

    // subscribe to online/offline status
    this.online = navigator.onLine;
    window.addEventListener('online', () => {
      this.online = navigator.onLine;
    });

    window.addEventListener('offline', () => {
      this.online = navigator.onLine;
    });
  }

  onLogInSuccess(): void {
    this.checkLogInStatus();
  }

  private checkLogInStatus(): void {
    this.needLogIn =
      !this.userService.isLoggedIn() ||
      (!this.practiceService.getPracticeGuid() &&
        !this.practiceService.getAnalyticsPracticeGuid());

    // change app theme depending on if we are in app or in login phase
    if (this.needLogIn) {
      window.parent.document.body.classList.add('blue-theme');
    } else {
      window.parent.document.body.classList.remove('blue-theme');
    }

    if (!this.needLogIn) {
      this.refreshData().then();
    }
  }

  private async refreshData(): Promise<any> {
    if (
      this.practiceService.getPracticeGuid() &&
      this.practiceService.getPracticeGuid() !== null
    ) {
      await this.practiceService.lazyRefreshPracticeData();
      await this.userService.loadUser();
      await this.userService.updateAppVersion();
    }
  }
}
