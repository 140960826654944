import { ModuleWithProviders, NgModule } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AlertsService } from './alerts/alerts.service';
import { ExistenceComponent } from './existence/existence.component';
import { ModalComponent } from './modal/modal.component';
import { SafePipeModule } from 'safe-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ErrorMessageService } from './error-message/error-message.service';
import { ModentoClientHeaderInterceptor } from './interceptors/modento-client-header-interceptor.service';
import { FormsModule } from '@angular/forms';
import { AcceptLanguageHeaderInterceptor } from './interceptors/accept-language-header-interceptor.service';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { PhonePipe } from './pipes/phone/phone.pipe';
import { CookieService } from 'ngx-cookie';
import { PracticeService } from './practice';
import { UserService } from './user';
import { NativeAppService } from './native-app-service/native-app.service';
import { ExternalUrlViewerComponent } from './external-url-viewer/external-url-viewer.component';
import { WwwUrlPipe } from './pipes/wwwUrl/wwwUrl.pipe';
import { NoConnectionComponent } from './no-connection/no-connection.component';
import { CommunicationService } from './comm-service/comm.service';
import { WindowRef } from './utils/window-utils.helper';
import { UserNotificationsService } from './user-notifications';
import { SelectComponent } from './select/select.component';
import { MatIconModule } from '@angular/material/icon';
import { SwitchComponent } from './switch/switch.component';
import { LegacyBadgeComponent } from './legacy-badge/legacy-badge.component';
import { ProviderSelectionComponent } from './provider-selection/provider-selection.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    SpinnerComponent,
    ExistenceComponent,
    ModalComponent,
    ConfirmModalComponent,
    ExternalUrlViewerComponent,
    PhonePipe,
    WwwUrlPipe,
    NoConnectionComponent,
    SelectComponent,
    SwitchComponent,
    LegacyBadgeComponent,
    ProviderSelectionComponent
  ],
  imports: [
    SafePipeModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    MatIconModule
  ],
  exports: [
    SpinnerComponent,
    ExistenceComponent,
    ModalComponent,
    ConfirmModalComponent,
    ExternalUrlViewerComponent,
    PageNotFoundComponent,
    NoConnectionComponent,
    PhonePipe,
    SelectComponent,
    SwitchComponent,
    LegacyBadgeComponent,
    ProviderSelectionComponent,
    DragDropModule
  ],
  providers: []
})
export class AppCommonModule {
  static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [
        UserNotificationsService,
        UserService,
        CookieService,
        DeviceDetectorService,
        AlertsService,
        ErrorMessageService,
        ModentoClientHeaderInterceptor,
        AcceptLanguageHeaderInterceptor,
        PhonePipe,
        PracticeService,
        NativeAppService,
        CommunicationService,
        WindowRef
      ]
    };
  }
}
