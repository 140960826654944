import { Injectable, NgZone } from '@angular/core';
import { DeviceService } from '../device-service/device.service';
import { NativeEventModel } from './native-event.model';
import { UserService } from '../user';
import { Observable, Subject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

declare global {
  interface Window {
    handleNativeEvent: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NativeAppService {
  constructor(
    private deviceService: DeviceService,
    private userService: UserService,
    private ngZone: NgZone
  ) {
    window.handleNativeEvent = window.handleNativeEvent || {};
    window.handleNativeEvent = (params: NativeEventModel) => {
      this.ngZone.run(() => {
        this.nativeEventHandler(params);
      });
    };
  }

  private pushNotificationSubject = new Subject<object>();
  private appFocusChangeSubject = new Subject<boolean>();

  private static callHookURL(action: string): void {
    // hook that will be catched by custom web view in the app
    window.location.replace('/__NATIVE__/' + action);
  }

  nativeEventHandler(params: NativeEventModel): any {
    // !!! please leave below line exactly as is, as Android client checks console for this exact line to confirm frontend app got the event
    console.log('handleNativeEvent');
    console.log(params);
    switch (params.event) {
      case 'push-token':
        return this.handlePushToken(params);
      case 'push-arrived':
        return this.handlePushArrived(params);
      case 'app-resumed':
        return this.appFocusChangeSubject.next(true);
      case 'app-paused':
        return this.appFocusChangeSubject.next(false);
      case 'app-check-lock':
        return this.handleCheckLock(params);
      case 'app-before-reload':
        return this.handleBeforeReload(params);
    }
  }

  registerForPushNotification(): void {
    console.log('registerForPushNotification');
    if (this.deviceService.isInApp()) {
      NativeAppService.callHookURL('register-push');
    }
  }

  openExternalUrl(url: string): void {
    console.log('openExternalUrl');
    if (this.deviceService.isInApp()) {
      NativeAppService.callHookURL(
        'external-url?url=' + encodeURIComponent(url)
      );
    } else {
      window.open(url, '_blank');
    }
  }

  updateBadgeCounter(count: number): void {
    if (this.deviceService.isiOS()) {
      NativeAppService.callHookURL(`set-badge?value=${count}`);
    }
  }

  private handlePushToken(params: NativeEventModel): void {
    this.userService
      .registerDeviceForPush(params.value)
      .then(() => {})
      .catch(() => {});
  }

  private handlePushArrived(params: NativeEventModel): void {
    this.pushNotificationSubject.next(params.value);
  }

  private handleCheckLock(params: NativeEventModel): void {
    if (this.userService.isLoggedIn()) {
      NativeAppService.callHookURL('lock-app');
    }
  }

  private handleBeforeReload(params: NativeEventModel): void {
    // clear everything and show app default background; app will reload soon
    window.document.body.innerHTML = '';
  }

  pushNotificationsObservable(): Observable<object> {
    return this.pushNotificationSubject.pipe(tap((data) => console.log(data)));
  }

  focusChangeObservable(): Observable<boolean> {
    return this.appFocusChangeSubject.asObservable().pipe(shareReplay(1));
  }

  isInApp(): boolean {
    return this.deviceService.isInApp();
  }

  mailTo(email: string): void {
    this.openExternalUrl(`mailto:${email}`);
  }

  call(phone: string): void {
    this.openExternalUrl(`tel:${phone}`);
  }
}
