import { Component, Input, OnInit } from '@angular/core';
import { PatientService } from './patient.service';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { Patient } from '../../../common/patient/patient.model';
import { NativeAppService } from '../../../common/native-app-service/native-app.service';
import { Router } from '@angular/router';
import { switchMap, take, tap } from 'rxjs/operators';
import { ModalComponent } from '../../../common/modal/modal.component';
import { PatientChatService } from '../../patient-chat/patient-chat.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss']
})
export class PatientInfoComponent implements OnInit {
  @Input() patient: Patient;
  isLoading: boolean;

  constructor(
    private patientService: PatientService,
    private alertService: AlertsService,
    private nativeService: NativeAppService,
    private patientChatService: PatientChatService,
    private router: Router,
    private modal: ModalComponent
  ) {}

  async ngOnInit(): Promise<any> {
    try {
      this.isLoading = true;
      const respPatient = await this.patientService.getFullPatient(
        this.patient.id
      );
      this.patient = Object.assign(new Patient(), respPatient);
    } catch (e) {
      this.alertService.showApiError(e);
    } finally {
      this.isLoading = false;
    }
  }

  callPatient(): void {
    const phone = this.patient.mobile_phone
      ? this.patient.mobile_phone
      : this.patient.guardian.mobile_phone;
    this.nativeService.call(phone);
  }

  emailPatient(): void {
    const email = this.patient.email
      ? this.patient.email
      : this.patient.guardian.email;
    this.nativeService.mailTo(email);
  }

  onStartConversationClick({ id, first_name }: Patient): void {
    this.patientChatService.search = first_name;

    from(this.patientChatService.loadConversations())
      .pipe(
        switchMap(() => this.router.navigate(['patient-chat', id])),
        tap((data) => this.modal.modalPlugin.close()),
        tap(() => (this.patientChatService.search = '')),
        take(1)
      )
      .subscribe();
  }
}
