import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SendMessageModel } from './send-message.model';
import { Conversation } from '../../conversation.model';
import { PatientChatService } from '../../patient-chat.service';
import { AlertsService } from '../../../../common/alerts/alerts.service';
import { ActionResponse } from '../../../../common/mobiati-http/action-response.model';
import { TranslateService } from '@ngx-translate/core';
import { Modal } from '../../../../materialize';
import { CommunicationService } from '../../../../common/comm-service/comm.service';
import { CommLogsChannelsEnum } from '../../../../common/comm-log/comm-logs-channels.enum';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {
  @Input() conversation: Conversation;
  @Input() autoDeliveryChannel: string;
  @Output() messageSent = new EventEmitter<ActionResponse>();
  message: string;
  deliveryChannel = '';
  isSending: boolean;
  deliveryChannelModal: Modal;
  canReceiveCommunication: boolean;

  constructor(
    private patientChatService: PatientChatService,
    private alertsService: AlertsService,
    private translateService: TranslateService,
    public commService: CommunicationService
  ) {}

  ngOnInit(): void {
    this.canReceiveCommunication = this.getCanReceiveCommunication();
  }

  async sendMessage(): Promise<any> {
    if (this.isSending || !this.canReceiveCommunication) {
      return;
    }

    const message: SendMessageModel = {
      patient_id: this.conversation.id,
      text: this.message,
      title: 'Personal message for ' + this.conversation.first_name,
      private: false,
      two_way: true,
      channels:
        this.deliveryChannel === CommLogsChannelsEnum.SMS_ALT
          ? [CommLogsChannelsEnum.SMS]
          : [this.deliveryChannel],
      use_backup_sms: this.deliveryChannel === CommLogsChannelsEnum.SMS_ALT
    } as SendMessageModel;

    try {
      this.isSending = true;
      const response = await this.patientChatService.sendMessage(message);
      this.message = null;
      this.messageSent.emit(response);
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isSending = false;
    }
  }

  showAutoChannelInfo(): void {
    this.alertsService.showInfo(
      this.translateService.instant(
        'PATIENT_CHAT.CONVERSATION.AUTO_DELIVERY_NOTE'
      )
    );
  }

  getCanReceiveCommunication(): boolean {
    return (
      this.conversation.can_receive_push ||
      this.conversation.can_receive_sms ||
      this.conversation.can_receive_email
    );
  }
}
