export function nonEmpty(value: any): boolean {
  return value !== undefined && value !== null && value !== '';
}

export function isEmpty(value: any): boolean {
  return value === undefined || value === null || value === '';
}

export function withGuessedType(value: any): any {
  if (value === '') {
    return value;
  }
  const asNumber = Number(value);
  if (!isNaN(asNumber)) {
    return asNumber;
  }
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return value;
}

export function hasMultiLines(element) {
  const style = window.getComputedStyle(element, null);
  const lineHeight = parseInt(style.getPropertyValue('line-height'), 10);
  return element.offsetHeight > lineHeight;
}

export function getLinesCount(element) {
  const style = window.getComputedStyle(element, null);
  const fontSize = parseInt(style.getPropertyValue('font-size'), 10);
  const boxSizing = style.getPropertyValue('box-sizing');
  let height = parseInt(style.getPropertyValue('height'), 10);
  let lineHeight = parseInt(style.getPropertyValue('line-height'), 10);

  if (isNaN(lineHeight)) {
    lineHeight = fontSize * 1.2;
  }

  if (boxSizing === 'border-box') {
    const paddingTop = parseInt(style.getPropertyValue('padding-top'), 10);
    const paddingBottom = parseInt(
      style.getPropertyValue('padding-bottom'),
      10
    );
    const borderTop = parseInt(style.getPropertyValue('border-top-width'), 10);
    const borderBottom = parseInt(
      style.getPropertyValue('border-bottom-width'),
      10
    );
    height = height - paddingTop - paddingBottom - borderTop - borderBottom;
  }

  return Math.ceil(height / lineHeight);
}

export function nl2br(text: string) {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

export function br2nl(text: string) {
  return text.replace(/<br>/g, '\n');
}

export function getCurrentWeekDates(inputDate) {
  const currentDate = new Date(inputDate);
  const currentDay = currentDate.getUTCDay();
  const diffToSunday = currentDay === 0 ? 0 : -currentDay;
  const sunday = new Date(currentDate);
  sunday.setDate(currentDate.getDate() + diffToSunday);

  const daysOfWeek = [
    { label: 'Sunday', shortLabel: 'SU' },
    { label: 'Monday', shortLabel: 'MO' },
    { label: 'Tuesday', shortLabel: 'TU' },
    { label: 'Wednesday', shortLabel: 'WE' },
    { label: 'Thursday', shortLabel: 'TH' },
    { label: 'Friday', shortLabel: 'FR' },
    { label: 'Saturday', shortLabel: 'SA' }
  ];

  return daysOfWeek.map((day, index) => {
    const dayDate = new Date(sunday);
    dayDate.setDate(sunday.getDate() + index);

    return {
      label: day.label,
      shortLabel: day.shortLabel,
      date: dayDate.getDate(),
      isSelected: dayDate.toDateString() === currentDate.toDateString(),
      isToday: dayDate.toDateString() === currentDate.toDateString(),
      formattedDate: toDateStr(dayDate)
    };
  });
}

export function toDateStr(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
