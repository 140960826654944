import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AlertsService } from '../../common/alerts/alerts.service';
import { AuthService } from '../../common/authentication/auth.service';
import { UserService } from '../../common/user';
import { PracticeService } from '../../common/practice';
import {
  AnalyticsUserPractice,
  UserData
} from '../../common/user/user-data.model';
import { NativeAppService } from '../../common/native-app-service/native-app.service';
import { DeviceService } from '../../common/device-service/device.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from, Subject } from 'rxjs';
import { TwoFactorAuthenticationDialogService } from './two-factor-authentication-dialog/two-factor-authentication-dialog.service';
import { TwoFactorAuthenticationDialogCloseReason } from './two-factor-authentication-dialog/two-factor-authentication-dialog.types';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Router } from '@angular/router';
import { LoginResponse } from '../../common/authentication/login-response.model';
import { PracticeList } from '../../common/practice/practice.model';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('password') passwordRef: ElementRef;

  @Output() logInSuccess = new EventEmitter<boolean>();

  isSubmitting: boolean;
  platform: string;

  form: FormGroup = this.fb.group({
    email: '',
    password: '',
    rememberMe: true
  });

  public firebaseAuth = getAuth();

  fixForAutofillOnIos$ = this.form.valueChanges
    .pipe(
      // wait for 500ms to be sure the autofill is finished with the password field
      debounceTime(500),
      tap((changes) => {
        // manually fetch the value from nativeElement
        const passVal = this.passwordRef.nativeElement.value;
        // if the value saved in the form is different from the input, manually update it
        if (passVal !== changes.password) {
          this.form.get('password').setValue(passVal);
        }
      }),
      untilDestroyed(this)
    )
    .subscribe();

  private readonly mfaRequired$: Subject<string> = new Subject<string>();

  constructor(
    private readonly alertsService: AlertsService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly practiceService: PracticeService,
    private readonly nativeService: NativeAppService,
    private readonly deviceService: DeviceService,
    private readonly fb: FormBuilder,
    private readonly twoFactorAuthenticationDialogService: TwoFactorAuthenticationDialogService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.platform = this.deviceService.getPlatform();
    this.platform = (this.platform ? this.platform : 'ios').toUpperCase();

    this.subscribeToMFARequired();
  }

  async onLogIn(formValue: any): Promise<any> {
    this.isSubmitting = true;
    try {
      // Validate User Credentials in Firebase
      const userCredentials = await signInWithEmailAndPassword(
        this.firebaseAuth,
        formValue.email,
        formValue.password
      );

      // Check if the user exists in Analytics
      const analyticsResponse = await this.authService.hasAccessToAnalytics(
        userCredentials
      );

      // Check if the user exists in Engagement
      const engagementResponse = await this.authService.hasAccessToEngagement(
        formValue.email,
        formValue.password,
        'staff',
        this.userService.getDeviceUuid(formValue.email)
      );
      await this.loadUserInfo(
        engagementResponse,
        analyticsResponse,
        formValue.email,
        formValue.rememberMe
      );

      // Navigate to support after login
      await this.router.navigate(['support'], {
        queryParams: { leaveMenuOpen: true }
      });
      this.logInSuccess.emit(true);
    } catch (e) {
      // check for Invalid Firebase credentials
      if (e?.code === 'auth/invalid-credential') {
        this.alertsService.showError('The user credentials are incorrect');
      } else {
        const error = e.error;
        if (error) {
          this.handleDeviceUuid(error, formValue.email);

          switch (error.error) {
            case 'unknown_device':
              this.alertsService.showApiWarning(e);
              break;
            case 'mfa_required':
              this.mfaRequired$.next(error.email);
              break;
            default:
              this.alertsService.showApiError(e);
              break;
          }
        }
      }
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  private handleDeviceUuid(error: any, email: any) {
    if (error.device_uuid) {
      this.userService.setDeviceUuid(error.device_uuid, email);
    }
  }

  async loadUserInfo(
    engagementResponse: LoginResponse,
    analyticsResponse: LoginResponse,
    email: string,
    remember: boolean
  ) {
    // Load engagement data
    const engPractices = await this.processEngagementLogin(
      engagementResponse,
      email,
      remember
    );

    // Load analytics data
    const analyticsPracticesWithPermissions = await this.processAnalyticsLogin(
      analyticsResponse,
      email,
      remember
    );

    // Merge practices from both systems
    const combinedPractices = this.mergePractices(
      engPractices,
      analyticsPracticesWithPermissions
    );

    this.practiceService.setCombinedPractices(combinedPractices);

    // Select appropriate practice based on available data
    await this.selectDefaultPractice(
      combinedPractices,
      engagementResponse.practice_guid
    );
  }

  private async selectDefaultPractice(
    combinedPractices: PracticeList[],
    defaultEngagementGuid: string
  ): Promise<void> {
    if (combinedPractices.length === 0) {
      return;
    }

    if (combinedPractices.length === 1) {
      const practice = combinedPractices[0];
      this.practiceService.setPracticeGuid(practice.eng_guid);
      this.practiceService.setAnalyticsPracticeGuid(practice.analytics_id);
      return;
    }

    // Find practices with both engagement and analytics IDs
    const practicesWithBoth = combinedPractices.filter(
      (practice) => practice.eng_id !== null && practice.analytics_id !== null
    );

    if (practicesWithBoth.length > 0) {
      const selectedPractice = practicesWithBoth[0];
      this.practiceService.setPracticeGuid(selectedPractice.eng_guid);
      this.practiceService.setAnalyticsPracticeGuid(
        selectedPractice.analytics_id
      );
      return;
    }

    // Try to find the default practice from engagement response
    const engPractices = combinedPractices.filter(
      (practice) =>
        defaultEngagementGuid != null &&
        practice.eng_guid === defaultEngagementGuid
    );

    if (engPractices.length > 0) {
      this.practiceService.setPracticeGuid(engPractices[0].eng_guid);
      this.practiceService.setAnalyticsPracticeGuid(null);
      return;
    }

    // Fallback to analytics practice
    const analyticsPractices = combinedPractices.filter(
      (practice) => practice.analytics_id !== null
    );

    if (analyticsPractices.length > 0) {
      this.practiceService.setPracticeGuid(null);
      this.practiceService.setAnalyticsPracticeGuid(
        analyticsPractices[0].analytics_id
      );
      await this.userService.loadAnalyticsUser(
        analyticsPractices[0].analytics_id
      );
    }
  }

  private mergePractices(
    engPractices: any[],
    analyticsPractices: AnalyticsUserPractice[]
  ): PracticeList[] {
    const combinedPractices: PracticeList[] = [];

    // Add engagement practices first
    engPractices.forEach((engPractice) => {
      combinedPractices.push({
        eng_id: engPractice.id,
        eng_guid: engPractice.guid,
        analytics_id: null,
        name: engPractice.name
      });
    });

    // Add or merge analytics practices
    analyticsPractices.forEach((analyticsPractice) => {
      const existingIndex = combinedPractices.findIndex(
        (practice) => practice.name === analyticsPractice.name
      );

      if (existingIndex >= 0) {
        combinedPractices[existingIndex].analytics_id =
          analyticsPractice.practiceId;
      } else {
        combinedPractices.push({
          eng_id: null,
          eng_guid: null,
          analytics_id: analyticsPractice.practiceId,
          name: analyticsPractice.displayName
        });
      }
    });

    return combinedPractices;
  }

  private async processAnalyticsLogin(
    analyticsResponse: LoginResponse,
    email: string,
    remember: boolean
  ): Promise<AnalyticsUserPractice[]> {
    this.userService.setAnalyticsAuthToken(analyticsResponse.access_token);
    this.userService.setUserData(
      { ...analyticsResponse, username: email } as UserData,
      remember,
      undefined,
      true
    );

    const analyticsPractices = await this.userService.getAnalyticsUserPractices();
    if (analyticsPractices.length === 0) {
      return [];
    }

    return this.filterPracticesWithAppAccess(analyticsPractices);
  }

  private async filterPracticesWithAppAccess(
    practices: AnalyticsUserPractice[]
  ): Promise<AnalyticsUserPractice[]> {
    const hasAccessPromises = practices.map(async (practice) => {
      const hasAccess = await this.userService.doesUserHaveMobileAppAccessForPractice(
        practice.practiceId
      );
      return hasAccess ? practice : null;
    });

    const results = await Promise.all(hasAccessPromises);
    return results.filter((practice) => practice !== null);
  }

  private async processEngagementLogin(
    engagementResponse: LoginResponse,
    email: string,
    remember: boolean
  ): Promise<any[]> {
    if (!engagementResponse?.access_token?.trim()) {
      return [];
    }

    this.userService.setEngagementAuthToken(engagementResponse.access_token);
    this.practiceService.setPracticeGuid(engagementResponse.practice_guid);
    await this.practiceService.loadPracticeIfNeeded();

    const engUser = await this.userService.loadUser();
    this.userService.setUserData(
      { ...engagementResponse, username: email } as UserData,
      remember,
      undefined,
      false
    );

    if (engUser?.dental_group && engUser.dental_group.length > 0) {
      return engUser.dental_group;
    } else if (engagementResponse.practice_guid) {
      // If user has a single practice, create a practice object from practiceGuid
      const practice = this.practiceService.getPractice();
      return [
        {
          id: practice.id || null,
          guid: practice.guid,
          name: practice.name,
          analytics_id: null
        }
      ];
    }

    return [];
  }

  async onPasswordKeyDown($event: KeyboardEvent): Promise<any> {
    if ($event.key === 'Enter') {
      await this.onLogIn(this.form.value);
    }
  }

  onTermsClicked(): void {
    this.nativeService.openExternalUrlOnNewTab(
      'https://www.dentalintel.com/terms'
    );
  }

  onPrivacyClicked(): void {
    this.nativeService.openExternalUrlOnNewTab(
      'https://www.dentalintel.com/terms/privacy'
    );
  }

  private subscribeToMFARequired(): void {
    this.mfaRequired$
      .pipe(
        switchMap((email) =>
          this.twoFactorAuthenticationDialogService
            .open(email, this.form.value.email)
            .afterClosed()
            .pipe(
              filter(
                (closeReason: TwoFactorAuthenticationDialogCloseReason) =>
                  closeReason ===
                  TwoFactorAuthenticationDialogCloseReason.VERIFIED
              ),
              switchMap(() => from(this.onLogIn(this.form.value)))
            )
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
