import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { PatientChatService } from './patient-chat.service';
import { AlertsService } from '../../common/alerts/alerts.service';
import { Conversation } from './conversation.model';
import { Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map
} from 'rxjs/operators';
import { nonEmpty } from '../../common/utils/string-utils.helper';
import { Materialize } from '../../materialize';
import { PracticeService } from '../../common/practice';

declare const M: Materialize;

@Component({
  selector: 'app-patient-chat',
  templateUrl: './patient-chat.component.html',
  styleUrls: ['./patient-chat.component.scss']
})
export class PatientChatComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading: boolean;
  searchActive: boolean;

  @ViewChild('searchConversation')
  searchConversation: ElementRef;
  // Search conversations key event stream
  protected searchConversation$: Observable<any>;
  // Search conversations subscription
  protected searchConversationSub: Subscription;

  constructor(
    public patientChatService: PatientChatService,
    private readonly alertsService: AlertsService,
    private readonly practiceService: PracticeService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    if (nonEmpty(this.patientChatService.search)) {
      this.searchActive = true;
    }
    if (this.practiceService.getPracticeGuid() != null) {
      this.loadConversations(this.patientChatService.conversations.length === 0)
        .then(() => {})
        .catch(() => {});
    }
  }

  ngAfterViewInit(): void {
    this.searchConversation.nativeElement.value = this.patientChatService.search;
    // Create search conversation stream
    this.searchConversation$ = fromEvent(
      this.searchConversation.nativeElement,
      'keyup'
    ).pipe(
      filter((e: any) => e.target.value.length >= 3),
      map((e: any) => e.target.value),
      debounceTime(500),
      distinctUntilChanged()
    );

    // Subscribe to search changes
    this.searchConversationSub = this.searchConversation$.subscribe(
      async (searchInputChange) => {
        this.patientChatService.search = searchInputChange;
        await this.loadConversations();
      },
      (error) => this.alertsService.showApiError(error)
    );

    M.updateTextFields();
  }

  ngOnDestroy(): void {
    if (this.searchConversationSub) {
      this.searchConversationSub.unsubscribe();
    }
  }

  async loadMore(): Promise<any> {
    try {
      this.isLoading = true;
      await this.patientChatService.loadMore(30);
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isLoading = false;
    }
  }

  onConversationSelected(conversation: Conversation): void {
    this.router.navigate(['patient-chat', conversation.id]);
  }

  toggleSearch(): void {
    this.searchActive = !this.searchActive;
    if (this.searchActive) {
      this.patientChatService.search = '';
      this.searchConversation.nativeElement.value = '';
      this.searchConversation.nativeElement.focus();
    } else {
      this.patientChatService.search = '';
      this.loadConversations()
        .then(() => {})
        .catch(() => {});
    }
  }

  onClearSearch(): void {
    this.patientChatService.search = null;
    this.searchConversation.nativeElement.value = '';
    this.loadConversations()
      .then(() => {})
      .catch(() => {});
  }

  private async loadConversations(withLoading: boolean = true): Promise<any> {
    try {
      this.isLoading = withLoading;
      await this.patientChatService.loadConversations();
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
