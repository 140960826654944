import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() showAllBorders = true;
  @Input() value = false;
  @Output() valueChange = new EventEmitter<boolean>();

  toggle(): void {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }

  toggleOnKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
    const key = event.key;
    if (key !== 'Enter' && key !== ' ') {
      return;
    }
    this.toggle();
  }
}
