<div class="send-message-body">
  <div class="row">
    <div class="input-field col s10">
      <textarea id="textarea1"
                class="materialize-textarea"
                rows="2"
                maxlength="560"
                [disabled]="!canReceiveCommunication"
                [(ngModel)]="message"></textarea>
      <label for="textarea1">{{
        'PATIENT_CHAT.CONVERSATION.INPUT' | translate
      }}</label>
    </div>
    <div class="col s2 center-align">
      <a *ngIf="!isSending" class="secondary-content" (click)="sendMessage()"
        ><h5><mat-icon svgIcon="send"></mat-icon></h5></a>
      <div *ngIf="isSending"
           class="valign-wrapper send-spinner-container">
        <app-spinner [small]="true"></app-spinner>
      </div>
    </div>
  </div>

  <div class="row"
       *ngIf="autoDeliveryChannel">
    <div class="col {{ deliveryChannel === 'auto' ? 's10' : 's12' }}">
      <small>{{ 'PATIENT_CHAT.CONVERSATION.SELECTED_CHANNEL' | translate }}
        {{ deliveryChannel | channel }}
        <a
          class="text-secondary change-channel"
          (click)="deliveryChannelModal.open()"
          >{{ 'PATIENT_CHAT.CONVERSATION.CHANGE_CHANNEL' | translate }}</a>
      </small>
    </div>
    <div class="col s2 center-align"
         *ngIf="deliveryChannel === 'auto'">
      <a class="tertiary-content" (click)="showAutoChannelInfo()">
        <mat-icon svgIcon="help"></mat-icon>
      </a>
    </div>
  </div>

  <div *ngIf="!canReceiveCommunication"
       class="send-message__cover animate__animated animate__fadeInUpBig">
    <h6 class="send-message__cover-info">
      {{ 'PATIENT_CHAT.CONVERSATION.NO_DELIVERY_CHANNEL' | translate }}
    </h6>
  </div>
</div>

<app-modal (modalPluginSet)="deliveryChannelModal = $event">
  <h5>{{ 'PATIENT_CHAT.CONVERSATION.CHANNEL_MODAL_TITLE' | translate }}</h5>
  <app-channel-select [patient]="conversation"
                      [autoDeliveryChannel]="autoDeliveryChannel"
                      [allowMobile]="true"
                      (channelChanged)="deliveryChannel = $event">
  </app-channel-select>
  <br />
  <div *ngIf="commService.hasDeliverabilityIssues(conversation)">
    <p class="text-error">
      {{ commService.getDeliverabilitySummary(conversation) }}
    </p>
  </div>
  <br /><br />
</app-modal>
