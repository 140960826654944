<div class="row row-no-margin"
     *ngIf="!message.next_on_same_date">
  <div class="col s12">
    <p class="center-align date-section allow-select">
      <strong>
        {{ message.created_at | date: 'MMM d, yyyy' }}
      </strong>
    </p>
  </div>
</div>
<div class="row"
     [ngClass]="{ 'from-staff': !message.is_patient, unread: message.unread }">
  <div class="col s3 image">
    <div class="round-image">
      <img *ngIf="!message.next_in_group"
           src="{{ message.image }}" />
    </div>
  </div>
  <div class="col s9 message">
    <span class="sender allow-select"
          *ngIf="!message.next_in_group">{{
      message.full_name
    }}</span>
    <mat-icon *ngIf="message.delivery_status === false"
              class="not-delivered-icon"
              [title]="
        ('PATIENT_CHAT.CONVERSATION.DELIVERY_FAILED' | translate) +
        message.delivery_note
      "
              svgIcon="warning"
              (click)="onDeliveryFailedClicked()">
    </mat-icon>

    <div class="message-body">
      <span *ngIf="message?.channel === 'email'; else displayAsIs"
            class="allow-select"
            [innerHTML]="message?.text"></span>
      <ng-template #displayAsIs>
        <span class="allow-select">{{message?.text}}</span>
      </ng-template>
      <div *ngIf="
          message.outgoing_message &&
          message.outgoing_message.media"
           class="message-media">
        <img [src]="message.outgoing_message.media">
      </div>
      <div *ngIf="
          message.incoming_message &&
          message.incoming_message.attachment &&
          !loadingAttachment
        "
           class="attachment"
           (click)="
          viewAttachment(
            message.incoming_message.attachment,
            message.incoming_message.attachment_filename
          )
        ">
        <mat-icon svgIcon="attachment"></mat-icon>
        <small>{{ attachmentExtension() }}</small>
      </div>
      <div *ngIf="loadingAttachment"
           class="attachment">
        <app-spinner [small]="true"></app-spinner>
      </div>
      <br />
      <small class="channel">{{ message?.channel | uppercase }}</small>
      <small class="timestamp">{{
        message.created_at | date: 'h:mm aa'
      }}</small>
    </div>
  </div>
</div>
