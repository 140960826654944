import { Component } from '@angular/core';
import { NativeAppService } from '../../common/native-app-service/native-app.service';

@Component({
  selector: 'request-access',
  templateUrl: './request-access.component.html',
  styleUrls: ['./request-access.component.scss']
})
export class RequestAccessComponent {
  constructor(private readonly nativeAppService: NativeAppService) {}

  navigateTo(url: string) {
    this.nativeAppService.openExternalUrlOnNewTab(url);
  }
}
