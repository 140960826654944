import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { from, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { PracticeService } from '../../../../common/practice';
import { UserService } from '../../../../common/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../../../../environments/environment';
import { CookieService } from 'ngx-cookie';
import { LoadingService } from '../../../../common/services/loading/loading.service';
import { PracticeList } from '../../../../common/practice/practice.model';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-group-practices-select',
  templateUrl: './group-practices-select.component.html',
  styleUrls: ['./group-practices-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupPracticesSelectComponent implements OnInit {
  @Output()
  practiceUpdate: EventEmitter<void> = new EventEmitter<void>();
  form: FormGroup = this.fb.group({
    practice: [null]
  });
  private isInitialLoad = true;

  constructor(
    private readonly fb: FormBuilder,
    public practiceService: PracticeService,
    public userService: UserService,
    private readonly cookieService: CookieService,
    private readonly loadingService: LoadingService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    // Initialize default practice
    this.initDefaultPractice();

    // Subscribe to form value changes
    this.subscribeToFormChanges();
  }

  private initDefaultPractice() {
    of(this.practiceService.getCombinedPractices())
      .pipe(
        take(1),
        map((practices) => (practices.length > 0 ? practices[0] : null)),
        filter((practice) => !!practice),
        tap((practice: PracticeList) => {
          this.practiceService.setPracticeInfo(practice);
          this.practiceService.setPracticeGuid(practice.eng_guid);
          this.practiceService.setAnalyticsPracticeGuid(practice.analytics_id);
          this.form.patchValue({ practice: practice }, { emitEvent: false });
          this.isInitialLoad = false;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private subscribeToFormChanges() {
    this.form
      .get('practice')
      .valueChanges.pipe(
        // Start the loading service, which will display a spin icon until stop is called
        tap(() => {
          this.loadingService.start();
        }),
        // Only emit when the value actually changes
        distinctUntilChanged(
          (prev, curr) =>
            prev?.eng_guid === curr?.eng_guid &&
            prev?.analytics_id === curr?.analytics_id
        ),
        // Only process if it's not the initial load
        filter(() => !this.isInitialLoad),
        tap((practice: PracticeList) => {
          this.handlePracticeChange(practice);
        }),
        switchMap(() => {
          const result = this.practiceService.loadPracticeIfNeeded();
          return result instanceof Promise ? from(result) : of(null);
        }),
        tap(() => {
          this.updateUserIfNeeded();
          this.practiceUpdate.emit();
          this.loadingService.stop();
        }),
        // Stop the loading service, using finalize guarantees that it will be called for success and failure cases
        finalize(() => {
          this.loadingService.stop();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private handlePracticeChange(practice: PracticeList) {
    this.practiceService.clearPracticeInfo();

    if (!practice) return;

    this.practiceService.setPracticeInfo(practice);
    this.practiceService.setPracticeGuid(practice.eng_guid);
    this.practiceService.setAnalyticsPracticeGuid(practice.analytics_id);
    this.router.navigate(['support'], {
      queryParams: { leaveMenuOpen: false }
    });
  }

  private updateUserIfNeeded() {
    if (
      !this.cookieService.get('currentUser') &&
      this.userService.getUserData() != undefined
    ) {
      const now = new Date();
      const oneMinute = 60 * 1000;
      this.userService.setUserData(this.userService.getUserData(), true, {
        expires: new Date(now.getTime() + oneMinute),
        secure: environment.production,
        storeUnencoded: false
      });
    }
  }

  getSelectedPractice(): PracticeList {
    const practice = this.form.get('practice').value;

    if (!practice) {
      return null;
    }

    const practices = this.practiceService.getCombinedPractices();

    // First try to find by eng_guid
    let selectedPractice = practices.find(
      (availablePractice: PracticeList) =>
        availablePractice.eng_guid != null &&
        availablePractice.eng_guid === practice.eng_guid
    );

    // If not found, and we have an analyticsId, try to find by analytics_id
    if (!selectedPractice && practice.analytics_id) {
      selectedPractice = practices.find(
        (availablePractice: PracticeList) =>
          availablePractice.analytics_id === practice.analytics_id
      );
    }

    return selectedPractice;
  }

  getFirstLineOfPracticeName(
    practiceName: string,
    maxLineOfLabel: number
  ): string {
    if (practiceName && practiceName.length > maxLineOfLabel) {
      const breakIndex = this.getBreakNameIndex(practiceName, maxLineOfLabel);
      return breakIndex ? practiceName.slice(0, breakIndex) : practiceName;
    }
    return practiceName;
  }

  getSecondLineOfPracticeName(
    practiceName: string,
    maxLineOfLabel: number
  ): string {
    if (!practiceName) {
      return;
    }

    if (practiceName.length <= maxLineOfLabel) {
      return '';
    } else {
      const breakIndex = this.getBreakNameIndex(practiceName, maxLineOfLabel);
      return breakIndex ? practiceName.slice(breakIndex) : '';
    }
  }

  private getBreakNameIndex(
    practiceName: string,
    maxLineOfLabel: number
  ): number {
    const indices = [];
    for (let i = 0; i < practiceName.length; i++) {
      if (practiceName[i] === ' ') {
        indices.push(i);
      }
    }
    const reversedIndices = [...indices].reverse();
    return reversedIndices.find((spaceIndex) => spaceIndex < maxLineOfLabel);
  }
}
