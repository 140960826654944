<div *ngIf="canUseApp && online"
     class="fullscreen-y-scroll"
     [ngClass]="{ 'main-app': !needLogIn }">
  <app-login *ngIf="needLogIn"
             (logInSuccess)="onLogInSuccess()"></app-login>
  <app-main *ngIf="!needLogIn"></app-main>
</div>

<app-page-not-found *ngIf="!canUseApp"></app-page-not-found>
<app-no-connection *ngIf="!online"></app-no-connection>

<app-dev-debug-actions *ngIf="env.envName === 'local'"></app-dev-debug-actions>
