import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TwoFactorAuthenticationDialogComponent } from './two-factor-authentication-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorAuthenticationDialogService {
  constructor(private readonly matDialog: MatDialog) {}

  open(
    email: string,
    username: string
  ): MatDialogRef<TwoFactorAuthenticationDialogComponent> {
    return this.matDialog.open(TwoFactorAuthenticationDialogComponent, {
      data: {
        email,
        username
      }
    });
  }
}
