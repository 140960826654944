import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { environment } from '../environments/environment';
import { SettingsComponent } from './main/settings/settings.component';
import { PatientChatRoutingModule } from './main/patient-chat/patient-chat-routing.module';
import { SupportRoutingModule } from './main/support/support-routing.module';
import { ViewFileComponent } from './main/shared/view-file/view-file.component';
import { SharedModule } from './main/shared/shared.module';
import { ScheduleRoutingModule } from './main/schedule/schedule-routing.module';
import { AnalyticsScheduleComponent } from './main/schedule/analytics/analytics-schedule.component';
import { StatisticsComponent } from './main/statistics/statistics.component';
import { StatisticsSettingsComponent } from './main/statistics/settings/statistics-settings.component';
import { PermissionGuard } from './common/guard/permission.guard';
import { RequestAccessComponent } from './main/request-access/request-access.component';

const routes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    data: { topLevel: true },
    canActivate: [PermissionGuard]
  },
  {
    path: 'view-file',
    component: ViewFileComponent,
    canActivate: [PermissionGuard]
  },
  {
    path: 'my-schedule',
    component: AnalyticsScheduleComponent,
    canActivate: [PermissionGuard],
    data: { topLevel: true, permission: 'my-schedule' }
  },
  {
    path: 'stats',
    component: StatisticsComponent,
    canActivate: [PermissionGuard],
    data: { topLevel: true, permission: 'stats' }
  },
  {
    path: 'stats/settings',
    component: StatisticsSettingsComponent,
    canActivate: [PermissionGuard],
    data: { topLevel: false, permission: 'stats/settings' }
  },
  {
    path: '',
    redirectTo: 'patient-chat',
    pathMatch: 'full',
    data: { topLevel: true }
  },
  {
    path: 'notifications',
    data: { topLevel: true, permission: 'notifications' },
    pathMatch: 'full',
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import('./main/+notifications/notifications.module').then(
        (m) => m.NotificationsModule
      )
  },
  {
    path: 'notification-settings',
    pathMatch: 'full',
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import('./main/notification-settings/notification-settings.module').then(
        (m) => m.NotificationSettingsModule
      )
  },
  {
    path: 'request-access',
    component: RequestAccessComponent,
    data: { topLevel: true }
  },
  { path: '**', component: PageNotFoundComponent }
];
const devRoutes: Routes = [
  /*{ path: '__TEST__', component: FormsRequestComponent,  },*/
  ...routes
];

@NgModule({
  imports: [
    RouterModule.forRoot(environment.production ? routes : devRoutes),
    PatientChatRoutingModule,
    SupportRoutingModule,
    ScheduleRoutingModule,
    SharedModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
