import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  IStatDetailed,
  metricsConfiguration,
  StatisticsItem
} from './statistics.model';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge.src';
import {
  ChartColor,
  SeriesType
} from '../../common/circular-gauge/circular-guage.model';
import { StatisticsService } from './statistics-service';
import { AlertsService } from '../../common/alerts/alerts.service';
import { CircularGaugeComponent } from '../../common/circular-gauge/circular-gauge.component';
import { PreferencesService } from '../../common/services/preferences/preferences.service';
import { Subscription } from 'rxjs';
import { ProviderService } from '../../common/services/provider/provider.service';

// Initialize the solid gauge module
HighchartsMore(Highcharts);
solidGauge(Highcharts);

@Component({
  selector: 'app-stats',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  date: string;
  metrics: StatisticsItem[] = metricsConfiguration.map(({ ...metric }) => ({
    ...metric,
    visible: true
  }));
  providerId: string = 'default';
  defaultLabel = 'Production';
  defaultFilter = 'P';
  metricFilter = this.defaultFilter;
  primaryMetric: StatisticsItem;
  secondaryMetric: StatisticsItem;
  primaryChartConfig: any;
  secondaryChartConfig: any;
  @ViewChild('primaryChart') primaryGauge: CircularGaugeComponent;
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly preferencesService: PreferencesService,
    private readonly statisticsService: StatisticsService,
    private readonly providerService: ProviderService,
    private readonly alertsService: AlertsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.subscribe();
    await this.loadMetrics(1);
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  private async loadMetrics(interval: number, date?: string): Promise<void> {
    try {
      this.isLoading = true;
      await this.fetchAndUpdateMetrics(interval, date);
      this.setupCharts();
    } catch (error) {
      this.alertsService.showApiError(error);
    } finally {
      this.isLoading = false;
    }
  }

  private async fetchAndUpdateMetrics(
    interval: number,
    customDate?: string
  ): Promise<void> {
    const date = customDate ?? this.getTimePeriod(interval);
    this.metrics = await this.loadAllStats(interval, date);
    await this.updateMetrics(interval, date);
  }

  private async updateMetrics(interval: number, date: string): Promise<void> {
    this.primaryMetric = this.findAndLabelMetric(interval);

    if (interval < 5) {
      await this.loadSecondaryMetric(interval, date);
    } else {
      this.secondaryMetric = undefined;
    }
  }

  private async loadSecondaryMetric(
    interval: number,
    date: string
  ): Promise<void> {
    try {
      const secondaryStats = await this.statisticsService.loadStat(
        this.primaryMetric.queryType,
        date,
        interval < 3 ? 3 : 5,
        this.providerId === 'default' ? 'All' : this.providerId
      );

      this.secondaryMetric = this.normalize(this.primaryMetric, secondaryStats);
      this.secondaryMetric.metricLabel = this.getMetricLabel(
        interval < 3 ? 3 : 5
      );
    } catch (error) {
      this.alertsService.showApiError(error);
    }
  }

  private setupCharts(): void {
    if (this.primaryMetric) {
      this.primaryChartConfig = this.createChartConfig(this.primaryMetric);
    }

    if (this.secondaryMetric) {
      this.secondaryChartConfig = this.createChartConfig(this.secondaryMetric);
    }
  }

  private createChartConfig(metric: StatisticsItem): any {
    const { label: name, measurementType, statData } = metric;
    const { rawValue, rawGoal } = statData;

    const value = !!rawValue && rawValue >= 0 ? rawValue : 0;
    const goal = !!rawGoal && rawGoal >= 0 ? rawGoal : 0;
    const max = this.calculateMaxValue(measurementType, value, goal);

    return {
      yAxis: [{ measurementType }],
      series: [
        {
          name,
          type: SeriesType.Gauge,
          color: ChartColor.Primary,
          values: [
            {
              value,
              goal,
              max
            }
          ],
          isVisible: true
        }
      ]
    };
  }

  private calculateMaxValue(
    measurementType: string,
    value: number,
    goal: number
  ): number {
    if (measurementType === 'percentage') {
      return 100;
    }
    if (goal > value) {
      return Math.round(goal * 1.1);
    }
    if (value > 0) {
      return value;
    }
    return 1;
  }

  private findAndLabelMetric(interval: number): StatisticsItem {
    const metric = this.metrics.find((m) => m.queryType === this.metricFilter);
    if (metric) {
      metric.metricLabel = this.getMetricLabel(interval);
    }
    return metric;
  }

  async onDateChange(event: any): Promise<void> {
    if (event.type === 'preset' && event.interval) {
      await this.loadMetrics(
        event.interval,
        this.toDateString(event.startDate)
      );
    } else if (event.type === 'custom') {
      if (this.areDatesEqual(event.startDate, event.endDate)) {
        await this.loadMetrics(0, this.toDateString(event.endDate));
      } else {
        const diffInMilliseconds = this.getDateDifferenceInMilliseconds(
          event.startDate,
          event.endDate
        );
        await this.loadMetrics(
          diffInMilliseconds,
          this.toDateString(event.endDate)
        );
      }
    }
    this.refreshCharts();
  }

  public readMetricLabel() {
    return (
      this.metrics.find((metric) => metric.queryType === this.metricFilter)
        .label ?? this.defaultLabel
    );
  }

  private getDateDifferenceInMilliseconds(
    startDate: Date,
    endDate: Date
  ): number {
    const start = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0,
      0,
      0
    );
    const end = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      0,
      0,
      0,
      0
    );

    return end.getTime() - start.getTime();
  }

  private areDatesEqual(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  private refreshCharts(): void {
    setTimeout(() => {
      if (this.primaryChartConfig) {
        this.primaryChartConfig = { ...this.primaryChartConfig };
      }
      if (this.secondaryChartConfig) {
        this.secondaryChartConfig = { ...this.secondaryChartConfig };
      }
    });
  }

  private getTimePeriod(interval: number): string {
    const currentDate = new Date();
    let date: Date;

    switch (interval) {
      case 1: {
        date = new Date();
        date.setDate(currentDate.getDate() - 1);
        break;
      }
      case 2: {
        date = new Date(currentDate);
        date.setDate(currentDate.getDate() - currentDate.getDay());
        break;
      }
      case 3: {
        date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        break;
      }
      case 4: {
        date = new Date(currentDate);
        const quarterStartMonth = Math.floor(currentDate.getMonth() / 3) * 3;
        date.setMonth(quarterStartMonth);
        date.setDate(1);
        break;
      }
      case 5: {
        date = new Date(currentDate.getFullYear(), 0, 1);
        break;
      }
      default:
        return '';
    }
    return this.toDateString(date);
  }

  private getMetricLabel(interval: number): string {
    const labels = {
      0: this.metricFilter,
      1: 'Yesterday',
      2: 'WTD',
      3: 'MTD',
      4: 'QTD',
      5: 'YTD'
    };
    return labels[interval] ?? '';
  }

  private async loadAllStats(
    interval: number,
    statsLoadDate: string
  ): Promise<StatisticsItem[]> {
    const promises = this.metrics
      .filter(
        (metric) => metric.visible || metric.queryType === this.metricFilter
      )
      .map((metric) =>
        this.statisticsService
          .loadStat(
            metric.queryType,
            statsLoadDate,
            interval,
            this.providerId === 'default' ? 'All' : this.providerId
          )
          .then((data) => this.normalize(metric, data))
          .catch(() => ({ ...metric, value: null, goal: null }))
      );
    return Promise.all(promises);
  }

  private normalize(
    metric: StatisticsItem,
    response: IStatDetailed
  ): StatisticsItem {
    const valueString = this.formatValue(metric, response);
    const goalString = this.formatGoal(metric, response);

    return {
      ...metric,
      value: valueString,
      goal: goalString,
      statData: response
    };
  }

  private formatValue(metric: StatisticsItem, response: IStatDetailed): string {
    if (metric.measurementType === 'currency') {
      return response.rawValue > 999999
        ? `$${this.millionFormat(Math.round(response.rawValue))}`
        : `$${Math.round(response.rawValue).toLocaleString()}`;
    }
    if (metric.measurementType === 'percentage') {
      return `${Math.round(response.rawValue)}%`;
    }
    if (metric.measurementType === 'number') {
      return response.kpiDetails;
    }
    return '';
  }

  private formatGoal(metric: StatisticsItem, response: IStatDetailed): string {
    if (response.rawGoal < 0) {
      return '';
    }
    if (metric.measurementType === 'currency') {
      return response.rawGoal > 999999
        ? `$${this.millionFormat(Math.round(response.rawGoal))}`
        : `$${Math.round(response.rawGoal).toLocaleString()}`;
    }
    if (metric.measurementType === 'percentage') {
      return `${Math.round(response.rawGoal)}%`;
    }
    return '';
  }

  private millionFormat(value: number): string {
    const tempString = value.toString();
    const divisor = Math.pow(10, tempString.length - 1);
    return `${(value / divisor).toFixed(1)}M`;
  }

  private toDateString(date: Date): string {
    return date.toLocaleDateString('en-US');
  }

  private loadPreferences(): Subscription {
    return this.preferencesService.preferencesState$.subscribe(
      (preferences) => {
        if (!preferences?.validate()) {
          return;
        }
        this.metricFilter =
          preferences.highlightedStatName === 'default'
            ? this.metricFilter
            : preferences.highlightedStatName;
        const indexMap = new Map<string, number>(
          preferences.statPreferences.map((stat) => [stat.name, stat.index])
        );
        const visibleMap = new Map<string, boolean>(
          preferences.statPreferences.map((stat) => [stat.name, stat.visible])
        );
        this.metrics.forEach((metric) => {
          metric.visible = visibleMap.get(metric.queryType) ?? true;
        });
        this.metrics.sort(
          (a, b) =>
            (indexMap.get(a.queryType) ?? 0) - (indexMap.get(b.queryType) ?? 0)
        );
      }
    );
  }

  private subscribe() {
    this.subscriptions.push(
      this.loadPreferences(),
      this.providerService.providerState$.subscribe(async (state) => {
        const { provider } = state;
        this.providerId = provider.id;
        await this.loadMetrics(1);
      })
    );
  }

  private unsubscribe() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
