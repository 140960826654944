import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { PracticeService } from '../common/practice';
import { isEmpty } from '../common/utils/string-utils.helper';
import { UserService } from '../common/user';
import { NativeAppService } from '../common/native-app-service/native-app.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {
  needLogIn: boolean;
  canUseApp: boolean;
  online: boolean;
  env = environment;

  constructor(
    private cookieService: CookieService,
    private practiceService: PracticeService,
    private userService: UserService,
    private nativeAppService: NativeAppService
  ) {}

  ngOnInit(): void {
    this.canUseApp = this.nativeAppService.isInApp() || !environment.production;
    this.checkLogInStatus();

    // subscribe to online/offline status
    this.online = navigator.onLine;
    window.addEventListener('online', (event) => {
      this.online = navigator.onLine;
    });

    window.addEventListener('offline', (event) => {
      this.online = navigator.onLine;
    });
  }

  onLogInSuccess(): void {
    this.checkLogInStatus();
  }

  private checkLogInStatus(): void {
    this.needLogIn =
      !this.userService.isLoggedIn() ||
      isEmpty(this.practiceService.getPracticeGuid());

    // change app theme depending if we are in app or in login phase
    if (this.needLogIn) {
      window.parent.document.body.classList.add('blue-theme');
    } else {
      window.parent.document.body.classList.remove('blue-theme');
    }

    if (!this.needLogIn) {
      this.refreshData().then();
    }
  }

  private async refreshData(): Promise<any> {
    await this.practiceService.lazyRefreshPracticeData();
    await this.userService.loadUser();
    await this.userService.updateAppVersion();
  }
}
