<div class='app-select'
     [ngClass]='style'>
  <p class='label'>{{ label }}</p>
  <div class='select'
       (click)='toggle()'
       (keydown)='toggleOnKeyDown($event)'
       [class.open]='isOpen'>
    <div class='selected'>{{ selectedText }}</div>
    <mat-icon class='icon'
              svgIcon="keyboard_arrow_down"></mat-icon>
  </div>
  <ul *ngIf='isOpen'
      class='options'>
    <li (click)='selectValue({ value: "default", text: defaultText })'
        (keydown)='selectValueOnKeyDown($event, { value: "default", text: defaultText })'>
      {{ defaultText }}
    </li>
    <li *ngFor='let option of options'
        (click)='selectValue(option)'
        (keydown)='selectValueOnKeyDown($event, option)'>{{ option.text }}
    </li>
  </ul>
</div>
