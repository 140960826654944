export interface IStatDetailed {
  statType: string;
  metricName: string;
  kpiDetails: string;
  incrementalGoalDetails: string;
  trendDetails: string;
  interval: number;
  rawTrend: string;
  rawValue: number;
  rawGoal: number;
  valueString: string;
  goalString: string;
  timePeriod: string;
  trophy: boolean;
  goalDetails: {
    percentOfGoal: number;
    abValue: number;
    abVerb: string;
    abCalculatedText: string;
    abColor: string;
  };
  chart?: string;
}

export interface StatisticsItem {
  icon: string;
  label: string;
  value?: string;
  goal?: string;
  measurementType?: string;
  queryType: string;
  statData?: IStatDetailed;
  metricLabel?: string;
  visible?: boolean;
}

function createMetric(
  icon: string,
  label: string,
  queryType: string,
  measurementType: string = 'currency'
): StatisticsItem {
  return { icon, label, queryType, measurementType };
}

export interface MetricValue {
  value: number;
  goal: number;
  max: number;
}

export interface ChartConfig {
  yAxis: Array<{ measurementType: string }>;
  series: Array<{
    name: string;
    type: string;
    color: string;
    values: MetricValue[];
    isVisible: boolean;
  }>;
}

export interface StatisticsState {
  isLoading: boolean;
  date: string;
  metricFilter: string;
  primaryMetric?: StatisticsItem;
  secondaryMetric?: StatisticsItem;
}

export const metricsConfiguration: StatisticsItem[] = [
  createMetric('stats_prod_per_visit', 'Production Per Visit', 'PPV'),
  createMetric('stats_prod', 'Production', 'P'),
  createMetric('stats_collections', 'Collections', 'C'),
  createMetric(
    'stats_same_day_prod',
    'Same Day Production %',
    'SDTD',
    'percentage'
  ),
  createMetric('stats_prod_per_hour', 'Production Per Hour', 'PPH'),
  createMetric(
    'stats_hyg_visits_per_hour',
    'Hygienist Visits Per Hour',
    'VPH',
    'number'
  ),
  createMetric('stats_hyg_reappt', 'Hyg Re-Appointment %', 'HRP', 'percentage'),
  createMetric(
    'stats_np_hyg_reappt',
    'NP Hyg Re-Appointment %',
    'NPHRP',
    'percentage'
  ),
  createMetric(
    'stats_patient_diagnostic',
    'Patient Diagnostic %',
    'PDP',
    'percentage'
  ),
  createMetric('stats_avg_tx_presented', 'Avg. Treatment Presented', 'ATP'),
  createMetric(
    'stats_patient_acceptance',
    'Patient Acceptance %',
    'CAP',
    'percentage'
  ),
  createMetric('stats_tx_acceptance', 'Tx $ Acceptance %', 'TAP', 'percentage'),
  createMetric(
    'stats_same_day_tx_acceptance',
    'Same Day Tx $ Acceptance %',
    'SDTAP',
    'percentage'
  ),
  createMetric(
    'stats_avg_accepted_per_patient',
    'Avg. Accepted $ Per Patient',
    'AAPP'
  ),
  createMetric(
    'stats_perio_diagnostic',
    'Perio Diagnostic %',
    'PERDP',
    'percentage'
  ),
  createMetric(
    'stats_perio_acceptance',
    'Perio Acceptance %',
    'PERAP',
    'percentage'
  ),
  createMetric('stats_perio_visit', 'Perio %', 'PERVP', 'percentage'),
  createMetric('stats_flouride', 'Fluoride Treatment %', 'FTP', 'percentage')
];
