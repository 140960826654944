import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private onGoingRequests = 0;
  private readonly state$ = new BehaviorSubject<boolean>(false);
  readonly loadingState$ = this.state$.asObservable();

  start(): void {
    this.onGoingRequests++;
    this.state$.next(true);
  }

  stop(): void {
    this.onGoingRequests--;

    if (this.onGoingRequests > 0) {
      return;
    }

    this.state$.next(false);
  }
}
